import React from "react";
import styles from "./BannerSec.module.scss";
import { Link } from "react-router-dom";
import { Container, Dropdown } from "react-bootstrap";
import Banner from "../../../../assets/images/profilebanner.jpg";
import { ShareIcon } from "../../../../assets/svgIcons/SVGicon";
import CommonBtn from "../../../../components/ui/CommonBtn/CommonBtn";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { TwitterShareButton, InstapaperShareButton } from "react-share";

const BannerSec = () => {
  const userInfo = useSelector((state) => state?.user?.userData);
  const auth = useSelector((state) => state?.user?.userLogin);
  const currLoc = `${window.location.href}`;
  const copyLinkHandler = () => {
    navigator.clipboard.writeText(currLoc);
    toast.success("Link Copied!");
  };
  return (
    <>
      <div
        className={styles.banner}
        style={{
          backgroundImage: `url( ${userInfo?.userMeta?.coverImage || Banner})`,
        }}
      >
        <Container>
          <div className={styles.banner_action}>
            {auth && (
              <CommonBtn
                title="EDIT PROFILE"
                className="btn-bordered"
                role="link"
                to="/profile-settings"
              />
            )}
            <Dropdown className={styles.banner_dropdown}>
              <Dropdown.Toggle>
                <ShareIcon />
              </Dropdown.Toggle>
              <Dropdown.Menu renderOnMount={true}>
                <Link
                  to="#"
                  onClick={copyLinkHandler}
                  className="dropdown-item"
                >
                  Copy Link
                </Link>
                <Link
                  to="."
                  className={`dropdown-item ${styles.banner_dropdown_shareButton}`}
                >
                  <TwitterShareButton url={currLoc} title={`Share On Twitter`}>
                    Share On Twitter
                  </TwitterShareButton>
                </Link>
                <Link to="#" className="dropdown-item">
                  Share On Instagram
                </Link>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Container>
      </div>
    </>
  );
};

export default BannerSec;
