import moment from "moment";
import { setUserData } from "../../Redux/Reducers/user";
import { store } from "../../Redux/Store";
import APIURLS from "../ApiPath/ApiPath";
import { apiCallGet, apiCallPost } from "../Axios/Axios";
import {
  API_REQUEST_PROFILE,
  VIDEO_TYPE,
  etherScannerUrl,
} from "../Constant/Constant";
import { toasts } from "../../components/common/Toast/Toast";

export const timeFromNow = (date) => moment(date).fromNow();
export const emptyState = (stateSetter) => {
  stateSetter([]);
};

export const limitPerPage = 10;

export const trimText = (text, maxLenthAlowwed = 26) => {
  if (!text) return;
  if (text?.length < maxLenthAlowwed) return text;
  return `${text.slice(0, 11)}....${text.slice(-2)}   `;
};

export const uploadImageVideoFile = async (fileObj, type) => {
  try {
    const formData = new FormData();
    formData.append("files", fileObj);

    if (fileObj.type === "application/pdf") {
      const res = await apiCallPost(APIURLS.MEDIA.ADD_PDF, formData, {});
      if (res) {
        if (res.data[0]?.name?.length > 0) {
          return res.data[0]?.name;
        } else {
          return false;
        }
      }
    } else if (VIDEO_TYPE.includes(fileObj.type)) {
      const res = await apiCallPost(APIURLS.MEDIA.ADD_VIDEO, formData, {});
      if (res) {
        if (res.data[0]?.name?.length > 0) {
          return res.data[0]?.name;
        } else {
          return false;
        }
      }
    } else {
      const res = await apiCallPost(
        type === API_REQUEST_PROFILE
          ? APIURLS.MEDIA.ADD_IMAGE
          : APIURLS.MEDIA.ADD_IMAGE_FRACTION,
        formData,
        {}
      );
      if (res) {
        if (res.data[0]?.name?.length > 0) {
          return res.data[0]?.name;
        } else {
          return false;
        }
      }
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};
export const captilizeFirstCharacter = (word) =>
  `${word.charAt(0).toUpperCase()}${word.substring(1).toLowerCase()}`;

export const KycOnComplete = async (payLoad) => {
  console.log(
    `============================= Hiting the backEnd Server with ${payLoad} ======================================`,
    payLoad
  );
  try {
    const res = await apiCallPost(APIURLS.KYC, payLoad);
    return res;
  } catch (error) {
    console.log(
      `============================= Hiting the backEnd Server with ${payLoad} ======================================`,
      payLoad
    );
    console.log(error);
  }
};

export const getProfile = async (setLoader) => {
  setLoader && setLoader(true);
  try {
    const res = await apiCallGet(APIURLS.USER.USER_PROFILE, {});
    if (res) {
      store.dispatch(setUserData(res?.data));
      setLoader && setLoader(false);
    }
  } catch (error) {
    setLoader && setLoader(false);
    console.error(error);
  }
};
export const formatDate = (date) => moment(date).format("MMM  DD, yyyy");

export const handleUpload = async (fileList, API_REQUEST_PROFILE) => {
  const d = fileList.map(
    (element) =>
      new Promise((resolve, reject) => {
        uploadImageVideoFile(element.url, API_REQUEST_PROFILE)
          .then((res) => {
            if (res) {
              resolve({
                type: element?.url?.type,
                url: res,
                key: element?.key,
              });
            } else {
              resolve(null);
            }
          })
          .catch((error) => {
            reject(error);
          });
      })
  );

  let data = await Promise.all(d);
  return data;
};

export const getImageUrl = (res, key) => {
  let url = res
    .filter((element) => element.key === key)
    .map((elem) => elem.url);
  return url[0];
};

export const imgKitFunction = (src, str, cls) => {
  const result = src?.indexOf("imagekit");
  const res =
    result !== -1 ? (
      <>
        {src && (
          <img
            className={cls}
            src={`${src}/tr:${str}`}
            alt="."
            referrerPolicy="no-referrer"
          />
        )}
      </>
    ) : (
      <img src={src} alt="." referrerPolicy="no-referrer" />
    );
  return res;
};

export const imageTypeSizeCheck = (file, field) => {
  if (typeof file === "object") {
    const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
    const maxSize = 5 * 1024 * 1024;
    return allowedTypes.includes(file?.type) && file?.size <= maxSize
      ? true
      : false;
  }
  return false;
};

export const checkPDFType = (file, field) => {
  if (typeof file === "object") {
    const allowedTypes = "application/pdf";
    const maxSize = 2 * 1024 * 1024;
    return allowedTypes === file?.type && file?.size <= maxSize;
  }
  return false;
};

export const getAllCategory = async (setAllCategory) => {
  try {
    const res = await apiCallGet(`${APIURLS.CATEGORY.CATEGORY}`);
    if (res) {
      setAllCategory(res.data.rows);
    }
  } catch (error) {
    console.log(error);
  }
};

export const isArrayEmpty = (dataArr) => {
  if (!dataArr) return true;
  return Array.isArray(dataArr) && dataArr?.length === 0;
};

export const listNftApi = async (data) => {
  try {
    const res = await apiCallPost(
      `${APIURLS.NFT.LIST_NFT}`,
      data,
      {},
      "toastOn"
    );
    return res;
  } catch (error) {}
};

export const handleCopy = (walletAddress) => {
  navigator.clipboard.writeText(walletAddress);
  toasts.success("Address Copied!");
};

export const getNftDetails = async (paramsid) => {
  try {
    const { data } = await apiCallGet(
      `${APIURLS.NFT.GET_NFT_DETAILS}/${paramsid}`,
      {},
      "toastOnError"
    );
    return data;
  } catch (error) {
    console.log("nftDetailsData", error);
    throw error;
  }
};

export const openEtherScan = (transactionHash) => {
  if (!transactionHash) return;
  window.open(`${etherScannerUrl}${transactionHash}`, "_blank");
};
