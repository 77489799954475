import React, { useEffect, useState } from "react";
import styles from "../KYC.module.scss";
import CommonBtn from "../../../../components/ui/CommonBtn/CommonBtn";
import { Col, Row } from "react-bootstrap";
import Lottie from "lottie-react";
import kycPending from "../../../../assets/lottie/Pending.json";
import { KycOnComplete } from "../../../../features/Utility/Utility";
import {
  setCompleteKYCModal,
  setKycStatus,
} from "../../../../Redux/Reducers/user";
import { useDispatch, useSelector } from "react-redux";

const YetTobeSubmitted = () => {
  const dispatch = useDispatch();
  const [jsonAnimation, setjsonAnimation] = useState();
  const username = useSelector((state) => state?.user?.userData?.username);

  useEffect(() => {
    fetch("https://d15k51272nsa6c.cloudfront.net/lottie/kycPending.json")
      .then((res) => res.json())
      .then((json) => {
        setjsonAnimation(json);
      })
      .catch((err) => {});
  }, []);

  const kycDummyApi = async () => {
    const payLoad = {
      resultUrl: "",
      sessionId: "",
    };
    try {
      const res = await KycOnComplete(payLoad);
      dispatch(setCompleteKYCModal(false));
      if (res) {
        dispatch(setKycStatus("APPROVED"));
        window.location.href = `/${username}/kyc`;
      }
      console.log(
        `======================= KYC COMPLETE WITH STATUS ============================`
      );
    } catch (error) {
      console.log(
        "==========================ERROR KYC API ============================",
        error
      );
    }
  };

  return (
    <>
      <div className={styles.kyc}>
        <div className={styles.kyc_lottie}>
          <Lottie
            animationData={jsonAnimation || kycPending}
            loop={true}
            className={styles.about_left_lottie}
            style={{ width: 130 }}
          />
        </div>
        <h4>KYC Yet to be Submitted</h4>
        <p>
          It's difficult to find examples of lorem ipsum in use before Letterset
          made it popular as a dummy text in the 1960s, although supports.
        </p>
        <div className={styles.kyc_footer}>
          <Row>
            <Col>
              <div className={styles.kyc_footer_box}>
                <span>Requested Date</span>
                <h6>-----------</h6>
              </div>
            </Col>
            <Col>
              <div className={styles.kyc_footer_box}>
                <span>Action Date</span>
                <h6>-----------</h6>
              </div>
            </Col>
            <Col>
              <div className={styles.kyc_footer_box}>
                <span>Status</span>
                <h6>-----------</h6>
              </div>
            </Col>
          </Row>
        </div>
        <CommonBtn
          // onClick={() => window.checkin && window.checkin.signUp.open()}
          onClick={() => kycDummyApi()}
          title="Request KYC"
          className="btn-bordered"
        />
      </div>
    </>
  );
};

export default YetTobeSubmitted;
