import React, { useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { Col, Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import HeaderInner from "../../../components/common/headerInner/HeaderInner";
import "react-pdf/dist/esm/Page/TextLayer.css";
import APIURLS from "../../../features/ApiPath/ApiPath";
import * as CryptoJS from "crypto-js";
import { apiCallGet } from "../../../features/Axios/Axios";
import { DECRYPTION_KEY } from "../../../features/Constant/Constant";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfReader = () => {
  const pageRefs = useRef({});
  const [numPages, setNumPages] = useState(null);
  const [totalPages, setTotalPages] = useState(null);
  const { pathname } = useLocation();
  const [pageNumber, setPageNumber] = useState(1);
  const [disabledNext, setDisabledNext] = useState(false);
  const [disabledPrev, setDisabledPrev] = useState(false);
  const [encryptedDocuments, setEncryptedDocuments] = useState({});

  const nftId = pathname.split("/")[3];
  const docId = pathname.split("/")[4];

  const calleverydoc = async () => {
    try {
      const res = await apiCallGet(`${APIURLS.NFT.GET_NFT_DOCS}/${nftId}`);

      const bytes = CryptoJS.AES.decrypt(res?.data, DECRYPTION_KEY);
      const decryptedData = bytes.toString(CryptoJS.enc.Utf8);

      setEncryptedDocuments(
        JSON.parse(decryptedData).find(
          (item) => item.categoryDocumentId === docId
        )
      );
    } catch (error) {
      console.log(error);
    }
  };

  useState(() => {
    calleverydoc();
  }, []);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setTotalPages(numPages);
  };

  function getPage(first, second, pos, pageNo) {
    let positionElem = pos;

    if (positionElem > first && positionElem < second) return pageNo;
    else if (positionElem >= second) return pageNo + 1;
    else if (positionElem < first) return pageNo - 1;
    else return pageNo;
  }

  // const handleScroll = () => {
  //   const position = window.pageYOffset;
  //   let page = getPage(
  //     pageRefs?.current[pageNumber]?.offsetTop,
  //     pageRefs?.current[pageNumber + 1]?.offsetTop,
  //     position,
  //     pageNumber
  //   );
  //   if (page !== 0 && page <= totalPages) setPageNumber(page);
  // };

  return (
    <>
      <Container>
        <HeaderInner title={encryptedDocuments?.documentName} />

        <Col lg={12} className="pb-5 mb-5">
          {encryptedDocuments?.url ? (
            <Document
              file={encryptedDocuments?.url}
              options={{ workerSrc: "/pdf.worker.js" }}
              onLoadSuccess={onDocumentLoadSuccess}
              onContextMenu={(e) => e.preventDefault()}
            >
              <p style={{ color: "#fff" }}>
                Page {pageNumber || (totalPages ? 1 : "--")} of{" "}
                {totalPages || "--"}
              </p>
              {Array.from(new Array(totalPages), (elem, index) => (
                <div
                  key={`page_${index + 1}`}
                  ref={(el) => {
                    pageRefs.current[index + 1] = el;
                    // obj.push(el);
                  }}
                >
                  <Page
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    scale={2.0}
                    renderTextLayer={false}
                  ></Page>
                </div>
              ))}
            </Document>
          ) : (
            <p>page not found</p>
          )}
        </Col>
      </Container>
    </>
  );
};

export default PdfReader;
