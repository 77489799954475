import React, { useEffect, useState } from "react";
import styles from "../KYC.module.scss";
import CommonBtn from "../../../../components/ui/CommonBtn/CommonBtn";
import { Col, Row } from "react-bootstrap";
import Lottie from "lottie-react";
import { RejectedIcon } from "../../../../assets/svgIcons/SVGicon";
import { formatDate, isArrayEmpty } from "../../../../features/Utility/Utility";
import { useDispatch } from "react-redux";
import { Image_kit_key } from "../../../../features/Constant/Constant";
import rejectedLottie from "../../../../assets/lottie/rejected.json";

const Rejected = ({ requestedDate, actionDate, reasons }) => {
  const [jsonAnimation, setjsonAnimation] = useState();
  const dispatch = useDispatch();
  useEffect(() => {
    fetch(`https://ik.imagekit.io/${Image_kit_key}/lottie/rejected.json`)
      .then((res) => res.json())
      .then((json) => {
        setjsonAnimation(json);
      })
      .catch((err) => {});
  }, []);

  return (
    <>
      <div className={styles.kyc}>
        <div className={styles.kyc_lottie}>
          <Lottie
            animationData={jsonAnimation || rejectedLottie}
            loop={true}
            className={styles.about_left_lottie}
            style={{ width: 130 }}
          />
        </div>
        <h4>KYC Submission Rejected</h4>

        <p>
          {!isArrayEmpty(reasons) && (
            <>
              <h6>Due to Reasons</h6>
              {reasons?.map((item, idx) =>
                idx === reasons?.length - 1 ? item : item + "  "
              )}
            </>
          )}
        </p>

        <div className={styles.kyc_footer}>
          <Row>
            <Col>
              <div className={styles.kyc_footer_box}>
                <span>Requested Date</span>
                <h6>{requestedDate && formatDate(requestedDate)}</h6>
              </div>
            </Col>
            <Col>
              <div className={styles.kyc_footer_box}>
                <span>Action Date</span>
                <h6>{actionDate && formatDate(actionDate)}</h6>
              </div>
            </Col>
            <Col>
              <div className={styles.kyc_footer_box}>
                <span>Status</span>
                <h6 className={styles.kyc_footer_box_rejected}>
                  <RejectedIcon /> Rejected
                </h6>
              </div>
            </Col>
          </Row>
        </div>
        <CommonBtn
          title="REQUEST AGAIN"
          onClick={() => window.checkin && window.checkin.signUp.open()}
          className="btn-bordered"
        />
      </div>
    </>
  );
};

export default Rejected;
