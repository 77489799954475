import React, { useEffect, useState } from "react";
import "./CompleteKYCModal.scss";
import Lottie from "lottie-react";
import CommonModal from "../CommonModal/CommonModal";
import CommonBtn from "../CommonBtn/CommonBtn";
import { useDispatch, useSelector } from "react-redux";
import {
  setCompleteKYCModal,
  setKycStatus,
} from "../../../Redux/Reducers/user";
import completekyc from "../../../assets/lottie/completekyc.json";
import { KycOnComplete } from "../../../features/Utility/Utility";

const CompleteKYCModal = () => {
  const dispatch = useDispatch();
  const [jsonAnimation, setjsonAnimation] = useState();
  const show = useSelector((state) => state?.user?.showCompleteKYCModal);
  const username = useSelector((state) => state?.user?.userData?.username);
  useEffect(() => {
    (async function () {
      try {
        let res = await fetch(
          "https://d15k51272nsa6c.cloudfront.net/lottie/completekyc.json"
        );
        res = await res.json();
        setjsonAnimation(res);
      } catch (error) {}
    })();
  }, []);
  const kycDummyApi = async () => {
    const payLoad = {
      resultUrl: "",
      sessionId: "",
    };
    try {
      const res = await KycOnComplete(payLoad);
      dispatch(setCompleteKYCModal(false));
      if (res) {
        dispatch(setKycStatus("APPROVED"));
        window.location.href = `/${username}/kyc`;
      }
      console.log(
        `======================= KYC COMPLETE WITH STATUS ============================`
      );
    } catch (error) {
      console.log(
        "==========================ERROR KYC API ============================",
        error
      );
    }
  };
  return (
    <>
      <CommonModal
        show={show}
        onHide={() => dispatch(setCompleteKYCModal(false))}
        className="complete_kyc_modal"
        centered>
        <div className="complete_kyc_modal_lottie">
          <Lottie
            animationData={jsonAnimation || completekyc}
            loop={true}
            style={{ width: 190 }}
          />
        </div>
        <p className="mt-3 text-center">Please Complete Your KYC First!</p>
        <CommonBtn
          className="mt-3 w-100 btn-bordered"
          // onClick={() => window.checkin && window.checkin.signUp.open()}
          onClick={() => kycDummyApi()}
          title="Continue"
        />
      </CommonModal>
    </>
  );
};

export default CompleteKYCModal;
