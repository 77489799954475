import React, { useEffect, useState } from "react";
import CommonCard from "../../../components/ui/CommonCards/CommonCard/CommonCard";
import styles from "./Favourites.module.scss";
import { Col, Row } from "react-bootstrap";
import CommonBtn from "../../../components/ui/CommonBtn/CommonBtn";
import SearchAndFilter from "../commonComponents/SearchAndFilter/SearchAndFilter";
import { apiCallPost } from "../../../features/Axios/Axios";
import APIURLS from "../../../features/ApiPath/ApiPath";
import {
  FILTER_LIST_ALL,
  FILTER_PROFILE_FAVORITE_TYPE,
} from "../../../features/Constant/Constant";
import useDebounce from "../../../hooks/useDebounce";
import NoResult from "../../../components/ui/NoResult/NoResult";

const Favourites = () => {
  const [allFavourite, setAllFavourite] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [loader, setLoader] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const debounceTerm = useDebounce(searchValue);
  const [filterValue, setFilterValue] = useState("collection");
  const [orderValue, setOrderValue] = useState("new");
  const debounceSearch = useDebounce(searchValue);
  const getAllFavourite = async (page, searchValue) => {
    setLoader(true);
    try {
      const res = await apiCallPost(
        `${APIURLS?.NFT?.GET_NFT_ALL_FAVOURITE}/${page}/6`,
        {
          search: debounceTerm,
          order: orderValue,
          type: filterValue,
        },
        {},
        "toastOnError"
      );
      if (res?.data) {
        if (page > 1 || (searchValue !== "" && page > 1)) {
          setAllFavourite((allFavourite) => [
            ...allFavourite,
            ...res?.data?.rows,
          ]);
          setLoader(false);
        } else {
          setAllFavourite(res?.data?.rows);
          setLoader(false);
        }
        setTotalCount(res?.data?.count);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  useEffect(() => {
    const finder = setTimeout(async () => {
      setCurrentPage(1);
      await getAllFavourite("1", searchValue);
    }, [500]);
    return () => {
      clearTimeout(finder);
    };
  }, [filterValue, debounceTerm, orderValue]);

  const handleViewMore = (nextPage) => {
    if (searchValue && searchValue.length >= 1) {
      getAllFavourite(nextPage, searchValue);
    } else getAllFavourite(nextPage, "");
  };

  // const updateRowHandler = (id) => {
  //   // setAllFavourite((prevState) =>
  //   //   prevState.filter((item) => (item?.id || item.nftId) !== id)
  //   // );
  //   console.log("totalCount ==>", allFavourite?.length, totalCount);
  //   getAllFavourite();
  // };
  // /marketplace/collections/
  return (
    <div className={styles.favourites}>
      <SearchAndFilter
        search={true}
        setSearchvalue={setSearchValue}
        searchvalue={searchValue}
        collection={true}
        collectionFilterOption={FILTER_PROFILE_FAVORITE_TYPE}
        setCollectionValue={setFilterValue}
        type="filter"
        newest
        newestOptions={FILTER_LIST_ALL}
        setNewestFilterValue={setOrderValue}
      />
      <Row>
        {allFavourite?.length === 0 && <NoResult />}
        {allFavourite?.length > 0 &&
          allFavourite?.map((item, index) => {
            return (
              <Col sm={6} md={4} key={item.nftId} className="mt-5">
                <CommonCard
                  img={item.featuredImage || item.image}
                  title={item.title}
                  nftId={item?.nftId}
                  type={filterValue}
                  to={
                    filterValue === "collection"
                      ? `/marketplace/collections/${item?.id}`
                      : `/marketplace/nft/${item?.nftId}`
                  }
                  subtitle={item?.collectionName}
                  price={item.price}
                  isFavourite={item?.isFavourite}
                  fractions={item.noOfNfts || item.quantity}
                  key={index}
                  btnTitle="BUY"
                  disableLikeBtn
                  collectionId={item?.id}
                  getAllFavourite={getAllFavourite}
                  // updateRow={() => updateRowHandler(item?.nftId || item?.id)}
                  forFavouriteOnly
                />
              </Col>
            );
          })}
      </Row>
      {/* <Link> */}

      {allFavourite?.length !== totalCount && (
        <div className="text-center mt-5 pt-5 ">
          <CommonBtn
            title="VIEW MORE"
            className="btn-bordered"
            onClick={() => {
              handleViewMore(currentPage + 1);
              setCurrentPage((prevValue) => prevValue + 1);
            }}
          />
        </div>
      )}
    </div>
  );
};
export default Favourites;
