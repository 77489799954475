import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import styles from "./Faq.module.scss";

const Faq = () => {
  const data = [
    {
      head: "Lorem ipsum was popularized in the 1960s with Letraset's dry-transfer sheets.",
      desc: "Rrow itself, let it be sorrow; let him love it; let him pursue it, ishing for its acquisitiendum. Because he will ab hold, uniess but through concer, and also of those who resist. Now a pure snore disturbeded sum dust.",
    },
    {
      head: "Lorem ipsum was popularized in the 1960s with Letraset's dry-transfer sheets.",
      desc: "Rrow itself, let it be sorrow; let him love it; let him pursue it, ishing for its acquisitiendum. Because he will ab hold, uniess but through concer, and also of those who resist. Now a pure snore disturbeded sum dust.",
    },
    {
      head: "Lorem ipsum was popularized in the 1960s with Letraset's dry-transfer sheets.",
      desc: "Rrow itself, let it be sorrow; let him love it; let him pursue it, ishing for its acquisitiendum. Because he will ab hold, uniess but through concer, and also of those who resist. Now a pure snore disturbeded sum dust.",
    },
    {
      head: "Lorem ipsum was popularized in the 1960s with Letraset's dry-transfer sheets.",
      desc: "Rrow itself, let it be sorrow; let him love it; let him pursue it, ishing for its acquisitiendum. Because he will ab hold, uniess but through concer, and also of those who resist. Now a pure snore disturbeded sum dust.",
    },
    {
      head: "Lorem ipsum was popularized in the 1960s with Letraset's dry-transfer sheets.",
      desc: "Rrow itself, let it be sorrow; let him love it; let him pursue it, ishing for its acquisitiendum. Because he will ab hold, uniess but through concer, and also of those who resist. Now a pure snore disturbeded sum dust.",
    },
    {
      head: "Lorem ipsum was popularized in the 1960s with Letraset's dry-transfer sheets.",
      desc: "Rrow itself, let it be sorrow; let him love it; let him pursue it, ishing for its acquisitiendum. Because he will ab hold, uniess but through concer, and also of those who resist. Now a pure snore disturbeded sum dust.",
    },
    {
      head: "Lorem ipsum was popularized in the 1960s with Letraset's dry-transfer sheets.",
      desc: "Rrow itself, let it be sorrow; let him love it; let him pursue it, ishing for its acquisitiendum. Because he will ab hold, uniess but through concer, and also of those who resist. Now a pure snore disturbeded sum dust.",
    },
  ];
  return (
    <>
      <section className={styles.faq_Sec}>
        <Container>
          <Row>
            <Col>
              <div className={styles.faq_Sec_head}>
                <h2>FAQ</h2>
              </div>
              <Accordion>
                {data.map((item, index) => {
                  return (
                    <Accordion.Item eventKey={`${index}`} key={index}>
                      <Accordion.Header>{item.head}</Accordion.Header>
                      <Accordion.Body>{item.desc}</Accordion.Body>
                    </Accordion.Item>
                  );
                })}
              </Accordion>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Faq;
