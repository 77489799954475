import React from "react";
import NumberCounter from "number-counter";
import styles from "./Ncounter.module.scss";
import { Col, Row } from "react-bootstrap";
import { useEffect } from "react";

const Ncounter = ({ countAssetCategory, countCollection, countNFT }) => {
 
  // useEffect(() => {
  //   countAssetCategory;
  // }, [countAssetCategory]);
  return (
    <>
      <div className={styles.n_counter}>
        <Row>
          <Col className="pe-md-0">
            <div className={styles.n_counter_main}>
              <div className={styles.n_counter_main_left}>
                <div className={styles.n_counter_main_left_inner}>
                  <NumberCounter
                    start={0}
                    end={Number(countAssetCategory)}
                    delay={5}
                    className={styles.n_counterNumber}
                  />
                </div>
              </div>
              <h4>Asset Categories</h4>
            </div>
          </Col>
          <Col>
            <div className={styles.n_counter_main}>
              <div className={styles.n_counter_main_left}>
                <div className={styles.n_counter_main_left_inner}>
                  <NumberCounter
                    start={0}
                    end={Number(countCollection)}
                    delay={5}
                    className={styles.n_counterNumber}
                  />
                </div>
              </div>
              <h4>Collections</h4>
            </div>
          </Col>
          <Col>
            <div className={styles.n_counter_main}>
              <div className={styles.n_counter_main_left}>
                <div className={styles.n_counter_main_left_inner}>
                  <NumberCounter
                    start={0}
                    end={Number(countNFT)}
                    delay={5}
                    className={styles.n_counterNumber}
                  />
                </div>
                {/* <span>k</span> */}
              </div>
              <h4>NFTs Listed</h4>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Ncounter;
