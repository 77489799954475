import React from "react";
import { Container } from "react-bootstrap";
import { NavLink, Outlet } from "react-router-dom";
import styles from "./Notifications.module.scss";

const NotificationsPage = () => {
  return (
    <section className={styles.notifications}>
      <Container>
        <h1 className="h2">Notifications</h1>
        <ul className={styles.notifications_nav}>
          <li>
            <NavLink to="/notifications/unread">Unread</NavLink>
          </li>
          <li>
            <NavLink to="/notifications/read">Read</NavLink>
          </li>
        </ul>
        <Outlet />
      </Container>
    </section>
  );
};

export default NotificationsPage;
