import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import {
  NFTicon,
  PencilIcon,
  Sellicon,
  WalletIcon2,
} from "../../../assets/svgIcons/SVGicon"
import HowItWorksCard from "../../../components/ui/CommonCards/HowItWorksCard/HowItWorksCard"
import styles from "./HowItWorks.module.scss"
const HowItWorks = () => {
  const [squares1to6, setSquares1to6] = useState("")
  const [squares7and8, setSquares7and8] = useState("")
  useEffect(() => {
    document.body.classList.toggle("HomePage")
    document.documentElement.addEventListener("mousemove", followCursor)
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.body.classList.toggle("HomePage")
      document.documentElement.removeEventListener("mousemove", followCursor)
    }
  }, [])
  const followCursor = (event) => {
    let posX = event.clientX - window.innerWidth / 2
    let posY = event.clientY - window.innerWidth / 6
    setSquares1to6(
      "perspective(500px) rotateY(" +
        posX * 0.006 +
        "deg) rotateX(" +
        posY * -0.006 +
        "deg)"
    )
    setSquares7and8(
      "perspective(500px) rotateY(" +
        posX * 0.005 +
        "deg) rotateX(" +
        posY * -0.005 +
        "deg)"
    )
  }
  const data = [
    {
      icon: <WalletIcon2 />,
      title: "Connect Wallet",
      content: (
        <>
          Don't bother typing "lorem ipsum" into Google translate. If you
          already tried, you may have gotten anything from "NATO" to "China",
          depending on how you capitalized the letters. The bizarre translation
          was fodder for conspiracy theories.
        </>
      ),
    },
    {
      icon: <PencilIcon />,
      title: "Request Fractionlization",
      content: (
        <>
          Don't bother typing "lorem ipsum" into Google translate. If you
          already tried, you may have gotten anything from "NATO" to "China",
          depending on how you capitalized the letters. The bizarre translation
          was fodder for conspiracy theories.
        </>
      ),
    },
    {
      icon: <NFTicon />,
      title: "Sell & Buy NFTs",
      content: (
        <>
          Don't bother typing "lorem ipsum" into Google translate. If you
          already tried, you may have gotten anything from "NATO" to "China",
          depending on how you capitalized the letters. The bizarre translation
          was fodder for conspiracy theories.
        </>
      ),
    },
    {
      icon: <Sellicon />,
      title: "Sell Your NFTs",
      content: (
        <>
          Don't bother typing "lorem ipsum" into Google translate. If you
          already tried, you may have gotten anything from "NATO" to "China",
          depending on how you capitalized the letters. The bizarre translation
          was fodder for conspiracy theories.
        </>
      ),
    },
  ]
  return (
    <>
      <section className={styles.howitworks} id="how-it-works">
        <Container>
          <div className={styles.howitworks_head}>
            <h2>How It Works</h2>
            <p>
              Don't bother typing "lorem ipsum" into Google translate. If you
              already tried, you may have gotten anything from "NATO" to
              "China", depending on how you capitalized the letters. The bizarre
              translation was fodder for conspiracy theories.
            </p>
          </div>
          <Row>
            {data.map((item, index) => {
              return (
                <Col
                  sm={6}
                  lg={3}
                  key={index}
                  className="mt-5"
                  style={{
                    transform: index % 2 == 0 ? squares7and8 : squares1to6,
                  }}>
                  <HowItWorksCard
                    icon={item.icon}
                    title={item.title}
                    content={item.content}
                  />
                </Col>
              )
            })}
          </Row>
        </Container>
      </section>
    </>
  )
}
export default HowItWorks
