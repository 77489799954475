import React, { useState } from "react";
import "./WalletModal.scss";
import metamask from "../../../assets/images/metamask.png";
import CommonModal from "../CommonModal/CommonModal";
import CommonBtn from "../CommonBtn/CommonBtn";
import { useDispatch, useSelector } from "react-redux";
import { setWalletAddress } from "../../../Redux/Reducers/wallet";
import { apiCallPost } from "../../../features/Axios/Axios";
import APIURLS from "../../../features/ApiPath/ApiPath";
import ethProviders from "../../../features/web3/EthProviders";
import { getProfile } from "../../../features/Utility/Utility";
import { toasts } from "../../common/Toast/Toast";

const WalletModal = ({ setWalletModalShow, setConfirmModal }) => {
  const dispatch = useDispatch();
  const [connectErrors, setConnectErrors] = useState("");
  const [connectLoading, setConnectLoading] = useState(false);
  const userWallet = useSelector((state) => state?.user?.userData?.userWallets);
  const isWalletDeleted = useSelector(
    (state) => state?.user?.userData?.isWalletDeleted
  );
  const walletAddress = useSelector((state) => state?.wallet?.walletAddress);
  const balance = useSelector((state) => state?.wallet?.walletBalance);

  const handleConnectWallet = async () => {
    setConnectLoading(true);
    try {
      const address = await ethProviders.isMetamaskInstalled(setConnectErrors);
      if (userWallet === address) {
        dispatch(setWalletAddress(address));
        setConnectLoading(false);
        setWalletModalShow(false);
        toasts.success("Wallet connected successfully.");
        return;
      }
      if (address) {
        try {
          const result = await apiCallPost(
            APIURLS.WALLET.ADD_WALLET_ADDRESS,
            { address },
            {},
            "toastOn"
          );
          if (result) {
            dispatch(setWalletAddress(address));
            setConnectLoading(false);
            setWalletModalShow(false);
            getProfile();
            return;
          }
        } catch (error) {
          setConnectLoading(false);
        }
      }
      return setConnectLoading(false);
    } catch (error) {
      setConnectLoading(false);
    }
  };
  return (
    <>
      <CommonModal
        show={() => setWalletModalShow(true)}
        onHide={() => setWalletModalShow(false)}
        className="wallet_modal"
        modalTitle="Wallet">
        {isWalletDeleted ? (
          <>
            <div className="wallet_modal_body_new">
              <p>
                Removed <br />
                Please add a new wallet
              </p>
            </div>
          </>
        ) : (
          <>
            {userWallet && walletAddress ? (
              <div className="wallet_modal_body">
                <img src={metamask} alt="meta_icon" />
                <p className="text-decoration-underline mb-4 word-break-all">
                  {userWallet}
                </p>
                <h6>Current Balance</h6>
                <div className="wallet_modal_body_detail">
                  <p>ETH {parseFloat(balance).toFixed(2)}</p>
                  <p>~$0.00</p>
                </div>
                {userWallet && (
                  <a
                    href={`https://sepolia.etherscan.io/address/${userWallet}`}
                    target="_blank">
                    <p className="text-decoration-underline">
                      View on Etherscan
                    </p>
                  </a>
                )}
              </div>
            ) : (
              <div className="wallet_modal_body_new">
                <img
                  src={metamask}
                  alt="meta_icon"
                  style={{ margin: "0 auto", display: "block" }}
                />
                <p>Please Connect a wallet</p>
                <a href="#">
                  <p className="text-center">{connectErrors}</p>
                </a>
              </div>
            )}
          </>
        )}
        <div className="btn_sec">
          {walletAddress || isWalletDeleted ? (
            <>
              {isWalletDeleted ? (
                <CommonBtn
                  loader={connectLoading}
                  title="CONNECT NEW"
                  onClick={() => handleConnectWallet()}
                />
              ) : (
                <CommonBtn
                  loader={connectLoading}
                  title="CHANGE REQUEST"
                  onClick={() => {
                    if (setConfirmModal) {
                      setWalletModalShow(false);
                      setConfirmModal(true);
                    } else {
                      setWalletModalShow(false);
                    }
                  }}
                />
              )}
            </>
          ) : (
            <>
              <CommonBtn
                loader={connectLoading}
                title="CONNECT WALLET"
                onClick={() => handleConnectWallet()}
              />
              {userWallet && (
                <>
                  <CommonBtn
                    loader={connectLoading}
                    className={`btn-bordered`}
                    title="CHANGE REQUEST"
                    onClick={() => {
                      if (setConfirmModal) {
                        setWalletModalShow(false);
                        setConfirmModal(true);
                      } else {
                        setWalletModalShow(false);
                      }
                    }}
                  />
                </>
              )}
            </>
          )}
        </div>
      </CommonModal>
    </>
  );
};

export default WalletModal;
