export const SignUpFormFields = [
  {
    label: "Full Name",
    type: "text",
    placeholder: "Enter Your Full Name",
    name: "fullName",
  },
  {
    label: "Username",
    type: "text",
    placeholder: "Enter Your Username",
    name: "userName",
  },
  {
    label: "Email",
    type: "email",
    placeholder: "Enter Your Email",
    name: "email",
  },
  {
    label: "Password",
    type: "password",
    placeholder: "**************",
    name: "password",
  },
  {
    label: "Confirm Password",
    type: "password",
    placeholder: "**************",
    name: "confirmPassword",
  },
];
export const newPassWordFields = [
  {
    label: "Password",
    name: "password",
  },
  {
    label: "Confirm Password",
    name: "confirmPassword",
  },
];
