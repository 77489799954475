import React from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import styles from "./Footer.module.scss";
import Logo from "../../../assets/images/FooterLogo.png";
import Insta from "../../../assets/images/icons/insta.png";
import Twitter from "../../../assets/images/icons/twitter.png";
import Ggl from "../../../assets/images/icons/gglpls.png";
import Pint from "../../../assets/images/icons/pint.png";
import { Link, NavLink } from "react-router-dom";
import CommonBtn from "../../ui/CommonBtn/CommonBtn";
import { useFormik } from "formik";
import { newsLetterValidation } from "../../../features/Validation/YupValidations";
import { apiCallPost } from "../../../features/Axios/Axios";
import APIURLS from "../../../features/ApiPath/ApiPath";
import { toasts } from "../Toast/Toast";
import { useDispatch, useSelector } from "react-redux";
import { setShowLogInLogInModal } from "../../../Redux/Reducers/user";
import { NavHashLink } from "react-router-hash-link";

const Footer = () => {
  const dispatch = useDispatch();
  const login = useSelector((state) => state.user.userLogin);

  const setLogin = (actionPayload) => {
    dispatch(setShowLogInLogInModal(actionPayload));
  };
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: newsLetterValidation,
    validateOnChange: false,
    onSubmit: async (values) => {
      try {
        const res = await apiCallPost(`${APIURLS.USER.NEWSLETTER}`, values, {});
        if (res) {
          toasts.success(res.message);
          formik.resetForm({ values: "" });
        }
      } catch (error) {
        toasts.error(error?.message);
      }
    },
  });

  return (
    <section className={styles.Footer}>
      <Container>
        <Row>
          <Col xs={12} md={4} className={styles.FooterAbout}>
            <img src={Logo} alt="" />
            <p>
              Lorem ipsum dolor sit amet consectetur adipiscing elit luctus
              cursus tempor euismod
            </p>
            <ul className={styles.FooterSocial}>
              <li>
                <Link to="#" targe="_blank">
                  <img src={Insta} alt="" />
                </Link>
              </li>
              <li>
                <Link to="#" targe="_blank">
                  <img src={Twitter} alt="" />
                </Link>
              </li>
              <li>
                <Link to="#" targe="_blank">
                  <img src={Ggl} alt="" />
                </Link>
              </li>
              <li>
                <Link to="#" targe="_blank">
                  <img src={Pint} alt="" />
                </Link>
              </li>
            </ul>
          </Col>
          <Col xs={6} sm={3} md={2}>
            <div className="ps-xxl-5">
              <h4>Company</h4>
              <ul className={styles.FooterQuick}>
                <li>
                  <NavHashLink to="/#how-it-works">How It Works</NavHashLink>
                </li>
                <li>
                  <NavLink to="/about">About</NavLink>
                </li>
                <li>
                  <NavLink to="/faq">FAQ's</NavLink>
                </li>
                <li>
                  <NavLink to="/activity">Activity</NavLink>
                </li>
                <li>
                  <NavLink to="/contact-us">Contact Us</NavLink>
                </li>
                <li>
                  {!login ? (
                    <NavLink onClick={() => setLogin(true)}>
                      Help Center
                    </NavLink>
                  ) : (
                    <NavLink to="/customer-support">Help Center</NavLink>
                  )}
                </li>
              </ul>
            </div>
          </Col>
          <Col xs={6} sm={3} md={2}>
            <div className="ps-xxl-4">
              <h4>Explore</h4>
              <ul className={styles.FooterQuick}>
                <li>
                  <NavLink to="/marketplace/collections">Collections</NavLink>
                </li>
                <li>
                  <NavLink to="/marketplace/nft">NFT's</NavLink>
                </li>
              </ul>
            </div>
          </Col>
          <Col sm={6} md={4} className={`ps-xxl-0 ${styles.FooterStayTouch}`}>
            <h4>Stay In Touch</h4>
            <p>
              Lorem ipsum dolor sit amet consectetur adipiscing elit luctus
              cursus.
            </p>
            <Form onSubmit={formik.handleSubmit}>
              <Form.Control
                type="text"
                placeholder="info@gmail.com"
                id={"email"}
                name={"email"}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              <CommonBtn
                type="submit"
                onSubmit={formik.handleSubmit}
                title="Sign Up"
              />
            </Form>
            {formik.touched.email && formik.errors.email && (
              <div className="error_field">
                <span>{formik.errors.email}</span>
              </div>
            )}
          </Col>
        </Row>
        <div className={styles.FooterCopyRight}>
          <Row>
            <Col xs={12} sm={6}>
              <p>
                © Copyright {new Date().getFullYear()} Xegara. All Rights
                Reserved.
              </p>
            </Col>
            <Col xs={12} sm={6} className="text-lg-end">
              <ul>
                <li>
                  <Link to="#">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="#">Terms of Service</Link>
                </li>
              </ul>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
};
export default Footer;
