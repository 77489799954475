import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import IntroImg from "../../../assets/images/IntroImg.png";
import {
  DecentralisationIcon,
  DemocratizationIcon,
  LiquidityIcon,
} from "../../../assets/svgIcons/SVGicon";
import CommonBtn from "../../../components/ui/CommonBtn/CommonBtn";
import Lottie from "lottie-react";
import styles from "./Introduction.module.scss";
import { Image_kit_key } from "../../../features/Constant/Constant";
import nftLottie from "../../../assets/lottie/nft.json";

const Introduction = () => {
  const [jsonAnimation, setjsonAnimation] = useState();
  useEffect(() => {
    fetch(`https://ik.imagekit.io/${Image_kit_key}/lottie/nft.json`)
      .then((res) => res.json())
      .then((json) => {
        setjsonAnimation(json);
      })
      .catch((err) => {});
  }, []);
  return (
    <>
      <section className={styles.introduction}>
        <Container>
          <Row className="align-items-center">
            <Col lg={6} className="order-lg-last">
              <div className={styles.introduction_right}>
                <h2>
                  <span>Welcome Xegara</span>Introducing
                </h2>
                <p>
                  The decade that brought us Star Trek and Doctor Who also
                  resurrected Cicero—or at least what used to be Cicero—in an
                  attempt to make the days before computerized design a little
                  less painstaking.
                </p>
                <p>
                  The French lettering company Letraset manufactured a set of
                  dry-transfer sheets which included the lorem ipsum filler text
                  in a variety of fonts, sizes, and layouts. These sheets of
                  lettering could be rubbed on anywhere and were quickly
                  adopted.
                </p>
                <p>
                  These sheets of lettering could be rubbed on anywhere and were
                  quickly adopted by graphic artists, printers, architects, and
                  advertisers for their professional look and ease of use.
                </p>
                <CommonBtn title="LEARN MORE" className="btn-bordered" />
              </div>
            </Col>
            <Col lg={6} className="text-center text-lg-start">
              <div className={styles.introduction_left}>
                <img src={IntroImg} alt="" />
              </div>
            </Col>
          </Row>
        </Container>
        <div className={styles.introduction_impact}>
          <Container>
            <Row>
              <Col lg={6}>
                <div className={styles.introduction_impact_left}>
                  <h2>Xegara Impact</h2>
                  <p>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. It is a long established fact that a reader will
                    be distracted by the readable
                  </p>
                  <div className={styles.introduction_impact_left_list}>
                    <div className={styles.introduction_impact_left_list_inner}>
                      <div
                        className={
                          styles.introduction_impact_left_list_inner_left
                        }>
                        <DemocratizationIcon />
                      </div>
                      <div
                        className={
                          styles.introduction_impact_left_list_inner_right
                        }>
                        <h5>Democratization</h5>
                        <p>
                          It is a long established fact that a reader will be
                          distracted by the readable content of a page
                        </p>
                      </div>
                    </div>
                    <div className={styles.introduction_impact_left_list_inner}>
                      <div
                        className={
                          styles.introduction_impact_left_list_inner_left
                        }>
                        <LiquidityIcon />
                      </div>
                      <div
                        className={
                          styles.introduction_impact_left_list_inner_right
                        }>
                        <h5>Liquidity</h5>
                        <p>
                          It is a long established fact that a reader will be
                          distracted by the readable content of a page
                        </p>
                      </div>
                    </div>
                    <div className={styles.introduction_impact_left_list_inner}>
                      <div
                        className={
                          styles.introduction_impact_left_list_inner_left
                        }>
                        <DecentralisationIcon />
                      </div>
                      <div
                        className={
                          styles.introduction_impact_left_list_inner_right
                        }>
                        <h5>Decentralisation</h5>
                        <p>
                          It is a long established fact that a reader will be
                          distracted by the readable content of a page
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <div className={styles.introduction_impact_right}>
                  <Lottie
                    animationData={jsonAnimation || nftLottie}
                    loop={true}
                    className={styles.about_left_lottie}
                    style={{ width: 650 }}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
};

export default Introduction;
