import React, { useEffect, useState } from "react";
import styles from "./SearchResults.module.scss";
import { Container, Row, Col } from "react-bootstrap";
import CommonBtn from "../../../components/ui/CommonBtn/CommonBtn";
import CommonCard from "../../../components/ui/CommonCards/CommonCard/CommonCard";
import CommonSelectbox from "../../../components/ui/Dropdowns/CommonSelectbox";
import { SelectCategory } from "../../../assets/svgIcons/SVGicon";
import NoResult from "../../../components/ui/NoResult/NoResult";
import CollectionSkelton from "../../marketplace/Collections/CollectionSkelton";
import {
  getAllCategory,
  isArrayEmpty,
} from "../../../features/Utility/Utility";
import { apiCallPost } from "../../../features/Axios/Axios";
import APIURLS from "../../../features/ApiPath/ApiPath";
import SearchHeader from "./SearchHeader";
import { useGlobalSearchContext } from "../../../context/GlobalSearchContext";

const SearchResults = () => {
  const { globalSearchTerm } = useGlobalSearchContext();

  const [allCollectionData, setAllCollectionData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterValue, setFilterValue] = useState("");
  const [allCategory, setAllCategory] = useState([]);
  const [categoryId, setCategoryId] = useState("all");
  const [categoryLoader, setCategoryLoader] = useState(true);
  const getAllCollection = async (page, searchvalue) => {
    setCategoryLoader(true);
    try {
      let res = await apiCallPost(
        APIURLS.GLOBAL_SEARCH,
        {
          search: globalSearchTerm,
          filter: "collection ",
          page: currentPage,
          limit: 12,
        },
        {},
        "toastOnError"
      );
      console.log({ res });
      if (res?.data) {
        if (page > 1 || (globalSearchTerm !== "" && page > 1)) {
          setAllCollectionData((allCollectionData) => [
            ...allCollectionData,
            ...res?.data?.rows,
          ]);
        } else {
          setAllCollectionData(res?.data?.rows);
          setCategoryLoader(false);
        }
        setTotalCount(res?.data?.count);
        setCategoryLoader(false);
      }
    } catch (error) {
      console.error(error);
      setCategoryLoader(false);
    }
  };
  useEffect(() => {
    getAllCategory(setAllCategory);
  }, []);
  useEffect(() => {
    const finder = setTimeout(async () => {
      setCurrentPage(1);
      await getAllCollection("1", globalSearchTerm);
    }, 500);
    return () => {
      clearTimeout(finder);
    };
  }, [globalSearchTerm, filterValue, categoryId]);

  const handleViewMore = (nextPage) => {
    if (globalSearchTerm && globalSearchTerm.length >= 1) {
      getAllCollection(nextPage, globalSearchTerm);
    } else {
      getAllCollection(nextPage, "");
    }
  };

  useEffect(() => {
    getAllCategory(setAllCategory);
  }, []);
  return (
    <>
      <section className={styles.search_result_sec}>
        <Container>
          <div className="d-flex flex-wrap align-items-center justify-content-between">
            <h2 className="text-white">Search Results</h2>
            <CommonSelectbox
              icon={<SelectCategory />}
              title={"Select Category"}
              options={allCategory}
              className="me-3"
              setCategoryId={setCategoryId}
              type={"category"}
            />
          </div>
          <SearchHeader />
          <Container>
            <Row>
              {categoryLoader ? (
                <CollectionSkelton />
              ) : (
                <>
                  {allCollectionData?.map((item, index) => (
                    <Col
                      xs={12}
                      sm={6}
                      lg={4}
                      xl={3}
                      key={index}
                      className="mt-5"
                    >
                      <CommonCard
                        img={item.featuredImage}
                        title={item.title}
                        to={item.id}
                        subtitle={
                          <>
                            NFTs: <strong>{item.noOfNfts}</strong>
                          </>
                        }
                        galleryData={item?.nfts.slice(0, 3)}
                        key={index}
                        btnTitle="BUY"
                        setAllCollectionData={setAllCollectionData}
                        isFavourite={item.isFavourite}
                        type={"collection"}
                      />
                    </Col>
                  ))}
                </>
              )}
            </Row>
            {isArrayEmpty(allCollectionData) && !categoryLoader && <NoResult />}

            {allCollectionData?.length !== totalCount && (
              <div className="text-center mt-5 ">
                <CommonBtn
                  title="VIEW MORE"
                  className="btn-bordered"
                  onClick={() => {
                    handleViewMore(currentPage + 1);
                    setCurrentPage((prevValue) => prevValue + 1);
                  }}
                />
              </div>
            )}
          </Container>
        </Container>
      </section>
    </>
  );
};

export default SearchResults;
