import moment from "moment";

export const API_BASE_URL = process.env.REACT_APP_API_HOST;
export const IMAGE_BASE_URL = process.env.REACT_APP_IMAGE_HOST;
export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;

export const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
export const dividendFactoryAddress =
  process.env.REACT_APP_DIVIDEND_CONTRACT_ADDRESS;
export const chainId = process.env.REACT_APP_CHAIN_ID;
export const Image_kit_key = process.env.REACT_APP_IMAGE_KIT_KEY;

export const domain = {
  chainId: 11155111,
  name: "XEGARA_NFT",
  verifyingContract: contractAddress,
  version: "1",
};
export const STATUS = {
  PENDING: "PENDING",
  ACCEPTED: "ACCEPTED",
  REJECTED: "REJECTED",
  NOT_SEND: "NOT_SEND",
};

export const EIP712Domain = [
  { name: "name", type: "string" },
  { name: "version", type: "string" },
  { name: "chainId", type: "uint256" },
  { name: "verifyingContract", type: "address" },
];
export const NFTDetails = [
  { name: "tokenId", type: "uint256" },
  { name: "fractionsQuantity", type: "uint256" },
  { name: "uri", type: "string" },
  { name: "owner", type: "address" },
];
export const NFTListing = [
  { name: "pricePerShare", type: "uint256" }, // Price of per share converted from USD to XAR
  { name: "tokenId", type: "uint256" }, // Token ID for the NFT
  { name: "counter", type: "uint256" }, // counter per listing for the NFT
  { name: "nftAmount", type: "uint256" }, // how much copy list at once
  { name: "owner", type: "address" }, // Address of the NFT owner
];
export const buyDetails = [
  { name: "conversionRate", type: "uint256" },
  { name: "amountToBuy", type: "uint256" },
  { name: "counter", type: "uint256" },
  { name: "timeStamp", type: "uint256" },
  { name: "currency", type: "address" },
];
export const VIDEO_TYPE = [
  "video/mp4",
  "video/mpeg-2",
  "video/webm",
  "audio/ogg",
];
export const FILTER_ACTIVITY = [
  {
    menu: "All",
    to: "#",
    value: "",
  },
  {
    menu: "Last 3 Days",
    to: "#",
    value: moment().subtract(3, "day").format("YYYY-MM-DD"),
  },
  {
    menu: "Last 10 Days",
    to: "#",
    value: moment().subtract(10, "day").format("YYYY-MM-DD"),
  },
  {
    menu: "Last 30 Days",
    to: "#",
    value: moment().subtract(30, "day").format("YYYY-MM-DD"),
  },
];
export const VIDEO_EXT_TYPE = ["mp4", "mpeg-2", "webm", "ogg"];
export const IMAGE_TYPE = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "image/gif",
  "video/mp4",
  "video/webm",
  "video/ogg",
];
export const etherScannerUrl = "https://sepolia.etherscan.io/tx/";
export const FileUploaderType = [
  "jpeg",
  "jpg",
  "png",
  "gif",
  "mp4",
  "ogg",
  "webm",
];
export const DECRYPTION_KEY = "Xegara::1.0.0::25-11-2022";
export const maxFileSizeAllowed = {
  IMAGE: 5 * 1024 * 1024,
  IMAGE_LARGE: 5 * 1024 * 1024,
  IMAGE_SMALL: 2 * 1024 * 1024,
  PDF: 5 * 1024 * 1024,
  VIDEO: 20 * 1024 * 1024,
  DOCUMENT: 5 * 1024 * 1024,
  ALL: 20 * 1024 * 1024,
};
export const KYC_STATUS = {
  processing: "PROCESSING",
  done: "APPROVED",
  approved: "APPROVED",
  declined: "DECLINED",
};

export const GLOBAL_SEARCH_PATH = {
  COLLECTION: "/search/collections",
  NFT: "/search/nft",
};
export const NOFTIFICATION_TYPE = {
  READ: 1,
  UNREAD: 0,
  ALL: 2,
};

export const FILTER_LIST_ALL = [
  { menu: "Newest", to: "#", value: "new" },
  { menu: "Oldest", to: "#", value: "old" },
];

export const FILTER_PROFILE_FRACTION_SORT = [
  { menu: "All", to: "#", value: "" },
  { menu: "Newset", to: "#", value: "desc" },
  { menu: "Oldest", to: "#", value: "asc" },
];

export const FILTER_PROFILE_FRACTION_STATUS = [
  { menu: "All", to: "#", value: "ALL" },
  { menu: "Approved", to: "#", value: "ACCEPTED" },
  { menu: "Rejected", to: "#", value: "REJECTED" },
  { menu: "Pending", to: "#", value: "PENDING" },
];

export const API_REQUEST_PROFILE = "forProfile";

export const FILTER_PROFILE_FAVORITE_TYPE = [
  { menu: "Collection", to: "#", value: "collection" },
  { menu: "NFTs", to: "#", value: "nft" },
];
export const CREATER_FILTER_OPTION = [
  { menu: "Created", to: "#", value: "created" },
  { menu: "Collected", to: "#", value: "collected" },
];

export const TRANSACTION_OPTIONS = [
  { menu: "All", to: "#", value: "" },
  { menu: "Dividend", to: "#", value: "dividend" },
  { menu: "Mint", to: "#", value: "mint" },
  { menu: "List", to: "#", value: "list" },
  { menu: "Buy", to: "#", value: "buy" },
];
