import React, { useEffect, useState } from "react";
import styles from "../KYC.module.scss";
import { Col, Row } from "react-bootstrap";
import Lottie from "lottie-react";
import { PendingIcon } from "../../../../assets/svgIcons/SVGicon";
import { formatDate } from "../../../../features/Utility/Utility";
import { Image_kit_key } from "../../../../features/Constant/Constant";
import pendingLottie from "../../../../assets/lottie/Pending.json";

const AlreadySubmitted = ({ requestedDate, actionDate }) => {
  const [jsonAnimation, setjsonAnimation] = useState();

  useEffect(() => {
    fetch(`https://ik.imagekit.io/${Image_kit_key}/lottie/Pending.json`)
      .then((res) => res.json())
      .then((json) => {
        setjsonAnimation(json);
      })
      .catch((err) => {});
  }, []);
  return (
    <>
      <div className={styles.kyc}>
        <div className={styles.kyc_lottie}>
          <Lottie
            animationData={jsonAnimation || pendingLottie}
            loop={true}
            className={styles.about_left_lottie}
            style={{ width: 130 }}
          />
        </div>
        <h4>Request Already Submitted</h4>
        <p>
          It's difficult to find examples of lorem ipsum in use before Letterset
          made it popular as a dummy text in the 1960s, although supports.
        </p>
        <div className={styles.kyc_footer}>
          <Row>
            <Col>
              <div className={styles.kyc_footer_box}>
                <span>Requested Date</span>
                <h6>{requestedDate && formatDate(requestedDate)}</h6>
              </div>
            </Col>
            <Col>
              <div className={styles.kyc_footer_box}>
                <span>Action Date</span>
                <h6>{actionDate && formatDate(actionDate)}</h6>
              </div>
            </Col>
            <Col>
              <div className={styles.kyc_footer_box}>
                <span>Status</span>
                <h6 className={styles.kyc_footer_box_submitted}>
                  <PendingIcon /> Pending
                </h6>
              </div>
            </Col>
          </Row>
        </div>
        {/* <CommonBtn title="CONTINUE" className="btn-bordered" /> */}
      </div>
    </>
  );
};

export default AlreadySubmitted;
