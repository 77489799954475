import React from "react";
import CommonModal from "../../../../components/ui/CommonModal/CommonModal";
import styles from "./Auth.module.scss";
import { DownloadDoc } from "../../../../assets/svgIcons/SVGicon";
import { Link, useNavigate } from "react-router-dom";

const CheckAuthenticityModal = ({
  show,
  onHide,
  encryptedDocuments,
  paramid,
}) => {
  console.log("inmodal", paramid && paramid);
  console.log("modal", encryptedDocuments);
  return (
    <CommonModal
      show={show}
      modalTitle={`Authentication Documents`}
      onHide={onHide}
      className={styles.authModalStyle}
    >
      <ul>
        {encryptedDocuments.length > 0 &&
          encryptedDocuments.map((item, index) => (
            <li key={index} style={{ cursor: "pointer" }}>
              <a
                target="_blank"
                href={`${paramid}/${item?.categoryDocumentId}`}
              >
                <span>{item?.documentName}</span>
                <DownloadDoc />
              </a>
            </li>
          ))}
      </ul>
    </CommonModal>
  );
};

export default CheckAuthenticityModal;
