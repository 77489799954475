import Lottie from "lottie-react";
import React, { useEffect, useState } from "react";
import styles from "./NoResult.module.scss";
import { Image_kit_key } from "../../../features/Constant/Constant";
import noRecord from "../../../assets/lottie/norecord.json";

const NoResult = () => {
  const [jsonAnimation, setjsonAnimation] = useState();
  useEffect(() => {
    fetch(`https://ik.imagekit.io/${Image_kit_key}/lottie/norecord.json`)
      .then((res) => res.json())
      .then((json) => {
        setjsonAnimation(json);
      })
      .catch((err) => {
        console.log("error in No Result Json Api");
      });
  }, []);

  return (
    <>
      <div className={styles.no_result}>
        <Lottie
          animationData={jsonAnimation || noRecord}
          loop={true}
          className={styles.about_left_lottie}
          style={{ width: 200, margin: "0 auto" }}
        />
        <h4>No Results Found!</h4>
      </div>
    </>
  );
};

export default NoResult;
