import React from 'react';
import { Container } from "react-bootstrap";
import P1 from '../../../assets/images/p1.png';
import P2 from '../../../assets/images/p2.png';
import P3 from '../../../assets/images/p3.png';
import P4 from '../../../assets/images/p4.png';
import P5 from '../../../assets/images/p5.png';
import styles from './Partners.module.scss';

const Partners = () => {
    return (
        <>
            <section className={styles.partners}>
                <Container>
                    <div className={styles.partners_inner}>
                        <ul>
                            <li><img src={P1} alt="" /></li>
                            <li><img src={P2} alt="" /></li>
                            <li><img src={P3} alt="" /></li>
                            <li><img src={P4} alt="" /></li>
                            <li><img src={P5} alt="" /></li>
                        </ul>
                    </div>
                </Container>
            </section>
        </>
    )
}

export default Partners