import React from "react";
import { Col, Row } from "react-bootstrap";
import CommonSearch from "../../../components/ui/CommonSearch/CommonSearch";
import styles from "./NotificationFilter.module.scss";
import { DeleteIcon, MarkIcon } from "../../../assets/svgIcons/SVGicon";
import { useDispatch } from "react-redux";
import {
  deleteAllUnReadMessageOrReadMessages,
  getAllNotifications,
  markAllReadMessage,
} from "../../../Redux/Reducers/notifications";
import {
  MarkAllReadNoftification,
  deleteAllNoftification,
} from "../../../features/serives/services";
import { NOFTIFICATION_TYPE } from "../../../features/Constant/Constant";
const NotificationFilter = ({
  search,
  searchvalue,
  setSearch,
  markbtn,
  setCurrPage,
  deletebtn,
  NotificationType,
}) => {
  const dispatch = useDispatch();

  const readAllNotifcations = () => {
    MarkAllReadNoftification();
    dispatch(markAllReadMessage(NotificationType));
    refreshAllNotificationForNewData();
  };
  const deleteAllMessage = () => {
    deleteAllNoftification(NotificationType);
    dispatch(deleteAllUnReadMessageOrReadMessages(NotificationType));
    refreshAllNotificationForNewData();
  };
  const refreshAllNotificationForNewData = () => {
    setTimeout(() => {
      dispatch(
        getAllNotifications({
          ReadOrUnReadType: NOFTIFICATION_TYPE.ALL,
          page: 1,
        })
      );
    }, 500);
  };
  return (
    <>
      <div className={styles.notification_filter}>
        {search && (
          <>
            <Row className="align-items-center">
              <Col xs={12} md={6}>
                <CommonSearch
                  setCurrentPage={setCurrPage}
                  searchvalue={searchvalue}
                  setSearchvalue={setSearch}
                  placeholder="Search..."
                  className={styles.notification_filter_search}
                />
              </Col>
              <Col xs={12} md={6} className="mt-5 mt-md-0">
                <div className={styles.notification_filter_action}>
                  {markbtn && (
                    <>
                      <button
                        onClick={readAllNotifcations}
                        className={styles.notification_filter_markbtn}
                      >
                        <MarkIcon /> Mark all as Read
                      </button>
                    </>
                  )}
                  {deletebtn && (
                    <>
                      <button
                        onClick={deleteAllMessage}
                        className={styles.notification_filter_deletebtn}
                      >
                        <DeleteIcon /> Delete all
                      </button>
                    </>
                  )}
                </div>
              </Col>
            </Row>
          </>
        )}
      </div>
    </>
  );
};
export default NotificationFilter;
