import React from "react";
import styles from "./HeaderIner.module.scss";
import { Col } from "react-bootstrap";

const HeaderInner = (props) => {
  return (
    <Col xs={12} className={styles.header}>
      {props.title && <h2>{props.title}</h2>}
      {props.subtitle && <p>{props.subtitle}</p>}
      <div className={styles.hl} />
    </Col>
  );
};

export default HeaderInner;
