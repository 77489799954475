import React from "react"
import { Link } from "react-router-dom"
import "./CommonBtn.scss"
import Spinners from "../Spinners/Spinners"

const CommonBtn = ({
  title,
  className,
  icon,
  onClick,
  to,
  role,
  type,
  disabled,
  loader,
  style,
}) => {
  return (
    <>
      {role === "link" ? (
        <Link to={to} className={`commonBtn ${className}`} onClick={onClick}>
          {icon && <span className="icon">{icon}</span>}
          {title && <span>{title}</span>}
        </Link>
      ) : (
        <button
          style={style}
          disabled={disabled}
          type={type}
          className={`commonBtn ${className}`}
          onClick={onClick}>
          {loader ? (
            <>
              <Spinners />
            </>
          ) : (
            <>
              {icon && <span className="icon">{icon}</span>}
              {title && <span>{title}</span>}
            </>
          )}
        </button>
      )}
    </>
  )
}
export default CommonBtn
