import React from "react";
import "./CreateTicketModal.scss";
import CommonModal from "../CommonModal/CommonModal";
import CommonBtn from "../CommonBtn/CommonBtn";
import { Form } from "react-bootstrap";
import { useFormik } from "formik";
import { createTicketValidation } from "../../../features/Validation/YupValidations";
import APIURLS from "../../../features/ApiPath/ApiPath";
import { apiCallPost } from "../../../features/Axios/Axios";
import { toasts } from "../../common/Toast/Toast";

const CreateTicketModal = ({ show, onHide, closeBtn, allTicketsData }) => {
  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
    },
    validationSchema: createTicketValidation,
    onSubmit: async (values) => {
      try {
        const res = await apiCallPost(
          `${APIURLS.SUPPORT.CREATE_TICKET}`,
          values,
          {},
          "toastOn"
        );
        if (res) {
          allTicketsData(allTicketsData);
          formik.resetForm({ values: "" });
        }
        onHide();
      } catch (error) {
        toasts.error(error?.message);
      }
    },
  });
  const cancleTicketCreation = () => {
    onHide();
    formik.resetForm({ values: "" });
  };
  return (
    <>
      <CommonModal
        closeBtn={closeBtn}
        show={show}
        onHide={onHide}
        className="ticket_modal"
        modalTitle={<>Create Ticket</>}
      >
        <Form>
          <Form.Group className="mb-3">
            <Form.Label className="mt-3">Title</Form.Label>
            <Form.Control
              type="text"
              placeholder="I have problem with transaction"
              id={"title"}
              name={"title"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.title}
            />
            {formik.touched.title && formik.errors.title && (
              <div className="error_field">
                <span>{formik.errors.title}</span>
              </div>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="mt-3">Description</Form.Label>
            <Form.Control
              as="textarea"
              type="text"
              placeholder="I tried to buy an item, the money transfer did not go through. Several times I re-created the deal-nothing come of it. I would like to know what the problem is and ask you to solve it"
              id={"description"}
              name={"description"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.description}
              className="descTextareaStyle"
            />
            {formik.touched.description && formik.errors.description && (
              <div className="error_field">
                <span>{formik.errors.description}</span>
              </div>
            )}
          </Form.Group>
        </Form>
        <div className="btn_sec">
          <CommonBtn
            loader={formik.isSubmitting}
            title="Create Ticket"
            onClick={formik.handleSubmit}
            disabled={!formik.isValid || formik.isSubmitting}
            type="submit"
          />
          <CommonBtn
            title="Cancel"
            className="btn-bordered"
            onClick={cancleTicketCreation}
          />
        </div>
      </CommonModal>
    </>
  );
};

export default CreateTicketModal;
