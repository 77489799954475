import React, { useState } from "react";
import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  ActionDividend,
  HamburgerIcon,
} from "../../../assets/svgIcons/SVGicon";
import { toasts } from "../../../components/common/Toast/Toast";
import WalletModal from "../../../components/ui/WalletModal/WalletModal";
import APIURLS from "../../../features/ApiPath/ApiPath";
import { apiCallPost } from "../../../features/Axios/Axios";
import { listNftApi } from "../../../features/Utility/Utility";
import ethProviders from "../../../features/web3/EthProviders";

const ListNftButton = ({ item, getAllFractions, className, disabled }) => {
  const walletAddress = useSelector((state) => state?.wallet?.walletAddress);
  const [walletModalShow, setWalletModalShow] = useState(false);
  const [signNftLoader, setSignNftLoader] = useState(false);

  // list Signature with price for the NFT Buy //
  const listNftSignature = async ({
    pricePerShare,
    counter,
    nftAmount,
    tokenId,
    nftId,
  }) => {
    const listId = counter;
    try {
      const { signature } = await ethProviders.listNftSign(
        {
          pricePerShare,
          tokenId,
          counter,
          nftAmount,
          owner: item.owner,
        },
        setSignNftLoader
      );
      if (signature) {
        try {
          await listNftApi({ nftId, listId, signature });
          getAllFractions();
        } catch (error) {}
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getDividendAddress = async (tokenId) => {
    try {
      const dividenddividend = await ethProviders.getNftDivident(tokenId);
      console.log({ dividenddividend });
    } catch (error) {}
  };
  // Mint NFT on contract with gas feee //
  const createNftToken = async (signMint) => {
    try {
      debugger;
      const createToken = await ethProviders.createToken(
        {
          tokenId: signMint?.tokenId,
          fractionsQuantity: signMint?.fractionsQuantity,
          uri: signMint?.uri,
          owner: signMint?.owner,
          signature: signMint?.signature,
        },
        setSignNftLoader
      );
      return createToken;
    } catch (error) {
      console.error(error);
    }
  };

  ///nftMint function///
  const nftMintApi = async (nftData) => {
    const {
      signature,
      nftId,
      isDividendAdded,
      tokenId,
      isListed,
      counter,
      price,
      owner,
      fractionsQuantity,
    } = nftData;
    const pricePerShare = price;
    const nftAmount = fractionsQuantity;
    try {
      const mintNftToaken = await createNftToken(nftData);
      debugger;
      await apiCallPost(
        APIURLS.NFT.NFT_MINTED,
        { signature, nftId, walletAddress: owner },
        {},
        "toastOnError"
      );
      if (mintNftToaken) {
        if (!isDividendAdded) {
          if (!isListed) {
            await listNftSignature({
              pricePerShare,
              counter,
              nftAmount,
              tokenId,
              nftId,
            });
          }
        } else {
          await getDividendAddress(tokenId);
          debugger;
        }
      }
    } catch (error) {
      getAllFractions();
    }
  };
  // First Signature for the NFT details //
  const signNftMint = async (nftData) => {
    const {
      tokenId,
      price,
      nftId,
      counter,
      isListed,
      isMint,
      isDividendAdded,
      fractionsQuantity,
    } = nftData;
    const pricePerShare = price;
    const nftAmount = fractionsQuantity;
    try {
      if (walletAddress) {
        if (isMint) {
          if (!isDividendAdded) {
            if (!isListed) {
              await listNftSignature({
                pricePerShare,
                counter,
                nftAmount,
                tokenId,
                nftId,
              });
            }
          } else {
            await getDividendAddress(tokenId);
            debugger;
          }
        } else {
          await nftMintApi(nftData);
        }
      } else {
        setWalletModalShow(true);
        toasts.warn("Please connect with wallet first.");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <OverlayTrigger
        key="overlay1"
        placement="top"
        overlay={
          <Tooltip>
            <span style={{ fontSize: "1.4rem" }}>
              {item?.mint
                ? !item?.isDividendAdded
                  ? "Add Dividend"
                  : "List"
                : "Mint nft"}
            </span>
          </Tooltip>
        }>
        <button
          onClick={() => signNftMint(item)}
          className={className}
          disabled={disabled}>
          {signNftLoader ? (
            <Spinner animation="border" variant="light" className="me-3 mt-0" />
          ) : item?.isMint && !item?.isDividendAdded ? (
            <ActionDividend />
          ) : (
            <HamburgerIcon />
          )}
        </button>
      </OverlayTrigger>
      {walletModalShow && (
        <WalletModal setWalletModalShow={setWalletModalShow} />
      )}
    </>
  );
};

export default ListNftButton;
