import React from 'react';
import BannerSec from './BannerSec/BannerSec';
import BoxesSec from './BoxesSec/BoxesSec';
import  HowItWorks2 from "../home/HowItWorks/HowItWorks";


const HowItWorks = () => {
  return (
    <>
    <BannerSec/>
    <HowItWorks2/>    
    <BoxesSec/>
    </>
  )
}

export default HowItWorks