import React from "react";
import { Pagination } from "react-bootstrap";
import styles from "./PaginationComponent.module.scss";

const PaginationComponent = ({
  requestPerPage,
  currentPage,
  setCurrentPage,
  totalCount,
}) => {
  const pageCount = Number(Math.ceil(totalCount / requestPerPage));
  const isCurrentPageFirst = currentPage === 1;
  const isCurrentPageLast = currentPage === pageCount;

  const changePage = (number) => {
    if (currentPage === number) return;
    setCurrentPage(number);
  };

  const onPageNumberClick = (pageNumber) => {
    changePage(pageNumber);
  };

  const onPreviousPageClick = (pageNumber) => {
    changePage((currentPage) => currentPage - 1);
  };

  const onNextPageClick = (pageNumber) => {
    changePage((currentPage) => currentPage + 1);
  };

  let isPageNumberOutOfRange;

  const pageNumbers = [...new Array(pageCount || null)].map((_, index) => {
    const pageNumber = index + 1;
    const isPageNumberFirst = pageNumber === 1;
    const isPageNumberLast = pageNumber === pageCount;
    const isCurrentPageWithinTwoPageNumber =
      Math.abs(pageNumber - currentPage) <= 2;

    if (
      isPageNumberFirst ||
      isPageNumberLast ||
      isCurrentPageWithinTwoPageNumber
    ) {
      isPageNumberOutOfRange = false;

      return (
        <Pagination.Item
          key={pageNumber}
          onClick={() => onPageNumberClick(pageNumber)}
          active={pageNumber === currentPage}
        >
          {pageNumber === currentPage ? `Page ${pageNumber}` : pageNumber}
        </Pagination.Item>
      );
    }

    if (!isPageNumberOutOfRange) {
      isPageNumberOutOfRange = true;
      return <Pagination.Ellipsis key={pageNumber} className="muted" />;
    }

    return null;
  });

  return (
    <>
      {pageCount > 1 && (
        <Pagination className={styles.page_nation}>
          <Pagination.Prev
            onClick={onPreviousPageClick}
            disabled={isCurrentPageFirst}
            className={styles.page_nation_first}
          />
          {pageNumbers}{" "}
          <li className="page-item" style={{ marginRight: "0.5rem" }}>
            <span className="page-link"> of {pageCount}</span>
          </li>
          <Pagination.Next
            onClick={onNextPageClick}
            disabled={isCurrentPageLast}
            className={styles.page_nation_last}
          />
        </Pagination>
      )}
    </>
  );
};

export default PaginationComponent;
