import React from "react";
import CommonModal from "../../components/ui/CommonModal/CommonModal.jsx";
import { Col, Form } from "react-bootstrap";
import styles from "./ProfileSettings.module.scss";
import CommonBtn from "../../components/ui/CommonBtn/CommonBtn";
import { useFormik } from "formik";
import { changeEmailValidation } from "../../features/Validation/YupValidations.js";
import APIURLS from "../../features/ApiPath/ApiPath.js";
import { apiCallPost } from "../../features/Axios/Axios.js";

const ChangeEmail = ({
  show,
  onHide,
  setChangeEmailModal,
  setVerifyChangeEmail,
  setNewEmail,
}) => {
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: changeEmailValidation,
    onSubmit: async (values) => {
      try {
        const res = await apiCallPost(
          `${APIURLS.USER.ADD_EMAIL}`,
          values,
          {},
          "toastOn"
        );
        if (res) {
          setNewEmail(values.email);
          setChangeEmailModal(false);
          setVerifyChangeEmail(true);
        }
      } catch (error) {
        setChangeEmailModal(true);
        setVerifyChangeEmail(false);
      }
    },
  });
  return (
    <CommonModal
      show={show}
      onHide={onHide}
      modalTitle="Change Mail ID"
      className={styles.changeEmail}
    >
      <Form onSubmit={formik.handleSubmit}>
        <Col sm={12} className={styles.changeEmail_inputStyle}>
          <Form.Group className="mb-3">
            <Form.Label>New Mail ID</Form.Label>
            <div style={{ position: "relative" }}>
              <Form.Control
                type="text"
                placeholder="abc@gmail.com"
                id="email"
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
            </div>
            {formik.errors.email && (
              <div className="error_field">
                <span>{formik.errors.email}</span>
              </div>
            )}
          </Form.Group>
        </Col>
        <Col sm={12}>
          <p className={styles.changeEmail_noteStyle}>
            <span>Please Note:</span>
            Changing the Mail ID will change the credentials of the platform.
            Existing Mail ID will no longer exist.
          </p>
        </Col>
        <Col sm={12}>
          <CommonBtn
            title="CHANGE EMAIL"
            className={styles.changeEmail_changePassword}
            type="submit"
          />
        </Col>
      </Form>
    </CommonModal>
  );
};

export default ChangeEmail;
