import React, { useState, useEffect } from "react";
import styles from "./DetailPage.module.scss";
import CommonTable from "../../../components/ui/CommonTable/CommonTable";
import CommonSelectbox from "../../../components/ui/Dropdowns/CommonSelectbox.jsx";
import { Col, Row } from "react-bootstrap";
import {
  BUYICON,
  CARTICON,
  DIVIDENDICON,
  DownloadDoc,
  LISTICON,
  MINTICON,
  SortIcon,
} from "../../../assets/svgIcons/SVGicon";
import { apiCallPost } from "../../../features/Axios/Axios";
import APIURLS from "../../../features/ApiPath/ApiPath";
import {
  formatDate,
  isArrayEmpty,
  openEtherScan,
  trimText,
} from "../../../features/Utility/Utility";
import { FILTER_PROFILE_FRACTION_SORT } from "../../../features/Constant/Constant";
import PaginationComponent from "../../../components/common/pagination/PaginationComponent";

const limit = 8;
const ActivityTable = ({ paramId }) => {
  const [allActivities, setAllActivities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currPage, setCurrPage] = useState(1);
  const [filterValue, setFilterValue] = useState(
    FILTER_PROFILE_FRACTION_SORT[0].value
  );

  const getAllActivities = async () => {
    try {
      setLoading(true);
      try {
        const { data } = await apiCallPost(
          `${APIURLS.NFT.GET_ALL_ACTIVITY}/${paramId}/${currPage}/${limit}`,
          { order: filterValue }
        );
        console.log("historyData", data);
        setAllActivities(data);
      } catch (error) {}
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllActivities();
  }, [filterValue, currPage]);

  return (
    <>
      <div className={`${styles.history_head} pb-5`}>
        <Row className="align-items-center">
          <Col md={6} className="text-center text-sm-start">
            <h4>Activity</h4>
          </Col>
          <Col md={6}>
            {allActivities?.count > 0 && (
              <div className={`text-md-end ${styles.activity_sec_act}`}>
                <CommonSelectbox
                  icon={<SortIcon />}
                  title="All"
                  type="filter"
                  options={FILTER_PROFILE_FRACTION_SORT}
                  className="me-lg-5 me-2 dark"
                  setFilterValue={setFilterValue}
                />
              </div>
            )}
          </Col>
        </Row>
        <hr />
        <CommonTable
          isDataEmpty={isArrayEmpty(allActivities?.count)}
          className="table-box"
        >
          <thead>
            <tr>
              <th>Event</th>
              <th>Price</th>
              <th>Transaction Hash</th>
              <th>From</th>
              <th>To</th>
              <th width="148">Date</th>
            </tr>
          </thead>
          <tbody>
            {allActivities && (
              <>
                {allActivities?.rows?.map((item, index) => (
                  <tr key={index}>
                    <td>
                      {item.type === "Buy" && (
                        <>
                          <span
                            style={{
                              display: "inline-block",
                              marginRight: 3,
                              verticalAlign: "middle",
                              position: "relative",
                              top: -3,
                            }}
                          >
                            <BUYICON />
                          </span>
                          {item.type}
                        </>
                      )}
                      {item.type === "Sale" && (
                        <>
                          <CARTICON />
                          {item.type}
                        </>
                      )}
                      {item.type === "Dividend" && (
                        <>
                          <DIVIDENDICON /> {item.type}
                        </>
                      )}
                      {item.type === "List" && (
                        <>
                          <LISTICON /> {item.type}
                        </>
                      )}
                      {item.type === "Mint" && (
                        <>
                          <MINTICON /> {item.type}
                        </>
                      )}
                    </td>
                    <td>$ {item.price}</td>
                    <td>
                      <a
                        href="#"
                        style={{ cursor: "pointer" }}
                        title={item?.txnHash}
                        onClick={() => openEtherScan(item?.txnHash)}
                        target="_blank"
                      >
                        {trimText(item?.txnHash)}
                      </a>
                    </td>
                    <td>{trimText(item.fromAddress)}</td>
                    <td>{trimText(item.toAddress)}</td>
                    <td>
                      {formatDate(item.createdAt)}
                      <a className="download-doc" href="#">
                        <DownloadDoc />
                      </a>
                    </td>
                  </tr>
                ))}
              </>
            )}
          </tbody>
        </CommonTable>
      </div>
      <PaginationComponent
        currentPage={currPage}
        requestPerPage={limit}
        setCurrentPage={setCurrPage}
        totalCount={allActivities?.count}
      />
    </>
  );
};

export default ActivityTable;
