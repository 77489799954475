import React, { useEffect, useState } from "react";
import styles from "./CheckoutModal.module.scss";
import CommonModal from "../CommonModal/CommonModal";
import CommonBtn from "../CommonBtn/CommonBtn";
import { Button, Dropdown, Form } from "react-bootstrap";
import { MinusIcon, PlusIcon } from "../../../assets/svgIcons/SVGicon";
import ethProviders from "../../../features/web3/EthProviders";
import { useSelector } from "react-redux";
import { apiCallPost } from "../../../features/Axios/Axios";
import APIURLS from "../../../features/ApiPath/ApiPath";
import Web3 from "web3";
import { toasts } from "../../common/Toast/Toast";
const CheckOutModal = ({
  show,
  onHide,
  setCheckoutModal,
  className,
  nftDetails,
  listingData,
  getNftListing,
}) => {
  const { ethereum } = window;
  const web3 = new Web3(ethereum);
  const [balance, setBalance] = useState(0);
  const [nftCopy, setNftCopy] = useState(1);
  const [coinPrice, setCoinPrice] = useState(0);
  const [coinConverstion, setCoinConverstion] = useState({});
  const [platformFee, setPlatformFee] = useState("");
  const [nftBuyLoader, setNftBuyLoader] = useState(false);
  const selectedCurrency = "eth";
  const userWallet = useSelector((state) => state.wallet.walletAddress);
  const walletBalance = useSelector((state) => state.wallet.walletBalance);
  const account = useSelector((state) => state?.user?.userData?.userWallets);
  const userBalance = async () => {
    const res = await ethProviders.getBalance(account);
    setBalance(res);
  };
  const platformFees = async () => {
    try {
      const res = await ethProviders.verifyPlatformFee();
      if (res) {
        setPlatformFee(res);
      }
    } catch (error) {}
  };
  const coinLatestPrice = async () => {
    try {
      const res = await apiCallPost(
        APIURLS.COIN_PRICE,
        {
          amountToBuy: nftCopy,
          currency: selectedCurrency,
          price: listingData?.price,
        },
        {},
        "toastOnError"
      );
      if (res) {
        setCoinConverstion(res?.data);
        let finalPrice = web3.utils.fromWei(
          res?.data?.conversionRate.toString(),
          "ether"
        );
        setCoinPrice(+finalPrice);
      }
    } catch (error) {}
  };
  const lockNftCopys = async (data) => {
    setNftBuyLoader(true);
    try {
      const res = await apiCallPost(
        `${APIURLS.NFT.LOCK_NFT}`,
        data,
        {},
        "toastOnError"
      );
      if (res && !data.revertQauntity) {
        buyToken();
      }
    } catch (error) {
      setNftBuyLoader(false);
    }
  };
  const buyToken = async () => {
    setNftBuyLoader(true);
    debugger;
    try {
      let finalPrice = web3.utils.toWei(listingData?.price, "shannon");
      const NFTListing = {
        pricePerShare: +finalPrice,
        tokenId: +nftDetails?.tokenId,
        counter: listingData?.id, // counter per listing for the NFT
        nftAmount: listingData?.quantity,
        owner: listingData?.wallet,
        signature: listingData?.signature,
      };
      const buyDetails = {
        conversionRate: +coinConverstion?.conversionRate,
        amountToBuy: coinConverstion?.amountToBuy,
        counter: coinConverstion?.counter,
        timeStamp: coinConverstion?.timeStamp,
        currency: coinConverstion?.currency,
        signature: coinConverstion?.signature,
      };
      const verifyNFTListing = await ethProviders.verifyNFTListing(NFTListing);
      const verifyBuyDetails = await ethProviders.verifyBuyDetails(buyDetails);
      console.log({ verifyBuyDetails }, "nftDetails");
      console.log({ verifyNFTListing }, "nftDetails");
      const buyData = {
        NFTListing,
        buyDetails,
        buyer: userWallet,
        amountToBuy: coinConverstion?.amountToBuy,
        userBalance: walletBalance,
      };
      const finalBuy = await ethProviders.buyToken(buyData, setNftBuyLoader);
      if (finalBuy) {
        const data = {
          nftId: listingData?.nftId,
          nftListId: listingData?.id,
          // totalPrice: finalBuy?.totalPrice,
          txnAmount: finalBuy?.txnAmount,
          txnFees: finalBuy?.txnFees,
          platformFees: platformFee,
          quantity: coinConverstion?.amountToBuy,
          txnHash: finalBuy?.txnHash,
          currency: selectedCurrency,
        };
        const postNftBuy = await apiCallPost(
          APIURLS.BUY_NFT,
          data,
          {},
          "toastOn"
        );
        if (postNftBuy) {
          setCheckoutModal(false);
          getNftListing();
        }
      }
    } catch (error) {
      console.error(error, "nftDetails");
      toasts.error(error?.message);
      await lockNftCopys({
        nftId: nftDetails?.nftId,
        nftListId: listingData?.id,
        revertQauntity: nftCopy,
      });
      setNftBuyLoader(false);
    }
  };
  useEffect(() => {
    nftCopy && coinLatestPrice();
  }, [nftCopy, selectedCurrency]);
  useEffect(() => {
    userBalance();
    platformFees();
  }, [account]);

  console.log(nftDetails, listingData, "nftDetails");
  return (
    <>
      <CommonModal
        show={show}
        onHide={onHide}
        className={`${className} ${styles.checkout_modal}`}
        modalTitle="Checkout"
        subTitle={
          <>
            <div className="d-flex align-items-center justify-content-start gap-4 py-4 px-5 w-100">
              <img src={nftDetails?.image} alt="nft Details" />
              <div className="d-inline-block text-start">
                <h4>{nftDetails?.title}</h4>
                <p>{nftDetails?.collection?.title}</p>
              </div>
            </div>
          </>
        }>
        <div className="inner_sec">
          <div className={styles.inner_sec_text}>
            <p>Fractions</p>
            <p>
              <span>Max</span>
            </p>
          </div>
          <div className={styles.counter}>
            <Button
              className={styles.counter_rate}
              onClick={() => setNftCopy(nftCopy - 1)}
              disabled={nftCopy <= 1}>
              <MinusIcon />
            </Button>
            <Form.Control
              type="number"
              placeholder={nftDetails?.quantity}
              value={
                nftCopy >= listingData?.quantityLeft
                  ? listingData?.quantityLeft
                  : nftCopy
              }
              disabled={nftCopy >= listingData?.quantityLeft || nftCopy < 1}
              onChange={(e) =>
                setNftCopy(
                  +e.target.value >= listingData?.quantityLeft
                    ? listingData?.quantityLeft
                    : +e.target.value
                )
              }
            />
            <Button
              className={styles.counter_rate}
              onClick={() => setNftCopy(nftCopy + 1)}
              disabled={nftCopy >= listingData?.quantityLeft}>
              <PlusIcon />
            </Button>
          </div>
          <div className={styles.inner_sec_text}>
            <p>
              <span>Platform Fees:</span> {platformFee}%
            </p>
            <p>
              <span>Available:</span> {listingData?.quantityLeft}
            </p>
          </div>
          <div className={styles.currencySelect}>
            <h4 className={styles.currencySelect_textWrap}>
              <span className={styles.currencySelect_textWrap_static}>
                {listingData?.price} USD =
              </span>
              {parseFloat(coinPrice * listingData?.price * nftCopy).toFixed(4)}
              <Dropdown
                className={styles.currencySelect_textWrap_dropDownWrap}
                // onSelect={(eventKey) => setSelectedCurrency(eventKey)}
              >
                <Dropdown.Toggle>
                  {selectedCurrency.toUpperCase()}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item eventKey="usd">USD</Dropdown.Item>
                  <Dropdown.Item eventKey="usdt">USDT</Dropdown.Item>
                  <Dropdown.Item eventKey="eth">ETH</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </h4>
          </div>
          <div className={styles.amount_sec}>
            <p className="mb-3 fw-normal">Amount to Pay</p>
            <div className={styles.amount_sec_bal}>
              <h4>
                {selectedCurrency.toUpperCase()}{" "}
                <span>
                  {parseFloat(coinPrice * listingData?.price * nftCopy).toFixed(
                    4
                  )}
                </span>
              </h4>
            </div>
            <p className="mt-3 fw-normal">
              <span>Balance:</span> {parseFloat(balance).toFixed(2)} ETH
            </p>
          </div>
        </div>
        <CommonBtn
          className="my-5 w-100 btn-bordered"
          title="Continue"
          onClick={() =>
            lockNftCopys({
              nftId: nftDetails?.nftId,
              nftListId: listingData?.id,
              lockQauntity: nftCopy,
            })
          }
          loader={nftBuyLoader}
          disabled={nftCopy <= 0}
        />
      </CommonModal>
    </>
  );
};
export default CheckOutModal;
