import React from 'react'
import bannerimg from "../../../assets/images/baneersecpic.png"
import { Col, Container, Row } from 'react-bootstrap';
import styles from "./BannerSec.module.scss";

function BannerSec() {
    return (
        <div className={styles.banner_sec}>
            <Container>
                <Row className='align-items-center'>
                    <Col lg={6}>
                        <div className={styles.banner_sec_content}>
                            <h1>We Rise By <span>Lifting</span> Others</h1>
                            <p>The placeholder text, beginning with the line Lorem ipsum dolor sit amet, consectetur adipiscing placeholder. The placeholder text, beginning with the line Lorem ipsum dolor sit amet, consectetur adipiscing placeholder</p>
                            
                        </div>
                        <div className={styles.banner_sec_text}>
                            <div className={styles.bannersec_data}>
                                <p>Founded</p>
                                <h2>2017</h2>
                            </div>
                            <div className={styles.bannersec_data}>
                                <p>Employees</p>
                                <h2>200+</h2>
                            </div>
                            <div className={styles.bannersec_data}>
                                <p>Collections</p>
                                <h2>2M</h2>
                            </div>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className={styles.bannersec_pic}>
                        <img  src={bannerimg} alt="" />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default BannerSec
