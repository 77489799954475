import { createSlice } from "@reduxjs/toolkit"
import { KYC_STATUS } from "../../features/Constant/Constant"
import moment from "moment"

const userSlice = createSlice({
  name: "user",
  initialState: {
    userLogin: false,
    userData: {},
    tokenExpires: false,
    showLogInLogInModal: false,
    showCompleteKYCModal: false,
  },

  reducers: {
    setGlobalSerach: (state, action) => {
      state.globalSearchTerm = action.payload
    },
    setUserLogin: (state, action) => {
      state.userLogin = action.payload
    },
    setUserData: (state, action) => {
      state.userData = action.payload
    },
    setWalletEmpty: (state, action) => {
      console.log(state.userData, action.payload.userWallets, "state dfbdfbn")
    },
    setTokenExpires: (state, action) => {
      state.tokenExpires = action.payload
    },
    setCompleteKYCModal: (state, action) => {
      state.showCompleteKYCModal = action.payload
    },
    setShowLogInLogInModal: (state, action) => {
      state.showLogInLogInModal = action.payload
    },
    setKycStatus: (state, action) => {
      state.userData.isKycDone = true
      state.userData.userKyc = {
        kycStatus: KYC_STATUS[action.payload],
        createdAt: moment().format(),
        updatedAt: moment().format(),
      }
    },
    resetUser(state) {
      Object.assign(state, {
        userLogin: false,
        userData: {},
        tokenExpires: false,
        showLogInLogInModal: false,
        showCompleteKYCModal: false,
      })
    },
  },
})

export const {
  setUserLogin,
  setUserData,
  setTokenExpires,
  setKycStatus,
  resetUser,
  setGlobalSerach,
  setCompleteKYCModal,
  setShowLogInLogInModal,
} = userSlice.actions

export default userSlice.reducer
