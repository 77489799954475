import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";
import { setGlobalSearch } from "../../Redux/Reducers/globalSearch";
import { useGlobalSearchContext } from "../../context/GlobalSearchContext";

const Search = () => {
  const { setGlobalSearchTerm } = useGlobalSearchContext();
  useEffect(() => {
    return () => setGlobalSearchTerm("");
  }, []);
  return (
    <>
      <Outlet />
    </>
  );
};

export default Search;
