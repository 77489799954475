import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import styles from "./About.module.scss";
import Lottie from "lottie-react";
import CommonBtn from "../../../components/ui/CommonBtn/CommonBtn";
import use_assets from "../../../assets/images/use_assets.png";
import { Image_kit_key } from "../../../features/Constant/Constant";
import aboutLottier from "../../../assets/lottie/about.json";

const About = () => {
  const [jsonData, setJsonData] = useState();
  const [loaderLootie, setLoaderLootie] = useState(false);

  useEffect(() => {
    setLoaderLootie(true);
    fetch(`https://ik.imagekit.io/${Image_kit_key}/lottie/about.json`)
      .then((res) => res.json())
      .then((json) => {
        setTimeout(() => {
          setJsonData(json);
          setLoaderLootie(false);
        }, 2000);
      })
      .catch((err) => {
        setLoaderLootie(false);
      });
  }, []);
  return (
    <>
      <section className={styles.about}>
        <Container>
          <Row className="align-items-center">
            <Col md={6} className="pt-xxl-5 order-md-last pb-4 pb-md-0">
              <div className={styles.about_right}>
                <h2>
                  <span>About Xegara</span>
                  Use Your Assets as NFTs
                </h2>
                <p>
                  The decade that brought us Star Trek and Doctor Who also
                  resurrected Cicero—or at least what used to be Cicero—in an
                  attempt to make the days before computerized design a little
                  less painstaking.{" "}
                </p>
                <p>
                  The French lettering company Letraset manufactured a set of
                  dry-transfer sheets which included the lorem ipsum filler text
                  in a variety of fonts, sizes, and layouts. These sheets of
                  lettering could be rubbed on anywhere and were quickly
                  adopted.
                </p>
                <p>
                  These sheets of lettering could be rubbed on anywhere and were
                  quickly adopted by graphic artists, printers, architects, and
                  advertisers for their professional look and ease of use.
                </p>
                <CommonBtn
                  title="LEARN MORE"
                  className="btn-bordered"
                  role="link"
                  to="/about"
                />
              </div>
            </Col>
            <Col md={6}>
              <div className={styles.about_left}>
                <div className={styles.about_left_inner}>
                  {loaderLootie ? (
                    <img src={use_assets} alt="About Xegara" />
                  ) : (
                    <Lottie
                      animationData={jsonData || aboutLottier}
                      loop={true}
                      className={styles.about_left_lottie}
                    />
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default About;
