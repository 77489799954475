import { createSlice } from "@reduxjs/toolkit"
import { store } from "../Store"

const walletSlice = createSlice({
  name: "wallet",
  initialState: {
    walletAddress: "",
    walletBalance: "",
  },
  reducers: {
    setWalletAddress: (state, action) => {
      state.walletAddress = action.payload
    },
    setWalletBalance: (state, action) => {
      state.walletBalance = action.payload
    },
    resetWallet(state) {
      Object.assign(state, {
        walletAddress: "",
      })
    },
  },
})

export const { setWalletAddress, setWalletBalance, resetWallet } =
  walletSlice.actions

export default walletSlice.reducer
