import { Formik, Form, Field, FieldArray, useFormik } from "formik";
import React from "react";
import { useRef } from "react";

import CommonBtn from "../../components/ui/CommonBtn/CommonBtn";
import PrevImg from "../profilesettings/PrevImg";
import APIURLS from "../../features/ApiPath/ApiPath";
import { apiCallPost } from "../../features/Axios/Axios";

const Fileup = () => {
  const formik = useFormik({
    initialValues: {
      friends: ["jared", "ian", "brent"],
    },
    onSubmit: async (values) => {
    
    },
  });
  const friends = formik.values.friends;
  return (
    <>
      <div>dsfsdf</div>
    </>
  );
};

export default Fileup;
