import React from "react";
import { useState } from "react";
import { VIDEO_EXT_TYPE, VIDEO_TYPE } from "../../features/Constant/Constant";

const PrevImg = ({ file, className = "" }) => {
  const [preview, setPreview] = useState("");
  const [videoPreview, setVideoPreview] = useState("");

  if (typeof file !== "string") {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (VIDEO_TYPE.includes(file.type)) {
        setVideoPreview(reader.result);
      } else {
        setPreview(reader.result);
      }
    };
  }

  console.log({ file });

  return (
    <>
      {videoPreview ||
      (typeof file === "string" &&
        VIDEO_EXT_TYPE.includes(file?.split(".").pop())) ? (
        <video
          controls
          src={
            typeof file === "string" &&
            VIDEO_EXT_TYPE.includes(file?.split(".").pop())
              ? file
              : videoPreview
          }
          autoPlay
        ></video>
      ) : (
        <img
          src={typeof file === "string" ? file : preview}
          className={`profileImg ${className}`}
          alt={"hanji"}
        />
      )}
    </>
  );
};

export default PrevImg;
