import React, { useState, useEffect } from "react";
import {
  ActionBurn,
  ActionDividend,
  ActionEye,
  ActionRemove,
  HamburgerIcon,
  PendingIcon,
  RejectedIcon,
  TickIcon,
} from "../../../assets/svgIcons/SVGicon";
import CommonTable from "../../../components/ui/CommonTable/CommonTable";
import SearchAndFilter from "../commonComponents/SearchAndFilter/SearchAndFilter";
import styles from "./ProfileFractionalize.module.scss";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import DividendModal from "./DividendModal";
import { apiCallPost, apiCallPut } from "../../../features/Axios/Axios";
import APIURLS from "../../../features/ApiPath/ApiPath";
import moment from "moment";
import img from "../../../assets/images/logo-large.png";
import { json, useNavigate } from "react-router-dom";
import PaginationComponent from "../../../components/common/pagination/PaginationComponent";
import useDebounce from "../../../hooks/useDebounce";
import NoResult from "../../../components/ui/NoResult/NoResult";
import {
  captilizeFirstCharacter,
  isArrayEmpty,
  listNftApi,
} from "../../../features/Utility/Utility";
import ListNftButton from "./ListNftButton";
import {
  FILTER_PROFILE_FRACTION_SORT,
  FILTER_PROFILE_FRACTION_STATUS,
  STATUS,
} from "../../../features/Constant/Constant";
import AlertModal from "../../../components/ui/AlertModal/AlertModal";

const ProfileFractionalize = () => {
  const navigation = useNavigate();
  const [showDividendModal, setShowDividendModal] = useState(false);
  const [allList, setAllList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [searchvalue, setSearchvalue] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [currIdx, setCurrIdx] = useState(0);
  const [statusFilter, setStatusFilter] = useState("ALL");
  const debounceTerm = useDebounce(searchvalue, 500);
  const [currntIndexData, setCurrntIndexData] = useState({});

  const [alertModalShow, setAlertModalShow] = useState(false);

  const showDividendHandler = (item, index) => {
    setCurrntIndexData(item);
    setCurrIdx(index);
    setShowDividendModal(true);
  };

  const getAllFractions = async () => {
    setLoader(true);
    try {
      const res = await apiCallPost(
        `${APIURLS.NFT.FRACTIONALIZE_LIST}/10/${currentPage}`,
        { search: debounceTerm, order: filterValue, status: statusFilter },
        {}
      );
      setAllList(res?.data);
      setLoader(false);
      console.log("filsteRes", res?.data);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  }

  useEffect(() => {
    getAllFractions();
  }, [filterValue, currentPage, debounceTerm, statusFilter]);
  const fractionButtonStatus = (status) => {
    if (status === "REJECTED") {
      return "_rejected";
    } else if (status === "PENDING") {
      return "_pending";
    } else {
      return "_approved";
    }
  };
  const fractionIconsStatus = (status) => {
    if (status === "REJECTED") {
      return <RejectedIcon />;
    } else if (status === "PENDING") {
      return <PendingIcon />;
    } else {
      return <TickIcon />;
    }
  };

  const fractionViewHandler = (id, status) => {
    navigation("/fractionalize", { state: { id, status } });
  };
  const cancelRequestHandler = async (id) => {
    try {
      const res = await apiCallPut(
        `/${APIURLS.NFT.FRACTIONALIZE_GET_BYID}/${id}`,
        { cancel: true },
        {},
        "toastOn"
      );
      if (res) {
        getAllFractions();
      }
    } catch (error) {}
  };
  const burnNFT = async () => {
    try {
      await apiCallPost(
        APIURLS.BURN_REQ,
        {
          nftId: currntIndexData?.nftId,
          nftListId: currntIndexData?.counter,
        },
        {},
        "toastOn"
      );
      // let allFractData = allList
      // allFractData.rows[currIdx].nftBurnRequest = "PENDING"
      // setAllList(allFractData)
      getAllFractions();
      setAlertModalShow(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleNftBurn = async (item, index) => {
    setCurrntIndexData(item);
    console.log({ item });
    const { nftBurnRequest = "", isMint = "" } = item;
    if (!isMint) return;
    if (nftBurnRequest !== STATUS.ACCEPTED) {
      setAlertModalShow(true);
      setCurrIdx(index);
    }
  };

  return (
    <>
      <div className={styles.fractionalize}>
        <SearchAndFilter
          search={true}
          setSearchvalue={setSearchvalue}
          searchvalue={searchvalue}
          newestOptions={FILTER_PROFILE_FRACTION_SORT}
          setNewestFilterValue={setFilterValue}
          newest
          type={"filter"}
          setCurrentPage={setCurrentPage}
          profileFilters={FILTER_PROFILE_FRACTION_STATUS}
          setStatusFilter={setStatusFilter}
        />
        <CommonTable
          isLoading={loader}
          isDataEmpty={isArrayEmpty(allList?.count)}
          className="table-box mt-5"
        >
          <thead>
            <tr>
              <th>Assets</th>
              <th>Req. Date & Time</th>
              <th>Status</th>
              <th style={{ width: "16rem" }}>Action</th>
            </tr>
          </thead>
          <tbody>
            {loader && (
              <tr>
                <td colspan="4" style={{ height: "200px" }}>
                  <p
                    style={{
                      textAlign: "center",
                      color: "#fff",
                      fontSize: "16px",
                    }}
                  >
                    Loading...
                  </p>
                </td>
              </tr>
            )}
            {!loader &&
              allList &&
              allList?.rows?.map((item, index) => (
                <>
                  <tr key={item?.fractionalizeId}>
                    <td>{item.title}</td>
                    <td>{moment(item?.createdAt).format("MMM DD, YYYY")} </td>
                    <td>
                      {" "}
                      {fractionIconsStatus(item.status)}{" "}
                      <span
                        className={`fractionalize${fractionButtonStatus(
                          item.status
                        )}`}
                      >
                        {item.status === STATUS.ACCEPTED
                          ? "APPROVED"
                          : item.status}
                      </span>
                    </td>
                    <td className={styles.fractionalize_actions}>
                      {(item?.status === STATUS.REJECTED ||
                        item?.status === STATUS.PENDING) && (
                        <OverlayTrigger
                          key="overlay1"
                          placement="top"
                          overlay={
                            <Tooltip>
                              <span style={{ fontSize: "1.4rem" }}>View</span>
                            </Tooltip>
                          }
                        >
                          <button
                            className={`${styles.fractionalize_actions_buttonStyle} `}
                            onClick={() =>
                              fractionViewHandler(
                                item?.fractionalizeId,
                                item?.status
                              )
                            }
                          >
                            <ActionEye />
                          </button>
                        </OverlayTrigger>
                      )}
                      {item?.status === STATUS.ACCEPTED && !item?.isListed && (
                        <ListNftButton
                          item={item}
                          className={`${styles.fractionalize_actions_buttonStyle}`}
                          getAllFractions={getAllFractions}
                        />
                      )}
                      {item?.status && item?.status === STATUS.PENDING && (
                        <OverlayTrigger
                          key="overlay1"
                          placement="top"
                          overlay={
                            <Tooltip>
                              <span style={{ fontSize: "1.4rem" }}>Cancel</span>
                            </Tooltip>
                          }
                        >
                          <button
                            className={`${styles.fractionalize_actions_buttonStyle} `}
                            onClick={() =>
                              cancelRequestHandler(item?.fractionalizeId)
                            }
                          >
                            <ActionRemove />
                          </button>
                        </OverlayTrigger>
                      )}
                      {/* {item.status === STATUS.ACCEPTED && !item?.isListed && (
                        <OverlayTrigger
                          key="overlay1"
                          placement="top"
                          overlay={
                            <Tooltip>
                              <span style={{ fontSize: "1.4rem" }}>
                                Add Dividend
                              </span>
                            </Tooltip>
                          }
                        >
                          <button
                            className={`${styles.fractionalize_actions_buttonStyle}`}
                            onClick={() => showDividendHandler(item, index)}
                          >
                            <ActionDividend />
                          </button>
                        </OverlayTrigger>
                      )} */}
                      {item?.nftBurnRequest !== STATUS.PENDING &&
                        item?.isMint && (
                          <OverlayTrigger
                            key="overlay1"
                            placement="top"
                            overlay={
                              <Tooltip>
                                <span
                                  style={{
                                    fontSize: "1.4rem",
                                  }}>
                                  Burn nft
                                </span>
                              </Tooltip>
                            }>
                            <button
                              onClick={() => {
                                handleNftBurn(item, index)
                              }}
                              className={
                                item?.nftBurnRequest === STATUS.NOT_SEND
                                  ? styles.fractionalize_actions_buttonStyle
                                  : `${styles.fractionalize_actions_buttonStyle} ${styles.fractionalize_actions_buttonDisable}`
                              }>
                              <ActionBurn />
                            </button>
                          </OverlayTrigger>
                        )}
                    </td>
                  </tr>
                </>
              ))}
          </tbody>
        </CommonTable>
        <PaginationComponent
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          requestPerPage={10}
          totalCount={allList?.count}
        />
      </div>
      {alertModalShow && (
        <AlertModal
          img={img}
          title={
            <div className="px-lg-5">{`Are You Sure You Want To Burn ${
              !isArrayEmpty(allList?.rows) && allList?.rows[currIdx]?.title
            } NFT`}</div>
          }
          btnAction={burnNFT}
          btnText="Send Burn Request"
          show={alertModalShow}
          onHide={() => setAlertModalShow(false)}
        />
      )}
      {showDividendModal && (
        <DividendModal
          fractionData={currntIndexData}
          show={showDividendModal}
          onHide={() => setShowDividendModal(false)}
        />
      )}
    </>
  );
};

export default ProfileFractionalize;
