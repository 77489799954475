import React, { useEffect, useState } from "react";
import styles from "./DetailPage.module.scss";
import { Link, useParams } from "react-router-dom";
import CheckAuthenticityModal from "./AuthDocModal/CheckAuthenticityModal";
import { apiCallGet } from "../../../features/Axios/Axios";
import APIURLS from "../../../features/ApiPath/ApiPath";

const DetailPageContent = ({
  totalFractions,
  availableFraction,
  youOwn,
  dateOfMinting,
  quantity,
  dividend,
  encryptedDocuments,
  paramId,
}) => {
  const { nftId } = useParams();

  const [showDoc, setShowDocs] = useState(false);

  const showDocHandler = () => {
    setShowDocs(true);
  };

  return (
    <>
      <div className={styles.detailpage_content}>
        {/* <div className={styles.detailpage_content_inner}>
                    <img src={location} alt="location_img" />
                    <p>Villa Paradise, MC Street, Near Church, Toronto, Canada.</p>
                </div> */}

        <div className={styles.detailpage_content_value}>
          <div className={styles.detailpage_content_value_data}>
            <p>Total Fractions</p>
            <h4>{quantity}</h4>
          </div>
          <div className={styles.vl} />
          <div className={styles.detailpage_content_value_data}>
            <p>Fractions Available</p>
            <h4>{availableFraction}</h4>
          </div>
          <div className={styles.vl} />
          <div className={styles.detailpage_content_value_data}>
            <p>You Own</p>
            <h4>{youOwn || 0}</h4>
          </div>
        </div>
        <div className={styles.detailpage_content_value}>
          <div className={styles.detailpage_content_value_data}>
            <p>Date of Minting</p>
            <h4>{dateOfMinting}</h4>
          </div>
          <div className={styles.vl} />
          <div className={styles.detailpage_content_value_data}>
            <p>ROI p.a</p>
            <h4>XAR {dividend}</h4>
          </div>
          <div className={styles.vl} />
          <div className={styles.detailpage_content_value_data}>
            <p>Check Authenticity</p>
            <Link to="#">
              <span onClick={() => showDocHandler()}>View</span>
            </Link>
          </div>
        </div>
      </div>
      {showDoc && (
        <CheckAuthenticityModal
          show={showDoc}
          encryptedDocuments={encryptedDocuments}
          onHide={() => setShowDocs(false)}
          paramid={paramId}
        />
      )}
    </>
  );
};

export default DetailPageContent;
