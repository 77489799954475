import React, { useState } from "react";
import logo from "../../../assets/images/logo.png";
import styles from "./SignInModal.module.scss";
import CommonModal from "../CommonModal/CommonModal";
import { Form } from "react-bootstrap";
import CommonBtn from "../CommonBtn/CommonBtn";
import { Link, redirect, useNavigate } from "react-router-dom";
import PasswordInput from "../passwordinput/PasswordInput";
import { apiCallPost } from "../../../features/Axios/Axios";
import APIURLS from "../../../features/ApiPath/ApiPath";
import { useFormik } from "formik";
import { loginFormValidations } from "../../../features/Validation/YupValidations";
import { useDispatch, useSelector } from "react-redux";
import { setUserLogin } from "../../../Redux/Reducers/user";

const SignInModal = ({
  show,
  onHide,
  className,
  setLogin,
  signUpTrigger,
  forPassClick,
}) => {
  const dispatch = useDispatch();
  const [signInLoader, setSignInLoader] = useState(false);
  const userInfo = useSelector((state) => state?.user?.userData);
  const navigator = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginFormValidations,
    onSubmit: async (values) => {
      setSignInLoader(true);
      try {
        const res = await apiCallPost(
          `${APIURLS.USER.SIGN_IN}`,
          values,
          {},
          "toastOn"
        );
        if (res) {
          localStorage.setItem("authorizationToken", res?.data?.accessToken);
          dispatch(setUserLogin(true));
          setLogin(false);
          navigator(`/${res?.data?.username}/favourites`, { replace: true });
          setSignInLoader(false);
        }
      } catch (error) {
        setSignInLoader(false);
      }
    },
  });

  return (
    <>
      <CommonModal
        show={show}
        onHide={onHide}
        className={`${className} ${styles.signin_modal}`}
        modalTitle={
          <>
            <img src={logo} alt="" />
          </>
        }
        subTitle="Sign In"
        centered
      >
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Email/Username</Form.Label>
            <Form.Control
              type="text"
              placeholder="abc@gmail.com"
              id={"email"}
              name={"email"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />

            {formik.touched.email && formik.errors.email && (
              <div className="error_field">
                <span>{formik.errors.email}</span>
              </div>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="mt-3">Password</Form.Label>
            <PasswordInput
              placeholder="**************"
              type="password"
              id="password"
              name="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
            />
            {formik.touched.password && formik.errors.password ? (
              <div className="error_field">
                <span>{formik.errors.password}</span>
              </div>
            ) : (
              ""
            )}
            <div className="text-end pt-2">
              <Link
                to="#"
                className="text-end forgetPass"
                onClick={forPassClick}
              >
                Forgot Password?
              </Link>
            </div>
          </Form.Group>
          <CommonBtn
            className="mt-3 w-100"
            title="CONTINUE"
            disabled={!formik.isValid}
            type="submit"
            loader={signInLoader}
          />
        </Form>
        <div className="signup_note">
          <p>
            Don’t have an account?{" "}
            <Link to="#" onClick={signUpTrigger}>
              Sign Up
            </Link>
          </p>
        </div>
      </CommonModal>
    </>
  );
};
export default SignInModal;
