import React, { useEffect, useState } from "react";
import styles from "./CollectionCategory.module.scss";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";

import { SortIcon, UpArrow } from "../../../assets/svgIcons/SVGicon";
import CommonSelectbox from "../../../components/ui/Dropdowns/CommonSelectbox";
import { DownArrow } from "../../../assets/svgIcons/SVGicon";
import CommonSearch from "../../../components/ui/CommonSearch/CommonSearch";
import CommonCard from "../../../components/ui/CommonCards/CommonCard/CommonCard";
import CommonBtn from "../../../components/ui/CommonBtn/CommonBtn";
import CommonTable from "../../../components/ui/CommonTable/CommonTable";
import APIURLS from "../../../features/ApiPath/ApiPath";
import { apiCallPost } from "../../../features/Axios/Axios";
import useDebounce from "../../../hooks/useDebounce";
import { isArrayEmpty } from "../../../features/Utility/Utility";
import NoResult from "../../../components/ui/NoResult/NoResult";

const CollectionCategory = () => {
  const [tab, setTab] = useState(false);
  const params = useParams();
  const [searchTerm, setSearchTerm] = useState("");
  const debounceTerm = useDebounce(searchTerm);
  const [sortFilter, setSortFilter] = useState("desc");
  const [collectionDetails, setCollectionDetails] = useState({});
  const [currLimit, setCurrLimit] = useState(8);
  const navigation = useNavigate();
  const getCollectionDetails = async () => {
    try {
      const { data } = await apiCallPost(
        `${APIURLS.COLLECTION.GET_COLLECTION_DETAILS}/${params.collectionId}`,
        { search: debounceTerm, sort: sortFilter, page: 1, limit: currLimit },
        {}
      );
      setCollectionDetails(data);
    } catch (error) {
      navigation("/marketplace/collections");
    }
  };
  useEffect(() => {
    getCollectionDetails();
  }, [debounceTerm, sortFilter, currLimit]);
  const openUp = () => {
    setTab(!tab);
  };
  const optionsTwo = [
    { menu: "Newset", to: "#", value: "desc" },
    { menu: "Oldest", to: "#", value: "asc" },
  ];

  const dat = [
    {
      lastTradedOn: "---",
      NoOfUniqueOwners: "---",
      qty: collectionDetails?.noOfNfts || 0,
      viewCount: collectionDetails?.viewCount || 0,
      favCount: collectionDetails?.favouriteCount || 0,
    },
  ];
  const { title, description, featuredImage, noOfNfts } = collectionDetails;
  return (
    <>
      <section className={styles.collection_category}>
        <Container>
          <Row>
            <Col>
              <div className={styles.collection_category_inner}>
                <div className={styles.collection_category_inner_data}>
                  <h4>{title}</h4>
                  <p>{description}</p>
                  <div className={styles.collection_category_inner_data_desc}>
                    <div>
                      <p>Total NFTs</p>
                      <h3>{noOfNfts}</h3>
                    </div>
                    <div>
                      <p>Floor Price</p>
                      <h3>$ {collectionDetails?.price}1,000</h3>
                    </div>
                    <div>
                      <p>NFTs sold</p>
                      <h3>---</h3>
                    </div>
                  </div>
                  {noOfNfts > 0 && (
                    <Link to="#" onClick={() => openUp()}>
                      {tab ? (
                        <>
                          <span>Hide</span>&nbsp;&nbsp;
                          <UpArrow />
                        </>
                      ) : (
                        <>
                          See More... <DownArrow />
                        </>
                      )}
                    </Link>
                  )}
                </div>
                <div className={styles.collection_category_inner_img}>
                  <img src={featuredImage} alt="" />
                </div>
              </div>
              {tab ? (
                <>
                  <CommonTable className="table-box mt-5">
                    <thead>
                      <tr>
                        <th>Last Traded on</th>
                        <th>No. of Unique Owners</th>
                        <th>Total Volume</th>
                        <th>Number of Views</th>
                        <th width="12rem">Favourites</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dat.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{"---"}</td>
                            <td>{"---"}</td>
                            <td>{item?.qty}</td>
                            <td>{item?.viewCount}</td>
                            <td>{item?.favCount}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </CommonTable>
                </>
              ) : null}
              <Row className="align-items-center my-5">
                <Col lg={6}>
                  <CommonSearch
                    searchvalue={searchTerm}
                    setSearchvalue={setSearchTerm}
                    className={styles.category_search}
                    placeholder="Search..."
                  />
                </Col>
                <Col lg={6}>
                  <div className={`text-md-end ${styles.activity_sec_act}`}>
                    <CommonSelectbox
                      icon={<SortIcon />}
                      setFilterValue={setSortFilter}
                      type={"filter"}
                      title="Newest"
                      options={optionsTwo}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                {collectionDetails?.nfts?.map((item, index) => (
                  <Col xs={12} sm={6} lg={3} key={item?.nftId}>
                    <div className={styles.collect_card}>
                      <CommonCard
                        img={item?.image}
                        title={item?.title}
                        nftId={item?.nftId}
                        to={`/marketplace/nft/${item?.nftId}`}
                        subtitle={title}
                        isFavourite={item?.isFavourite}
                        price={item?.price}
                        fractions={item?.quantity}
                        key={item?.nftId}
                        btnTitle="BUY"
                      />
                    </div>
                  </Col>
                ))}
              </Row>
              {isArrayEmpty(collectionDetails?.nfts) && <NoResult />}
              {collectionDetails?.noOfNfts >
                collectionDetails?.nfts?.length && (
                <div className="text-center mt-5">
                  <CommonBtn
                    onClick={() => setCurrLimit((prev) => prev + 8)}
                    title="VIEW MORE"
                    className="btn-bordered my-5"
                    // className={styles.view_more_btn}
                  />
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default CollectionCategory;
