import React, { useState } from "react"
import { Form } from "react-bootstrap"
import { CloseEyeIcon, OpenEyeIcon } from "../../../assets/svgIcons/SVGicon"
import styles from "./PasswordInput.module.scss"
const PasswordInput = ({
  placeholder,
  name,
  onChange,
  onBlur,
  value,
}) => {
  const [seePass, setSeePass] = useState(false)
  return (
    <>
      <div className={styles.pass_handle}>
        <Form.Control
          name={name}
          type={seePass ? "text" : "password"}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
        />
        <span
          className={styles.pass_handle_icon}
          onClick={() => setSeePass(!seePass)}>
          {seePass ? <OpenEyeIcon /> : <CloseEyeIcon />}
        </span>
      </div>
    </>
  )
}
export default PasswordInput
