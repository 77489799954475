import React, { useEffect, useState } from "react";
import styles from "./Ticket.module.scss";
import CommonBtn from "../../components/ui/CommonBtn/CommonBtn";
import { TimeFrameIcon } from "../../assets/svgIcons/SVGicon";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import APIURLS from "../../features/ApiPath/ApiPath";
import { apiCallGet, apiCallPost } from "../../features/Axios/Axios";
import { useFormik } from "formik";
import { SendTicketResponseValidation } from "../../features/Validation/YupValidations";
import { toasts } from "../../components/common/Toast/Toast";

const Ticket = () => {
  const params = useParams();
  const [ticketDetail, setTicketDetail] = useState({});
  const getTicketDetails = async () => {
    try {
      const res = await apiCallGet(
        `${APIURLS.SUPPORT.TICKET_DETAIL}/${params.supportId}`
      );
      if (res) {
        setTicketDetail(res?.data);
      }
    } catch (error) {
      toasts.error(error?.message);
    }
  };
  useEffect(() => {
    getTicketDetails();
  }, []);

  const tikButtonClass = (status) => {
    if (status?.toLowerCase() === "open") {
      return "btn-bordered-yellow";
    } else if (status === "resolved") {
      return "btn-bordered-green";
    } else {
      return "btn-bordered-red";
    }
  };

  const { ticketId, title, status, description, responses } = ticketDetail;
  const formik = useFormik({
    initialValues: {
      message: "",
      supportId: params.supportId,
    },
    validationSchema: SendTicketResponseValidation,
    onSubmit: async (values) => {
      try {
        const res = await apiCallPost(
          APIURLS.SUPPORT.SEND_RESPONSE,
          values,
          {},
          "toastOn"
        );
        if (res) {
          formik.resetForm({ values: "" });
          getTicketDetails();
        }
      } catch (error) {
        console.log(error);
      }
    },
  });
  return (
    <>
      <section className={styles.admin_sec}>
        <Container>
          <Row>
            <Col>
              <h2>{ticketId} Ticket</h2>
              <div className={styles.admin_sec_block}>
                <div className={styles.admin_sec_block_detail}>
                  <h6>{title}</h6>
                  <CommonBtn
                    className={tikButtonClass(status)}
                    title={status}
                  />
                </div>
                <p>{description}</p>
              </div>
              {status === "open" && responses?.length <= 0 ? (
                <div className={styles.admin_sec_period1}>
                  <TimeFrameIcon />
                  <h6>Waiting for admin support!</h6>
                  <p>
                    There are many variations of passages of Lorem Ipsum
                    available, but the majority have suffered alteration in some
                    form, by injected humour
                  </p>
                </div>
              ) : (status === "open" || status === "resolved") &&
                responses?.length > 0 ? (
                <>
                  {responses?.map((item) => (
                    <div className={styles.admin_sec_period}>
                      <h6>Respond</h6>
                      <p className={`mb-0 ${styles.admin_sec_period_reply}`}>{item.repliedBy}</p>
                      {/* <p>Thank you for contacting us!</p> */}
                      <p>{item.reply}</p>
                    </div>
                  ))}
                  <div className={styles.admin_sec_period}>
                    <h6>Respond</h6>
                    <Form>
                      <Form.Control
                        as="textarea"
                        type="text"
                        placeholder="Insert Respond"
                        name="message"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.message}
                        className={styles.admin_sec_user_response}
                      />
                      {formik.touched.message && formik.errors.message && (
                        <div
                          className="error_field"
                          style={{ marginTop: "-2rem", marginBottom: "2rem" }}
                        >
                          <span>{formik.errors.message}</span>
                        </div>
                      )}
                    </Form>
                    <CommonBtn
                      type="submit"
                      loader={formik.isSubmitting}
                      className={styles.admin_sec_send_response}
                      onClick={formik.handleSubmit}
                      disabled={!formik.isValid || formik.isSubmitting}
                      title={"Send Response"}
                    />
                      </div>
                </>
              ) : status === "closed" && responses?.length > 0 ? (
                responses?.map((item) => (
                  <div className={styles.admin_sec_period}>
                    <h6>Respond</h6>
                    <p className="mb-0">{item.repliedBy}</p>
                    {/* <p>Thank you for contacting us!</p> */}
                    <p>{item.reply}</p>
                  </div>
                ))
              ) : (
                ""
              )}
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Ticket;
