import React, { useState, useEffect } from "react";
import styles from "./ProfileHistory.module.scss";
import CommonTable from "../../../components/ui/CommonTable/CommonTable";
import SearchAndFilter from "../commonComponents/SearchAndFilter/SearchAndFilter";
import { SendIcon } from "../../../assets/svgIcons/SVGicon";
import PaginationComponent from "../../../components/common/pagination/PaginationComponent";
import APIURLS from "../../../features/ApiPath/ApiPath";
import {
  formatDate,
  isArrayEmpty,
  limitPerPage,
  openEtherScan,
  trimText,
} from "../../../features/Utility/Utility";
import { apiCallPost } from "../../../features/Axios/Axios";
import useDebounce from "../../../hooks/useDebounce";
import {
  FILTER_PROFILE_FRACTION_SORT,
  TRANSACTION_OPTIONS,
} from "../../../features/Constant/Constant";

const ProfileHistory = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchvalue, setSearchvalue] = useState("");
  const [loader, setLoader] = useState(false);
  const [histtoryList, setHistoryList] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const [transactionValue, setTransactionValue] = useState("");
  const debounce = useDebounce(searchvalue, 500);

  console.log("historysearch", filterValue);

  const getHistory = async (payload) => {
    setLoader(true);
    try {
      const { data } = await apiCallPost(
        `${APIURLS.NFT.GET_HISTORY}/${currentPage}/${limitPerPage}`,
        {
          search: debounce,
          filter: transactionValue,
          order: filterValue,
        }
      );
      setLoader(false);
      setHistoryList(data);
    } catch (error) {
      setLoader(false);
    }
  };

  useEffect(() => {
    getHistory();
  }, [debounce, currentPage, filterValue, transactionValue]);

  return (
    <>
      <div className={styles.profile_history}>
        <SearchAndFilter
          search={true}
          searchvalue={searchvalue}
          setSearchvalue={setSearchvalue}
          setCurrentPage={setCurrentPage}
          newestOptions={FILTER_PROFILE_FRACTION_SORT}
          setNewestFilterValue={setFilterValue}
          type={"filter"}
          newest
          profileFilters={TRANSACTION_OPTIONS}
          setStatusFilter={setTransactionValue}
        />
        <CommonTable
          isDataEmpty={isArrayEmpty(histtoryList?.count)}
          className="table-box mt-5"
        >
          <thead>
            <tr>
              <th>Transaction Type</th>
              <th>NFTs</th>
              <th>Price (XAR)</th>
              <th>Fractions</th>
              <th>To </th>
              <th>Txn Hash</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {histtoryList &&
              histtoryList?.rows?.map((item, index) => {
                const transactionText = (
                  <p>
                    {item?.type} <br />
                    <small
                      style={{
                        color: "#d59111",
                        display: "block",
                        marginTop: -5,
                        fontSize: 14,
                      }}
                    >
                      (in-process)
                    </small>
                  </p>
                );
                return (
                  <>
                    <tr key={index}>
                      <td>
                        {item?.type === "Transaction"
                          ? transactionText
                          : item?.type}{" "}
                      </td>
                      <td>{item?.title}</td>
                      <td>{Number(item?.price).toFixed(4)}</td>
                      <td>{item?.quantity}</td>
                      <td>{trimText(item?.toAddress)}</td>
                      <td>
                        <a
                          style={{ cursor: "pointer" }}
                          title={item?.txnHash}
                          onClick={() => openEtherScan(item?.txnHash)}
                          target="_blank"
                        >
                          {trimText(item?.txnHash)}
                        </a>
                      </td>
                      <td>
                        {
                          <>
                            <div className="d-flex align-items-center gap-3">
                              {formatDate(item?.createdAt)} <SendIcon />
                            </div>
                          </>
                        }
                      </td>
                    </tr>
                  </>
                );
              })}
          </tbody>
        </CommonTable>
        <PaginationComponent
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          requestPerPage={limitPerPage}
          totalCount={histtoryList?.count}
        />
      </div>
    </>
  );
};
export default ProfileHistory;
