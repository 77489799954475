import toast from "react-hot-toast";

class toster {
  success(massage) {
    toast.success(massage, {
      style: {
        padding: "6px 12px",
        fontSize: "1.5rem",
        textShadow: "0px 3px 7px rgba(0,0,0,0.1)",
        fontWeight: "600",
        color: "#666",
        wordBreak: "break-word",
      },
    });
  }
  warn(massage) {
    toast.error(massage, {
      icon: "⚠️",
      style: {
        padding: "6px 12px",
        fontSize: "1.5rem",
        textShadow: "0px 3px 7px rgba(0,0,0,0.1)",
        fontWeight: "600",
        color: "#666",
        wordBreak: "break-word",
      },
    });
  }
  error(massage) {
    toast.error(massage, {
      style: {
        padding: "6px 12px",
        fontSize: "1.5rem",
        textShadow: "0px 3px 7px rgba(0,0,0,0.1)",
        fontWeight: "600",
        color: "#666",
        wordBreak: "break-word",
      },
      iconTheme: {
        primary: "#900",
      },
    });
  }
}

export const toasts = new toster();
