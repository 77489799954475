import React, { useState } from "react";
import CommonTable from "../../../components/ui/CommonTable/CommonTable";
import CommonBtn from "../../../components/ui/CommonBtn/CommonBtn";
import styles from "./DetailPage.module.scss";
import { Col, Row } from "react-bootstrap";
import moment from "moment";
import CheckOutModal from "../../../components/ui/CheckoutModal/CheckOutModal";
import { isArrayEmpty, trimText } from "../../../features/Utility/Utility";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { toasts } from "../../../components/common/Toast/Toast";
import WalletModal from "../../../components/ui/WalletModal/WalletModal";

const ListingTable = ({ listingData, nftDetails, getNftListing }) => {
  const [checkoutModal, setCheckoutModal] = useState(false);
  const [walletModalShow, setWalletModalShow] = useState(false);
  const [checkoutListingData, setCheckoutListingData] = useState({});
  const connectedWallet = useSelector((state) => state?.wallet?.walletAddress);
  const userConnectedWallet = useSelector(
    (state) => state?.user?.userData?.userWallets
  );
  const handleBuyFunction = (item) => {
    if (connectedWallet === userConnectedWallet) {
      setCheckoutListingData(item);
      setCheckoutModal(true);
      return;
    }
    setWalletModalShow(true);
    // toasts.warn("Please connect your wallet")
  };
  return (
    <>
      <div className={`${styles.history_head} pb-5`}>
        <Row className="align-items-center">
          <Col className="text-center text-sm-start">
            <h4>Listings</h4>
          </Col>
        </Row>
        <hr />
        <CommonTable
          isDataEmpty={isArrayEmpty(listingData?.count)}
          className="table-box">
          <thead>
            <tr>
              <th>Price</th>
              <th>Fractions</th>
              <th>From</th>
              <th>Date & Time</th>
              <th width="12rem">Action</th>
            </tr>
          </thead>
          <tbody>
            {listingData?.rows?.map((item) => (
              <tr key={item?.id}>
                <td>$ {item?.price}</td>
                <td>{item?.quantityLeft}</td>
                <td>{trimText(item?.wallet) || "No wallet"}</td>
                <td>
                  {moment(item?.updatedAt).format("MMMM DD, YYYY | hh:mmA")}
                </td>
                <td>
                  {item?.wallet === userConnectedWallet ? (
                    ""
                  ) : (
                    <CommonBtn
                      title={item?.quantityLeft === 0 ? "SOLD" : "BUY"}
                      disabled={item?.quantityLeft === 0}
                      className="btn-bordered btn-small my-2"
                      onClick={() => handleBuyFunction(item)}
                    />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </CommonTable>
      </div>
      {checkoutModal && (
        <CheckOutModal
          show={checkoutModal}
          onHide={() => setCheckoutModal(false)}
          setCheckoutModal={setCheckoutModal}
          nftDetails={nftDetails}
          listingData={checkoutListingData}
          getNftListing={getNftListing}
        />
      )}
      {walletModalShow && (
        <WalletModal setWalletModalShow={setWalletModalShow} />
      )}
    </>
  );
};

export default ListingTable;
