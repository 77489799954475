import React from "react";
import { Modal } from "react-bootstrap";
import "./CommonModal.scss";

const CommonModal = ({
  children,
  centered,
  show,
  onHide,
  modalTitle,
  subTitle,
  className,
  backdrop,
  keyboard,
  closeBtn,
}) => {
  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        className={`commonModal ${className}`}
        centered={centered}
        backdrop={backdrop}
        keyboard={keyboard}
      >
        <Modal.Header closeButton={!closeBtn}>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {subTitle && <p className="commonModal-subtitle">{subTitle}</p>}
          {children}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CommonModal;
