import React, { useState } from "react";
import styles from "./DetailPage.module.scss";

import Slider from "react-slick";
import CommonModal from "../../../components/ui/CommonModal/CommonModal";
import { VIDEO_EXT_TYPE } from "../../../features/Constant/Constant";

const SliderSec = ({ gallery, image, fileType }) => {
  const [detail, setDetail] = useState(false);
  const closeDetail = () => setDetail(false);
  const handleDetail = () => setDetail(true);
  const [nav2, setNav2] = useState();
  const [nav1, setNav1] = useState();

  return (
    <>
      <div className={styles.detailpage_main_slider_outer}>
        {/* <div className={styles.detailpage_img_imagebox}> */}
        <Slider
          asNavFor={nav2}
          ref={(slider) => setNav1(slider)}
          dots={false}
          infinite={true}
          speed={500}
          slidesToShow={1}
          slidesToScroll={1}
          pauseOnHover={false}
          pauseOnFocus={false}
          arrows={false}
          focusOnSelect={true}
          className={styles.detailpage_main_slider}
        >
          {image &&
            image?.map((item, index) => {
              return (
                <div key={index}>
                  <div
                    className={styles.mainSlider}
                    key={index}
                    onClick={() => {
                      handleDetail();
                    }}
                  >
                    {VIDEO_EXT_TYPE.includes(item?.split(".").pop()) ? (
                      <video
                        autoplay
                        playsinline
                        style={{ pointerEvents: "none" }}
                        src={item}
                        autoPlay
                      ></video>
                    ) : (
                      <img className="img-nft" src={item} alt="" />
                    )}
                  </div>
                </div>
              );
            })}
        </Slider>
        {/* </div> */}
      </div>
      <div className={styles.detailpage_info}>
        <Slider
          asNavFor={nav1}
          ref={(sliderNav) => setNav2(sliderNav)}
          dots={false}
          infinite={true}
          speed={500}
          slidesToShow={4}
          slidesToScroll={1}
          pauseOnHover={false}
          pauseOnFocus={false}
          focusOnSelect={true}
          arrows={true}
        >
          {gallery?.map((item, index) => {
            return (
              <div key={index} onClick={handleDetail}>
                <div className={styles.detail_card} key={index}>
                  {fileType &&
                  VIDEO_EXT_TYPE.includes(item.image?.split(".").pop()) ? (
                    <video
                      autoplay
                      playsinline
                      style={{ pointerEvents: "none" }}
                      src={item.image}
                      autoPlay
                    ></video>
                  ) : (
                    <img className="img-nft" src={item.image} alt="" />
                  )}
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
      <CommonModal
        show={detail}
        className={styles.popup_slider}
        onHide={closeDetail}
      >
        <Slider
          asNavFor={nav2}
          ref={(slider) => setNav1(slider)}
          dots={true}
          infinite={true}
          speed={500}
          slidesToShow={1}
          slidesToScroll={1}
          pauseOnHover={false}
          pauseOnFocus={false}
          arrows={true}
          focusOnSelect={true}
        >
          {gallery?.map((item, index) => {
            return (
              <div key={index}>
                <div
                  className="mainSliderPopup"
                  key={index}
                  onClick={() => {
                    handleDetail();
                  }}
                >
                  {fileType &&
                  VIDEO_EXT_TYPE.includes(item.image?.split(".").pop()) ? (
                    <video
                      autoplay
                      playsinline
                      style={{ pointerEvents: "none" }}
                      src={item.image}
                      autoPlay
                    ></video>
                  ) : (
                    <img className="img-nft" src={item.image} alt="" />
                  )}
                </div>
              </div>
            );
          })}
        </Slider>
      </CommonModal>
    </>
  );
};

export default SliderSec;
