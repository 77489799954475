import React, { createContext, useContext, useState } from "react";
const globalSearchContext = createContext();
const GlobalSearchContextProvider = ({ children }) => {
  const [globalSearchTerm, setGlobalSearchTerm] = useState("");
  return (
    <globalSearchContext.Provider
      value={{ globalSearchTerm, setGlobalSearchTerm }}
    >
      {children}
    </globalSearchContext.Provider>
  );
};

export default GlobalSearchContextProvider;
export const useGlobalSearchContext = () => useContext(globalSearchContext);
