import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCompleteKYCModal, setKycStatus } from "../Redux/Reducers/user";
import { KycOnComplete } from "../features/Utility/Utility";
import { useNavigate } from "react-router-dom";

const useCheckInKYC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const username = useSelector((state) => state?.user?.userData?.username);
  useEffect(() => {
    window.onCheckinLoad = (checkin) => {
      console.log("@ SDK CHECKIN SDK LOADED@");
      window?.checkin?.dataFlow?.setKnownData({
        user: { firstName: "", lastName: "" },
      });

      window?.checkin?.events?.setOnCloseModule(() => {
        console.log(
          "========================== USER CLOSE THE CHECKIN MODAL ============================"
        );
      });

      window?.checkin?.dataFlow?.setOnComplete(async ({ data }) => {
        console.log(
          "========================== setOnComplete ============================"
        );
        const payLoad = {
          resultUrl: data?.ocrData?.resultUrl,
          sessionId: data?.ocrData?.id,
        };
        const userKycStatus = data?.ocrData?.data?.status;
        const ApplicationStatus = data?.ocrData?.status;
        console.log(
          `======================= setOnComplete ============================`
        );
        console.log(
          `======================= ${ApplicationStatus} APLLICATION STATUS ${userKycStatus} ============================`
        );

        try {
          const res = await KycOnComplete(payLoad);
          dispatch(setCompleteKYCModal(false));
          if (res) {
            dispatch(setKycStatus(userKycStatus));
            window.location.href = `/${username}/kyc`;
            // navigate(`/${username}/kyc`);
          }
          console.log(
            `======================= KYC COMPLETE WITH STATUS${userKycStatus} ============================`
          );
        } catch (error) {
          console.log(
            "==========================ERROR KYC API ============================",
            error
          );
        }
      });
    };

    window.onCheckinLoad();
  }, []);
};

export default useCheckInKYC;
