import React, { useEffect, useState } from "react";
import CommonTable from "../../../components/ui/CommonTable/CommonTable";
import NotificationFilter from "../NotificationFilter/NotificationFilter";
import styles from "../Notifications.module.scss";
import Thumb from "../../../assets/images/3d-1.png";
import {
  MarkReadNoftification,
  deleteNoftification,
} from "../../../features/serives/services";
import {
  NOFTIFICATION_TYPE,
  VIDEO_EXT_TYPE,
} from "../../../features/Constant/Constant";
import { isArrayEmpty, timeFromNow } from "../../../features/Utility/Utility";
import useDebounce from "../../../hooks/useDebounce";
import PaginationComponent from "../../../components/common/pagination/PaginationComponent";
import {
  ReadUnreadMessages,
  deleteNotification,
  getAllNotifications,
} from "../../../Redux/Reducers/notifications";
import { useDispatch, useSelector } from "react-redux";

const limit = 10;
const Unread = () => {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const debounceTerm = useDebounce(searchTerm);

  const { unReadNotifications } = useSelector((state) => state?.notification);
  const [currPage, setCurrPage] = useState(1);
  const readNotification = (notificationId) => {
    MarkReadNoftification(notificationId);
    dispatch(
      ReadUnreadMessages({
        id: notificationId,
        type: NOFTIFICATION_TYPE.UNREAD,
        count: unReadNotifications.count,
        totalcount: unReadNotifications.totalcount,
      })
    );
  };

  useEffect(() => {
    dispatch(
      getAllNotifications({
        ReadOrUnReadType: NOFTIFICATION_TYPE.UNREAD,
        searchTerm: debounceTerm,
        page: currPage,
      })
    );
  }, [debounceTerm, currPage]);

  return (
    <>
      <div className={styles.notifications_unread}>
        <NotificationFilter
          setCurrPage={setCurrPage}
          searchvalue={searchTerm}
          markbtn={true}
          search={true}
          deletebtn={true}
          setSearch={setSearchTerm}
          NotificationType={NOFTIFICATION_TYPE.UNREAD}
        />
        <CommonTable
          isDataEmpty={isArrayEmpty(unReadNotifications?.rows)}
          className={`mt-5 ${styles.notifications_table}`}
        >
          <thead>
            <tr>
              <th>Details</th>
              <th>Time</th>
              <th width="170px">Actions</th>
            </tr>
          </thead>
          <tbody>
            {unReadNotifications?.rows?.map((item, index) => {
              return (
                <tr
                  onClick={() => readNotification(item?.notificationId)}
                  key={index}
                >
                  <td
                    onClick={() =>
                      window.location.replace(item.profileRedirectLink)
                    }
                  >
                    {item &&
                    VIDEO_EXT_TYPE.includes(item?.profile?.split(".").pop()) ? (
                      <video
                        autoplay
                        playsinline
                        style={{ pointerEvents: "none" }}
                      >
                        <source src={item?.profile} type="video/mp4"></source>
                      </video>
                    ) : (
                      <img
                        className={styles.notifications_table_thumb}
                        src={item?.profile || Thumb}
                        alt="img"
                      />
                    )}

                    <span
                      dangerouslySetInnerHTML={{ __html: item?.message }}
                    ></span>
                  </td>
                  <td>{timeFromNow(item?.updatedAt)}</td>
                  <td>
                    <button
                      onClick={() => readNotification(item?.notificationId)}
                      className={styles.notifications_table_act_mark}
                    >
                      Mark
                    </button>
                    <span className={styles.notifications_table_act_divider}>
                      |
                    </span>
                    <button
                      onClick={() => {
                        deleteNoftification(item?.notificationId);
                        dispatch(
                          deleteNotification({
                            id: item?.notificationId,
                            type: NOFTIFICATION_TYPE.UNREAD,
                          })
                        );
                      }}
                      className={styles.notifications_table_act_delete}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </CommonTable>
      </div>
      <PaginationComponent
        currentPage={currPage}
        requestPerPage={limit}
        setCurrentPage={setCurrPage}
        totalCount={unReadNotifications?.count}
      />
    </>
  );
};

export default Unread;
