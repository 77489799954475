import React from "react";
import { Container } from "react-bootstrap";
import styles from "./Banner.module.scss";
import videoBanner from "../../../assets/hero-vdo.webm";

const Banner = () => {
  return (
    <>
      <section className={styles.banner}>
        <Container>
          <div className={styles.banner_inner}>
            <div className={styles.banner_right}>
              <div className={styles.banner_right_vdo}>
                <video
                  width="100%"
                  height="100%"
                  autoPlay
                  playsInline
                  muted
                  loop>
                  <source src={videoBanner} type="video/mp4" />
                </video>
              </div>
            </div>
            <div className={styles.banner_left}>
              <h1>
                Building An <span>Open Digital</span> Economy
              </h1>
              <p>
                The placeholder text, beginning with the line Lorem ipsum dolor
                sit amet, consectetur adipiscing placeholder
              </p>
              <ul>
                <li>
                  <span>Founded</span>
                  <h3>2017</h3>
                </li>
                <li>
                  <span>Employees</span>
                  <h3>200+</h3>
                </li>
                <li>
                  <span>Collections</span>
                  <h3>2M</h3>
                </li>
              </ul>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Banner;
