import React from "react";
import styles from "./DetailPage.module.scss";
const PropertyDetail = ({ additionalField, showProperties }) => {
  return (
    <>
      <div
        className={styles.detailpage_property}
        style={{
          height: showProperties ? "auto" : "0px",
          padding: showProperties ? "3rem 3rem 3rem 3rem" : "0rem",
          visibility: showProperties ? "visible" : "hidden",
          marginTop: showProperties ? "3rem" : "0rem",
        }}
      >
        <ul>
          {additionalField.map((item, index) => {
            return (
              <li key={index}>
                <p style={{ fontSize: showProperties ? "1.4rem" : "0rem" }}>
                  {item.name}
                </p>
                <h4 style={{ fontSize: showProperties ? "1.8rem" : "0rem" }}>
                  {item.value}
                </h4>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default PropertyDetail;
