import React, { useEffect, useState } from "react";
import CommonBtn from "../../../components/ui/CommonBtn/CommonBtn";
import CommonTable from "../../../components/ui/CommonTable/CommonTable";
import SearchAndFilter from "../commonComponents/SearchAndFilter/SearchAndFilter";
import styles from "./MyListings.module.scss";
import AlertModal from "../../../components/ui/AlertModal/AlertModal";
import img from "../../../assets/images/logo-large.png";
import { apiCallPost } from "../../../features/Axios/Axios";
import APIURLS from "../../../features/ApiPath/ApiPath";
import useDebounce from "../../../hooks/useDebounce";
import { formatDate, isArrayEmpty } from "../../../features/Utility/Utility";
import { FILTER_LIST_ALL } from "../../../features/Constant/Constant";
import { Navigate, useNavigate } from "react-router-dom";

const MyListings = () => {
  const navigate = useNavigate();
  const [alertModal, setAlertModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [allList, setAllList] = useState([]);
  const [cancelButtonData, setCancelButtonData] = useState();
  const [searchvalue, setSearchvalue] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const debounce = useDebounce(searchvalue, 500);

  const getAllListing = async () => {
    setLoader(true);
    try {
      const { data } = await apiCallPost(
        `${APIURLS.NFT.GET_NFT_ALL_LISTING}/1/10`,
        { search: debounce, order: filterValue }
      );
      setAllList(data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };

  const cancelNFTFromList = async (payload) => {
    setAlertModal(false);
    if (alertModal) {
      setLoader(true);
      try {
        await apiCallPost(
          `${APIURLS.NFT.DELIST_NFT}`,
          {
            nftId: payload.nftId,
            nftListId: payload.nftListId,
          },
          {},
          "toastOn"
        );
        setLoader(false);
        getAllListing();
      } catch (error) {
        setLoader(false);
      }
    }
  };

  const updateDelistNftHandler = async (payload) => {
    try {
      await apiCallPost(
        APIURLS.NFT.UPDATE_DELIST_NFT,
        {
          status: "NOT_SEND",
          nftId: payload?.nftId,
          nftListId: payload?.nftListId,
        },
        {},
        "toastOn"
      );
      getAllListing();
    } catch (error) {
      console.log("Something went wrong while updating NFT Delist");
    }
  };

  useEffect(() => {
    getAllListing();
  }, [filterValue, debounce]);

  return (
    <>
      <div className={styles.mylistings}>
        <SearchAndFilter
          searchvalue={searchvalue}
          setSearchvalue={setSearchvalue}
          search={true}
          newest
          type={"filter"}
          newestOptions={FILTER_LIST_ALL}
          setNewestFilterValue={setFilterValue}
        />
        <CommonTable
          isLoading={loader}
          isDataEmpty={isArrayEmpty(allList?.count)}
          className="table-box mt-5"
        >
          <thead>
            <tr>
              <th>NFTs</th>
              <th>Price (XAR)</th>
              <th>Fractions</th>
              <th>Date</th>
              <th width="12rem">Actions</th>
            </tr>
          </thead>
          <tbody>
            {allList && (
              <>
                {allList.rows?.map((item, index) => (
                  <tr key={index}>
                    <td
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        navigate(`/marketplace/nft/${item?.nftId}`)
                      }
                    >
                      {item.title}
                    </td>
                    <td>{item.price}</td>
                    <td>{item.quantity}</td>
                    <td>{formatDate(item.createdAt)}</td>
                    <td>
                      {item.nftDelistStatus === "PENDING" && (
                        // <span style={{ color: "#EBA919" }}>
                        //   {/* {item.nftDelistStatus} */}
                        //   Cancel
                        // </span>
                        <CommonBtn
                          title="Cancel"
                          onClick={() => updateDelistNftHandler(item)}
                        />
                      )}
                      {/* 
                      {item.nftDelistStatus === "NOT_SEND" && (
                        <CommonBtn
                          title="CANCEL"
                          className="btn-bordered btn-small my-2"
                          onClick={() => {
                            setAlertModal(true);
                            setCancelButtonData({
                              nftId: item.nftId,
                              nftListId: item.nftListId,
                            });
                          }}
                        />
                      )} */}
                      {item.nftDelistStatus === "ACCEPTED" && (
                        <CommonBtn
                          title="CANCEL"
                          className="btn-bordered btn-small my-2"
                          onClick={() => updateDelistNftHandler(item)}
                        />
                      )}
                      {(item.nftDelistStatus === "REJECTED" ||
                        item.nftDelistStatus === "NOT_SEND") && (
                        <span style={{ color: "#f00" }}>
                          {/* {item.nftDelistStatus} */}
                          <CommonBtn
                            title="Delist"
                            className="btn-bordered btn-small my-2"
                            onClick={() => {
                              setAlertModal(true);
                              setCancelButtonData({
                                nftId: item.nftId,
                                nftListId: item.nftListId,
                              });
                            }}
                          />
                        </span>
                      )}
                    </td>
                  </tr>
                ))}
              </>
            )}
          </tbody>
        </CommonTable>
      </div>
      {alertModal && (
        <AlertModal
          img={img}
          title={
            <>
              <p>
                Are You Sure <br /> You Want To Cancel The Listing? <br />
                <small
                  style={{ fontSize: 14, color: "#d59111", display: "block" }}
                >
                  Request will be canceled after admin approval !
                </small>
              </p>
            </>
          }
          btnAction={() => cancelNFTFromList(cancelButtonData)}
          btnText="CONFIRM"
          show={alertModal}
          onHide={() => setAlertModal(false)}
        />
      )}
    </>
  );
};

export default MyListings;
