import React from "react";
import styles from "./DetailPage.module.scss";

const TokenDetails = ({ contractId, tokeId }) => {
  return (
    <>
      <div className={styles.detailpage_address}>
        <div>
          <p>Contract Address</p>
          <p>{contractId}</p>
        </div>
        <div>
          <p>Token ID</p>
          <p>{tokeId}</p>
        </div>
        <div>
          <p>Token Standard</p>
          <p>ERC-1155</p>
        </div>
        <div>
          <p>Blockchain</p>
          <p>Ethereum</p>
        </div>
        <div></div>
      </div>
    </>
  );
};

export default TokenDetails;
