import React from "react";
import { useState } from "react";
import { NavLink } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { CheckIcon } from "../../../assets/svgIcons/SVGicon";
import "./Dropdowns.scss";
const CommonSelectbox = ({
  className,
  icon,
  title,
  options,
  setCurrentPage,
  setFilterValue,
  setCategoryId,
  type,
  setTicketStatus,
  setStatusFilter,
  defaultValue,
}) => {
  const [value, setValue] = useState(defaultValue || title);
  const [active, setActive] = useState("");

  const activeHandle = (index) => {
    setActive(index);
  };
  // useEffect(() => {
  //   if (defaultState) {
  //     activeHandle(+defaultState);
  //   }
  // }, [defaultState]);

  return (
    <>
      <div className={`common_dropdown selectbox ${className}`}>
        <Dropdown>
          <Dropdown.Toggle>
            <span className="common_dropdown_icon">{icon}</span>
            <span className="common_dropdown_title">{value}</span>
          </Dropdown.Toggle>
          <Dropdown.Menu renderOnMount={true}>
            {type === "category" && (
              <NavLink
                className="dropdown-item"
                href="#"
                onClick={() => {
                  setValue(title);
                  activeHandle("all");

                  type === "category" && setCategoryId("all");
                }}
              >
                All
                {active === "all" ? (
                  <>
                    <span className="checkicon">
                      <CheckIcon />
                    </span>
                  </>
                ) : null}
              </NavLink>
            )}
            {options?.map((item, index) => (
              <NavLink
                key={index}
                className="dropdown-item"
                href={item.to}
                onClick={() => {
                  setValue(item?.menu || item?.name);
                  activeHandle(index);

                  type === "filter" && setFilterValue(item?.value);
                  if (setCurrentPage) setCurrentPage(1);

                  type === "status" && setStatusFilter(item?.value);

                  type === "category" && setCategoryId(item?.id);

                  type === "tickets" && setTicketStatus(item?.value);
                }}
              >
                {item.menu || item?.name}
                {active === index ? (
                  <>
                    <span className="checkicon">
                      <CheckIcon />
                    </span>
                  </>
                ) : null}
              </NavLink>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  );
};
export default CommonSelectbox;
