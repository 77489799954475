import React from "react";
import { NavLink } from "react-router-dom";
import {
  DividendIcon,
  FavouriteIcon,
  FractionalizeIcon,
  HistoryIcon,
  KycProfileIcon,
  MyItemsIcon,
  MyListingIcon,
} from "../../../../assets/svgIcons/SVGicon";
import styles from "./ProfileNav.module.scss";
import { useSelector } from "react-redux";

const ProfileNav = () => {
  const auth = useSelector((state) => state?.user?.userLogin);
  const userInfo = useSelector((state) => state?.user?.userData);

  return (
    <>
      <ul className={styles.profile_nav}>
        <li>
          <NavLink to="favourites">
            <span className={styles.profile_nav_icon}>
              <FavouriteIcon />
            </span>
            <span className={styles.profile_nav_title}>Favourites</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="my-items">
            <span className={styles.profile_nav_icon}>
              <MyItemsIcon />
            </span>
            <span className={styles.profile_nav_title}>My Items</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="my-listings">
            <span className={styles.profile_nav_icon}>
              <MyListingIcon />
            </span>
            <span className={styles.profile_nav_title}>My Listings</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="history">
            <span className={styles.profile_nav_icon}>
              <HistoryIcon />
            </span>
            <span className={styles.profile_nav_title}>History</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="kyc">
            <span className={styles.profile_nav_icon}>
              <KycProfileIcon />
            </span>
            <span className={styles.profile_nav_title}>KYC</span>
          </NavLink>
        </li>
        <li>
            <NavLink to="roi">
            <span className={styles.profile_nav_icon}>
              <DividendIcon />
            </span>
            <span className={styles.profile_nav_title}>ROI</span>
          </NavLink>
        </li>
        {auth && userInfo?.isKycDone &&
          userInfo?.userKyc?.kycStatus === "APPROVED" && 
        <li>
          <NavLink to="fractionalize">
            <span className={styles.profile_nav_icon}>
              <FractionalizeIcon />
            </span>
            <span className={styles.profile_nav_title}>Fractionalize</span>
          </NavLink>
        </li>
        } 
{/*         
        <li>
          <NavLink to="fractionalize">
            <span className={styles.profile_nav_icon}>
              <FractionalizeIcon />
            </span>
            <span className={styles.profile_nav_title}>Fractionalize</span>
          </NavLink>
        </li> */}
      </ul>
    </>
  );
};
export default ProfileNav;
