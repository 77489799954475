import React from "react";
import styles from "./SearchResults.module.scss";
import { NavLink } from "react-router-dom";

const SearchHeader = () => {
  return (
    <div className={styles.search_result_sec_list}>
      <ul className="d-flex mt-5">
        <li>
          <NavLink to="/search/collections" className="h4 px-5  pb-3  mb-0">
            Collections
          </NavLink>
        </li>
        <li>
          <NavLink to="/search/nft" className="h4 px-5 pb-3 mb-0">
            NFTs
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default SearchHeader;
