import React from "react";
import styles from "./ContactUs.module.scss";
import { Col, Container, Form, Row } from "react-bootstrap";
import CommonBtn from "../../components/ui/CommonBtn/CommonBtn";
import { MailIcon } from "../../assets/svgIcons/SVGicon";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { contactUsValidation } from "../../features/Validation/YupValidations";
import APIURLS from "../../features/ApiPath/ApiPath";
import { apiCallPost } from "../../features/Axios/Axios";
import { toasts } from "../../components/common/Toast/Toast";

const ContactUs = () => {
  const formik = useFormik({
    initialValues: { username: "", email: "", message: "" },
    validationSchema: contactUsValidation,
    onSubmit: async (values) => {
      try {
        const res = await apiCallPost(
          `${APIURLS.USER.CONTACT_US}`,
          values,
          {},
        );
        if (res) {
          toasts.success(res.message)
          formik.resetForm({values: ''})
        }
      } catch (error) {
        toasts.error(error?.message)
      }
    },
  });
  return (
    <>
      <section className={styles.contact_sec}>
        <Container>
          <Row>
            <Col sm={12}>
              <div className={styles.contact_sec_head}>
                <h2>Contact Us</h2>
                <p>Lorem ipsum, or lipsum as it is sometimes known</p>
                <div className={styles.hl} />
              </div>
            </Col>
            <Col lg={12} className="mb-5">
              <div className={styles.contact_sec_left}>
                <div className={styles.contact_sec_left_data_img}>
                  <MailIcon />
                </div>
                <div className={styles.contact_sec_left_data}>
                  <div className={styles.contact_sec_left_data_info}>
                    <h4>Email Address</h4>
                    <Link to="mailto:info@gmail.com" target="_black">
                      info@gmail.com
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
            <Row className="mt-5 mx-0 px-0">
              <Col sm={12} md={6} lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control
                    id={"username"}
                    name={"username"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.username}
                    type="text"
                    placeholder="Enter your name"
                  />
                </Form.Group>
                {formik.touched.username && formik.errors.username && (
                  <div className="error_field">
                    <span>{formik.errors.username}</span>
                  </div>
                )}
              </Col>
              <Col sm={12} md={6} lg={6}>
                <div className={styles.contact_sec_right}>
                  <Form>
                    <Form.Group className="mb-3">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="adsc@gmail.com"
                        id={"email"}
                        name={"email"}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                      />
                    </Form.Group>
                    {formik.touched.email && formik.errors.email && (
                      <div className="error_field">
                        <span>{formik.errors.email}</span>
                      </div>
                    )}
                  </Form>
                </div>
              </Col>
              <Col lg={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Message</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={4}
                    type="text"
                    placeholder="Write your words here..."
                    id={"message"}
                    name={"message"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.message}
                  />
                </Form.Group>
                {formik.touched.message && formik.errors.message && (
                  <div className="error_field">
                    <span>{formik.errors.message}</span>
                  </div>
                )}
                <CommonBtn
                  onClick={formik.handleSubmit}
                  title="SUBMIT"
                  type="submit"
                  className="btn-bordered my-sm-5 my-3"
                />
              </Col>
            </Row>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default ContactUs;
