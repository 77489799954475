import React, { useEffect, useState } from "react";
import styles from "../marketplace.module.scss";
import { Col, Container, Row } from "react-bootstrap";
import CommonCard from "../../../components/ui/CommonCards/CommonCard/CommonCard";
import MarketplaceHead from "../MarketplaceHead/MarketplaceHead";
import CommonBtn from "../../../components/ui/CommonBtn/CommonBtn";
import APIURLS from "../../../features/ApiPath/ApiPath";
import { apiCallPost } from "../../../features/Axios/Axios";
import { getAllCategory } from "../../../features/Utility/Utility";
import CollectionSkelton from "./CollectionSkelton";
import { useLocation } from "react-router-dom";
import NoResult from "../../../components/ui/NoResult/NoResult";

const Nft = () => {
  const location = useLocation();
  const [allCollectionData, setAllCollectionData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchvalue, setSearchvalue] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [allCategory, setAllCategory] = useState([]);
  const [categoryId, setCategoryId] = useState(location?.state?.id || "");
  const [categoryLoader, setCategoryLoader] = useState(true);

  const getAllCollection = async (page, searchvalue) => {
    setCategoryLoader(true);
    try {
      // setCategoryId(state.state || "All");
      let res = await apiCallPost(
        `${APIURLS.COLLECTION.GET_ALL_COLLECTION}/${page}/12`,
        {
          search: searchvalue,
          filter: filterValue,
          category: categoryId || location?.state?.state || "All",
        },
        {}
      );
      if (res?.data) {
        if (page > 1 || (searchvalue !== "" && page > 1)) {
          setAllCollectionData((allCollectionData) => [
            ...allCollectionData,
            ...res?.data?.rows,
          ]);
        } else {
          setAllCollectionData(res?.data?.rows);
          setCategoryLoader(false);
        }
        setTotalCount(res?.data?.count);
        setCategoryLoader(false);
      }
    } catch (error) {
      console.error(error);
      setCategoryLoader(false);
    }
  };
  useEffect(() => {
    getAllCategory(setAllCategory);
  }, []);

  useEffect(() => {
    const finder = setTimeout(async () => {
      setCurrentPage(1);
      await getAllCollection("1", searchvalue);
    }, 500);
    return () => {
      clearTimeout(finder);
    };
  }, [searchvalue, filterValue, categoryId]);

  const handleViewMore = (nextPage) => {
    if (searchvalue && searchvalue.length >= 1) {
      getAllCollection(nextPage, searchvalue);
    } else {
      getAllCollection(nextPage, "");
    }
  };

  return (
    <>
      <section className={styles.collection_sec}>
        <MarketplaceHead
          item="Collections"
          item1="NFTs"
          setSearchvalue={setSearchvalue}
          searchvalue={searchvalue}
          setFilterValue={setFilterValue}
          defaultValue={location?.state?.cname}
          allCategory={allCategory}
          setCategoryId={setCategoryId}
        />
        <Container>
          <Row>
            {categoryLoader ? (
              <CollectionSkelton />
            ) : (
              <>
                {allCollectionData && allCollectionData.length === 0 ? (
                  <NoResult />
                ) : (
                  <>
                    {allCollectionData?.map((item, index) => (
                      <Col
                        xs={12}
                        sm={6}
                        lg={4}
                        xl={3}
                        key={index}
                        className="mt-5"
                      >
                        <CommonCard
                          img={item.featuredImage}
                          title={item.title}
                          to={item.id}
                          subtitle={
                            <>
                              NFTs: <strong>{item.noOfNfts}</strong>
                            </>
                          }
                          galleryData={item?.nfts.slice(0, 3)}
                          key={index}
                          btnTitle="BUY"
                          setAllCollectionData={setAllCollectionData}
                          isFavourite={item.isFavourite}
                          type={"collection"}
                          getAllCollection={getAllCollection}
                          marketPlaceCollectionOnly
                        />
                      </Col>
                    ))}
                  </>
                )}
              </>
            )}
          </Row>

          {allCollectionData?.length !== totalCount && (
            <div className="text-center mt-5 ">
              <CommonBtn
                title="VIEW MORE"
                className="btn-bordered"
                onClick={() => {
                  handleViewMore(currentPage + 1);
                  setCurrentPage((prevValue) => prevValue + 1);
                }}
              />
            </div>
          )}
        </Container>
      </section>
    </>
  );
};

export default Nft;
