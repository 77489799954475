import React, { useEffect, useState } from "react";
import styles from "./ProfileSettings.module.scss";
import Form from "react-bootstrap/Form";
import { Row, Col, Container } from "react-bootstrap";
import CommonBtn from "../../components/ui/CommonBtn/CommonBtn";
import { EditFileIcon } from "../../assets/svgIcons/SVGicon";
import BG from "../../assets/images/profile-img.png";
import Bg from "../../assets/images/banner.png";
import { useFormik } from "formik";
import { ProfileSettingValidationSchema } from "../../features/Validation/YupValidations";
import { apiCallGet, apiCallPut } from "../../features/Axios/Axios";
import { useDispatch, useSelector } from "react-redux";
import APIURLS from "../../features/ApiPath/ApiPath";
import { getImageUrl, handleUpload } from "../../features/Utility/Utility";
import { useNavigate } from "react-router-dom";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import ChangeEmail from "./ChangeEmail";
import VerificationModal from "../../components/ui/VerificationModal/VerificationModal";
import { setUserLogin } from "../../Redux/Reducers/user";
import { resetWallet } from "../../Redux/Reducers/wallet";
import ProfilePrev from "./ProfilePrev.jsx";
import { API_REQUEST_PROFILE } from "../../features/Constant/Constant";

const ProfileSettings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const useInfo = useSelector((state) => state?.user);
  const [changeEamilModal, setChangeEmailModal] = useState(false);
  const [verifyChangeEmail, setVerifyChangeEmail] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const [profileSettingLoader, setProfileSettingLoader] = useState(false);
  const [fileList, setFilelist] = useState([]);
  const [profileError, setProfileError] = useState("");
  const [coverError, setCoverError] = useState("");
  const [submitButtonDisable, setSubmitButtonDisable] = useState(false);
  const [uniqUsername, setUniqUsername] = useState({
    UserMessage: "",
    UserError: false,
  });

  const formik = useFormik({
    initialValues: {
      profileImage: useInfo?.userData?.userMeta?.profileImage || "",
      coverImage: useInfo?.userData?.userMeta?.coverImage || "",
      fullName: useInfo?.userData?.fullName || "",
      username: useInfo?.userData?.username,
      phoneNo: useInfo?.userData?.userMeta?.phoneNo || "",
      email: useInfo?.userData?.email,
      bio: useInfo?.userData?.userMeta?.bio || "",
      twitter: useInfo?.userData?.userMeta?.twitter || "",
      instagram: useInfo?.userData?.userMeta?.instagram || "",
      hasDeleteProfileImage: false,
      hasDeleteCoverImage: false,
    },
    validationSchema: ProfileSettingValidationSchema,
    onSubmit: async (values) => {
      setProfileSettingLoader(true);
      try {
        if (fileList.length !== 0) {
          const imageUplode = await handleUpload(fileList, API_REQUEST_PROFILE);
          const data = {
            ...values,
            profileImage:
              getImageUrl(imageUplode, "profileImage") ||
              useInfo?.userData?.userMeta?.profileImage ||
              "",
            coverImage:
              getImageUrl(imageUplode, "coverImage") ||
              useInfo?.userData?.userMeta?.coverImage ||
              "",
          };
          return updateProfile(data);
        }
        return updateProfile(values);
      } catch (error) {
        setProfileSettingLoader(false);
      }
    },
  });
  const updateProfile = async (data) => {
    setProfileSettingLoader(true);
    try {
      const res = await apiCallPut(
        `${APIURLS.USER.UPDATE_PROFILE}`,
        data,
        {},
        "toastOn"
      );
      if (res) {
        setProfileSettingLoader(false);
        navigate(`/${useInfo?.userData?.username}/favourites`);
      }
    } catch (error) {
      setProfileSettingLoader(false);
    }
  };

  const profilenadBannerImageHandler = (e, key, hasDeleteImageKey) => {
    formik.setFieldValue(key, e.target.files[0]);
    setFilelist([...fileList, { url: e.target.files[0], key: key }]);
    formik.setFieldValue(hasDeleteImageKey, false);
  };
  const profilenadBannerImageDeleteHandler = (key, hasDeleteImageKey) => {
    formik.setFieldValue(key, "");
    setFilelist(fileList.filter((item) => item.key !== key));
    formik.setFieldValue(hasDeleteImageKey, true);
  };

  const imageTypeSizeCheck = async (file, field) => {
    if (typeof file === "object") {
      const allowedTypes = ["image/jpeg", "image/png", "image/webp"];
      const maxSize = 5 * 1024 * 1024;
      if (allowedTypes.includes(file?.type) && file?.size <= maxSize) {
        if (field === "coverImage") {
          setCoverError("");
          setSubmitButtonDisable(false);
        } else {
          setProfileError(" ");
          setSubmitButtonDisable(false);
        }
      } else {
        if (field === "coverImage") {
          setCoverError("Please verify image type and size");
          setSubmitButtonDisable(true);
        } else {
          setProfileError("Please verify image type and size");
          setSubmitButtonDisable(true);
        }
      }
    } else {
      if (field === "coverImage") {
        setCoverError("");
        setSubmitButtonDisable(false);
      } else {
        setProfileError("");
        setSubmitButtonDisable(false);
      }
    }
  };

  useEffect(() => {
    if (formik.touched.profileImage) {
      imageTypeSizeCheck(formik.values.profileImage, "profileImage");
    }
    if (formik.touched.coverImage) {
      imageTypeSizeCheck(formik.values.coverImage, "coverImage");
    }
  }, [formik]);

  useEffect(() => {
    const finder = setTimeout(async () => {
      if (formik.values.username && formik.values.username.length >= 3) {
        try {
          const res = await apiCallGet(
            `${APIURLS.USER.CHECK_USERNAME}/${formik.values.username}`
          );
          if (res) {
            setUniqUsername({ UserMessage: res.message, UserError: false });
          }
        } catch (error) {
          setUniqUsername({ UserMessage: error.message, UserError: true });
        }
      }
    }, [500]);
    return () => {
      clearTimeout(finder);
    };
  }, [formik.values.username]);

  const updateEmailHandler = () => {
    setChangeEmailModal(true);
  };

  return (
    <>
      <section className={styles.profile_sec}>
        <Container>
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col lg={12}>
                <div className={styles.profile_sec_head}>
                  <h2>Profile Settings</h2>
                  <p>
                    You can set preferred display name, create your branded
                    profile URL and manage other personal settings.
                  </p>
                  <div className={styles.hl} />
                </div>
              </Col>
              <Col sm={12} md={6} lg={6} className={styles.profile_sec_left}>
                <h5>Profile Image</h5>
                <p>
                  This image will also be used for navigation. 350 x 350
                  <br />
                  recommended.
                </p>
                <div
                  className={`profile-img ${styles.profile_img}`}
                  style={{
                    backgroundImage: `url( ${
                      !formik?.values?.profileImage && BG
                    }) `,
                  }}
                >
                  <div className={styles.profile_img_icon}>
                    {formik?.values?.profileImage ? (
                      <>
                        <div className={styles.profile_img_icon}>
                          <div className="imgprebox">
                            <button
                              onClick={() =>
                                profilenadBannerImageDeleteHandler(
                                  "profileImage",
                                  "hasDeleteProfileImage"
                                )
                              }
                              className="removeImg"
                              type="button"
                            >
                              X
                            </button>
                            <ProfilePrev
                              file={
                                formik.values.profileImage ||
                                useInfo?.userData?.userMeta?.profileImage
                              }
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <EditFileIcon />
                        <Form.Control
                          type="file"
                          placeholder="Uplode your profile image"
                          id="profileImage"
                          name="profileImage"
                          value={formik.values.profileImage}
                          accept="image/png, image/jpeg, image/webg"
                          onChange={(e) =>
                            profilenadBannerImageHandler(
                              e,
                              "profileImage",
                              "hasDeleteProfileImage"
                            )
                          }
                          onBlur={formik.handleBlur}
                        />
                      </>
                    )}
                  </div>
                </div>
                {profileError && (
                  <div className="error_field" style={{ marginTop: "-2.5rem" }}>
                    <span>{profileError}</span>
                  </div>
                )}
              </Col>
              <Col sm={12} md={6} lg={6} className={styles.profile_sec_right}>
                <h5>Cover Image</h5>
                <p>
                  This image will be appear at the top of your collection page.
                  Avoid including too much text in this banner image, as the
                  dimensions change on different devices. 1400 x 350
                  recommended.
                </p>
                <div
                  className={`profile_banner ${styles.profile_banner}`}
                  style={{
                    backgroundImage: `url( ${
                      !formik?.values?.coverImage && Bg
                    }) `,
                  }}
                >
                  {formik?.values?.coverImage ? (
                    <>
                      <div className="imgprebox">
                        <button
                          onClick={() =>
                            profilenadBannerImageDeleteHandler(
                              "coverImage",
                              "hasDeleteCoverImage"
                            )
                          }
                          className="removeImg"
                          type="button"
                        >
                          X
                        </button>

                        <ProfilePrev
                          file={
                            formik.values.coverImage ||
                            useInfo?.userData?.userMeta?.coverImage
                          }
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className={styles.profile_banner_icon}>
                        <button type="button">
                          <EditFileIcon />
                        </button>
                        <Form.Control
                          type="file"
                          placeholder="Uplode your profile cover image"
                          id="coverImage"
                          name="coverImage"
                          accept=".png,.jpg,.jpeg,.webp"
                          value={formik.values.coverImage}
                          onChange={(e) =>
                            profilenadBannerImageHandler(
                              e,
                              "coverImage",
                              "hasDeleteCoverImage"
                            )
                          }
                          onBlur={formik.handleBlur}
                        />
                      </div>
                    </>
                  )}
                </div>
                {coverError && (
                  <div className="error_field" style={{ marginTop: "-2.5rem" }}>
                    <span>{coverError}</span>
                  </div>
                )}
              </Col>
              <Col sm={12} md={6} lg={6} className={styles.profile_sec_left}>
                <Form.Group className="mb-3">
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your name"
                    id="fullName"
                    name="fullName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.fullName}
                    minLength="3"
                    maxLength="30"
                  />
                  {formik.errors.fullName && (
                    <div className="error_field">
                      <span>{formik.errors.fullName}</span>
                    </div>
                  )}
                </Form.Group>
              </Col>
              <Col sm={12} md={6} lg={6} className={styles.profile_sec_right}>
                <Form.Group className="mb-3">
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your username"
                    id="username"
                    name="username"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.username}
                    minLength="3"
                    maxLength="20"
                  />
                  {!formik.errors.username &&
                    formik.values.username &&
                    formik.touched.username && (
                      <div
                        className={`error_field ${
                          uniqUsername.UserError ? "" : "success"
                        }`}
                      >
                        <span>{uniqUsername.UserMessage}</span>
                      </div>
                    )}

                  {formik.errors.username && (
                    <div className="error_field">
                      <span>{formik.errors.username}</span>
                    </div>
                  )}
                </Form.Group>
              </Col>
              <Col sm={12} md={6} lg={6} className={styles.profile_sec_left}>
                <Form.Group
                  className={`mb-3 ${styles.profile_sec_left_phoneInput}`}
                >
                  <Form.Label>Mobile Number</Form.Label>
                  <PhoneInput
                    className={
                      styles.profile_sec_left_phoneInput_phoneInputStyle
                    }
                    international
                    placeholder="Enter your mobile number"
                    defaultCountry="HR"
                    value={formik.values.phoneNo}
                    onChange={(e) => formik.setFieldValue("phoneNo", e)}
                  />
                  {formik.errors.phoneNo && (
                    <div className="error_field">
                      <span>{formik.errors.phoneNo}</span>
                    </div>
                  )}
                </Form.Group>
              </Col>
              <Col sm={12} md={6} lg={6} className={styles.profile_sec_right}>
                <Form.Group className="mb-3">
                  <Form.Label>Email Address</Form.Label>
                  <div style={{ position: "relative" }}>
                    <Form.Control
                      type="text"
                      placeholder="abc@gmail.com"
                      id="email"
                      name="email"
                      disabled
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                    />
                    <button
                      type="button"
                      className={styles.profile_sec_right_emailEdit}
                      onClick={() => updateEmailHandler()}
                    >
                      Edit
                    </button>
                  </div>
                  {formik.errors.email && (
                    <div className="error_field">
                      <span>{formik.errors.email}</span>
                    </div>
                  )}
                </Form.Group>
              </Col>
              <Col sm={12} md={6} lg={6} className={styles.profile_sec_left}>
                <Form.Group className="mb-3">
                  <Form.Label>Bio</Form.Label>
                  <Form.Control
                    as="textarea"
                    type="text"
                    rows={4}
                    placeholder="Write about yourself"
                    id="bio"
                    name="bio"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.bio}
                    maxLength={200}
                  />
                  {formik.errors.bio && (
                    <div className="error_field">
                      <span>{formik.errors.bio}</span>
                    </div>
                  )}
                </Form.Group>
              </Col>
              <Col sm={12} md={6} lg={6} className={styles.profile_sec_right}>
                <Form.Group className="mb-3">
                  <Form.Label>Twitter Username</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="e.g. http://www.twitter.com/{username}"
                    id="twitter"
                    name="twitter"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.twitter}
                  />
                  {formik.errors.twitter && (
                    <div className="error_field">
                      <span>{formik.errors.twitter}</span>
                    </div>
                  )}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Instagram username</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="e.g. http://www.instagram.com/{username}"
                    id="instagram"
                    name="instagram"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.instagram}
                  />
                  {formik.errors.instagram && (
                    <div className="error_field">
                      <span>{formik.errors.instagram}</span>
                    </div>
                  )}
                </Form.Group>
              </Col>
              <Col lg={12}>
                <CommonBtn
                  title="SUBMIT"
                  type="submit"
                  disabled={!formik.isValid || submitButtonDisable}
                  className="btn-bordered my-5"
                  loader={profileSettingLoader}
                />
              </Col>
            </Row>
          </Form>
        </Container>
      </section>
      {changeEamilModal && (
        <ChangeEmail
          show={changeEamilModal}
          onHide={() => setChangeEmailModal(false)}
          setChangeEmailModal={setChangeEmailModal}
          setVerifyChangeEmail={setVerifyChangeEmail}
          setNewEmail={setNewEmail}
        />
      )}
      {verifyChangeEmail && (
        <VerificationModal
          show={verifyChangeEmail}
          onHide={() => setVerifyChangeEmail(false)}
          setVerifyPass={setVerifyChangeEmail}
          title="Verification Code"
          btnText="VERIFY"
          userEmail={newEmail}
          triggerVerifyOtp={(otp) => {
            if (otp) {
              setVerifyChangeEmail(false);
              dispatch(setUserLogin(false));
              dispatch(resetWallet());
              localStorage.clear();
              navigate("/");
            } else {
              setVerifyChangeEmail(false);
              setChangeEmailModal(true);
            }
          }}
        />
      )}
    </>
  );
};

export default ProfileSettings;
