import React from "react";
import { Form } from "react-bootstrap";
import { SearchIcon } from "../../../assets/svgIcons/SVGicon";
import styles from "./CommonSearch.module.scss";

import { useGlobalSearchContext } from "../../../context/GlobalSearchContext";

const GlobalSearchComponent = ({ className }) => {
  const { globalSearchTerm, setGlobalSearchTerm } = useGlobalSearchContext();

  return (
    <>
      <div className={`${styles.common_search} ${className}`}>
        <div style={{ position: "relative" }}>
          <Form.Control
            type="name"
            placeholder="Search assets"
            required
            value={globalSearchTerm}
            onChange={(e) => {
              setGlobalSearchTerm(e.target.value);
            }}
          />
          <button type="submit">
            <SearchIcon />
          </button>
        </div>
      </div>
    </>
  );
};

export default GlobalSearchComponent;
