import React from "react";
import { Container } from "react-bootstrap";
import Slider from "react-slick";
import CategoryCard from "../../../components/ui/CommonCards/CategoryCard/CategoryCard";
import styles from "./BrowseByCategory.module.scss";
import { useNavigate } from "react-router-dom";

const BrowseByCategory = ({ data }) => {
  const navigate = useNavigate();
  console.log("sdfsdf", data);
  const settings = {
    dots: false,
    infinite: data?.length > 4 ? true : false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    pauseOnHover: false,
    pauseOnFocus: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <section className={styles.browse_by_cat}>
        <Container>
          <div className={styles.browse_by_cat_head}>
            <h2>Browse By Categories</h2>
            <p>
              Don't bother typing "lorem ipsum" into Google translate. If you
              already tried, you may have gotten anything from "NATO" to
              "China", depending on how you capitalized the letters. The bizarre
              translation was fodder for conspiracy theories.<span></span>
            </p>
          </div>
          {data && (
            <Slider {...settings}>
              {data?.map((item, index) => {
                return (
                  <CategoryCard
                    img={item?.categoryImage}
                    title={item?.categoryName}
                    onClick={() => {
                      navigate(`marketplace/collections/`, {
                        state: { id: item?.id, cname: item?.categoryName },
                      });
                    }}
                    // to={to}
                    key={item?.id}
                    className="homeCategoryCards"
                  />
                );
              })}
            </Slider>
          )}
        </Container>
      </section>
    </>
  );
};
export default BrowseByCategory;
