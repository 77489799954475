import React from "react";
import { Col, Row } from "react-bootstrap";
import { SortIcon } from "../../../../assets/svgIcons/SVGicon";
import CommonSearch from "../../../../components/ui/CommonSearch/CommonSearch";
import styles from "./SearchAndFilter.module.scss";
import CommonSelectbox from "../../../../components/ui/Dropdowns/CommonSelectbox";

const SearchAndFilter = ({
  search,
  collection,
  setSearchvalue,
  searchvalue,
  newest,
  newestOptions,
  type,
  setFilterValue,
  setCollectionValue,
  setCurrentPage,
  collectionFilterTitle,
  profileFilters,
  setStatusFilter,
  setNewestFilterValue,
  options,
  collectionFilterOption,
}) => {
  return (
    <>
      <div className={styles.search_filter}>
        {search && (
          <>
            <Row className="align-items-center">
              <Col xs={12} md={6} lg={7} xxl={8}>
                <CommonSearch
                  placeholder="Search..."
                  setSearchvalue={setSearchvalue}
                  searchvalue={searchvalue}
                  setCurrentPage={setCurrentPage}
                  className={styles.search_filter_search}
                />
              </Col>
              <Col xs={12} md={6} lg={5} xxl={4} className="mt-5 mt-md-0">
                <div className={styles.search_filter_action}>
                  {collection && (
                    <>
                      <CommonSelectbox
                        icon={<SortIcon />}
                        setFilterValue={setCollectionValue}
                        type={type}
                        setCurrentPage={setCurrentPage}
                        title={collectionFilterTitle || "Collection"}
                        options={collectionFilterOption}
                      />
                    </>
                  )}
                  {profileFilters && (
                    <>
                      <CommonSelectbox
                        icon={<SortIcon />}
                        title="All"
                        type="status"
                        options={profileFilters}
                        setFilterValue={setFilterValue}
                        setCurrentPage={setCurrentPage}
                        setStatusFilter={setStatusFilter}
                      />
                    </>
                  )}
                  {newest && (
                    <>
                      <CommonSelectbox
                        icon={<SortIcon />}
                        title="Newest"
                        options={newestOptions}
                        type={type}
                        setFilterValue={setNewestFilterValue}
                        setCurrentPage={setCurrentPage}
                      />
                    </>
                  )}
                </div>
              </Col>
            </Row>
          </>
        )}
      </div>
    </>
  );
};
export default SearchAndFilter;
