import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Footer from "../../footer/Footer";
import Header from "../../header/Header";
import styles from "./MainLayout.module.scss";
import { useLocation } from "react-router-dom";

const MainLayout = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Header />
      <main className={styles.mainHandle}>
        <Outlet />
      </main>
      <Footer />
    </>
  );
};

export default MainLayout;
