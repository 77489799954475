import React, { useState } from "react";
import CommonModal from "../CommonModal/CommonModal";
import CommonBtn from "../CommonBtn/CommonBtn";
import OTPInput, { ResendOTP } from "otp-input-react";
import "../CommonModal/CommonModal.scss";
import { apiCallPost } from "../../../features/Axios/Axios";
import APIURLS from "../../../features/ApiPath/ApiPath";
import { toasts } from "../../common/Toast/Toast";

const VerificationModal = ({
  show,
  onHide,
  setVerifyPass,
  title,
  btnText,
  userEmail,
  triggerVerifyOtp,
}) => {
  const [otp, setOtp] = useState(0);

  const verifyOpt = async () => {
    if (otp.length === 4) {
      try {
        const res = await apiCallPost(
          APIURLS.USER.OTPVERIFY,
          { email: userEmail, otp: +otp },
          {},
          "toastOn"
        );
        if ((res.status === 200) | (res.status === 201)) {
          triggerVerifyOtp(otp);
          setVerifyPass(false);
        }
      } catch (error) {
        console.error({ error });
      }
    } else {
      toasts.warn("Please write 4 number otp");
    }
  };
  const resendOtp = async () => {
    try {
      const res = await apiCallPost(
        APIURLS.USER.RESEND_OTP,
        { email: userEmail },
        {},
        "toastOn"
      );
      if (res === 200) {
      }
    } catch (error) {}
  };
  const renderTime = (remainingTime) => {
    return <span>{remainingTime} Seconds</span>;
  };

  const renderButton = (buttonProps) => {
    return <button {...buttonProps}>Resend</button>;
  };

  return (
    <>
      <CommonModal
        show={show}
        onHide={onHide}
        className="verification_modal"
        modalTitle={
          <>
            <h3 className="creat_headind">{title}</h3>
          </>
        }
        backdrop="static"
        closeBtn={true}
      >
        <OTPInput
          value={otp}
          onChange={setOtp}
          className="verification_modal_otp"
          OTPLength={4}
          otpType="number"
          disabled={false}
          placeholder="****"
          autoFocus
        />
        <CommonBtn
          className="mt-3 w-100"
          title={btnText}
          onClick={() => verifyOpt()}
        />
        <div className="signup_note">
          <p className="resend_block">
            Don’t Receive Link?
            <ResendOTP
              maxTime={120}
              renderButton={renderButton}
              className="verification_modal_resend"
              onResendClick={() => resendOtp()}
              renderTime={renderTime}
            />
          </p>
        </div>
      </CommonModal>
    </>
  );
};
export default VerificationModal;
