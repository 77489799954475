import React, { useEffect, useState } from "react";
import styles from "./Dividend.module.scss";
import SearchAndFilter from "../commonComponents/SearchAndFilter/SearchAndFilter";
import CommonTable from "../../../components/ui/CommonTable/CommonTable";
import CommonBtn from "../../../components/ui/CommonBtn/CommonBtn";
import { Link } from "react-router-dom";
import img from "../../../assets/images/logo-large.png";
import { SendIcon } from "../../../assets/svgIcons/SVGicon";
import AlertModal from "../../../components/ui/AlertModal/AlertModal";
import { apiCallPost } from "../../../features/Axios/Axios";
import APIURLS from "../../../features/ApiPath/ApiPath";
import { formatDate, isArrayEmpty } from "../../../features/Utility/Utility";
import { FILTER_PROFILE_FRACTION_SORT } from "../../../features/Constant/Constant";
import useDebounce from "../../../hooks/useDebounce";
const Dividend = () => {
  const [dividentData, setDividentData] = useState([]);
  const [currPage, setCurrPage] = useState(1);
  const [itemForRedeem, setItemForRedeem] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const debounceTerm = useDebounce(searchTerm);
  const [order, setOrder] = useState(FILTER_PROFILE_FRACTION_SORT[0].value);
  const getDividentDate = async () => {
    try {
      const { data } = await apiCallPost(
        APIURLS.DIVIDENT.SHOW,
        {
          page: currPage,
          limit: 10,
          order: order,
          search: debounceTerm,
        },
        {},
        "toastOnError"
      );
      setDividentData(data);
    } catch (error) {}
  };
  const redeemAmount = async () => {
    try {
      await apiCallPost(APIURLS.DIVIDENT.CLAM, {
        dividendId: itemForRedeem.dividendId,
      });
      setDividentData((prev) =>
        prev.map((item) => {
          if (item.dividendId === itemForRedeem.dividendId) {
            return { ...item, isClaimed: true };
          }
          return item;
        })
      );
      onHide();
    } catch (error) {}
  };
  const [show, setShow] = useState(false);
  const onHide = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    getDividentDate();
  }, [currPage, debounceTerm, order]);

  return (
    <>
      <div className={styles.dividend}>
        <SearchAndFilter
          search={true}
          setNewestFilterValue={setOrder}
          setCurrentPage={setCurrPage}
          searchvalue={searchTerm}
          setSearchvalue={setSearchTerm}
          type="filter"
          newestOptions={FILTER_PROFILE_FRACTION_SORT}
          newest={true}
        />
        <CommonTable
          isDataEmpty={isArrayEmpty(dividentData)}
          className="table-box mt-5"
        >
          <thead>
            <tr>
              <th>NFTs</th>
              <th>Amount</th>
              <th>Date & Time</th>
              <th width="12rem">Actions</th>
            </tr>
          </thead>
          <tbody>
            {dividentData?.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item?.nftTitle}</td>
                  <td>{item?.dividendXAR}</td>
                  <td>{formatDate(item.date)}</td>
                  <td>
                    {!item.isClaimed ? (
                      <>
                        <CommonBtn
                          title="CLAIM"
                          className="btn-bordered btn-small my-2"
                          onClick={() => {
                            setItemForRedeem(item);
                            handleShow();
                          }}
                        />
                      </>
                    ) : (
                      <div className={styles.claimed_btn}>
                        <Link to="#">
                          Claimed <SendIcon />
                        </Link>
                      </div>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </CommonTable>

        <AlertModal
          img={img}
          title={
            <div className="px-lg-5">
              {`Are You Sure You Want To Claim XAR ${itemForRedeem?.dividend}
              Tokens To Your Wallet?`}
            </div>
          }
          btnAction={() => {
            redeemAmount();
          }}
          btnText="CONTINUE"
          show={show}
          onHide={onHide}
          handleShow={handleShow}
        />
      </div>
    </>
  );
};

export default Dividend;
