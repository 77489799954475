import React, { useEffect, useState } from "react"
import styles from "./SearchResults.module.scss"
import { Container, Row, Col } from "react-bootstrap"
import CommonBtn from "../../../components/ui/CommonBtn/CommonBtn"
import CommonCard from "../../../components/ui/CommonCards/CommonCard/CommonCard"
import CommonSelectbox from "../../../components/ui/Dropdowns/CommonSelectbox"
import { SortIcon } from "../../../assets/svgIcons/SVGicon"
import NoResult from "../../../components/ui/NoResult/NoResult"
import CollectionSkelton from "../../marketplace/Collections/CollectionSkelton"
import { getAllCategory } from "../../../features/Utility/Utility"
import { apiCallPost } from "../../../features/Axios/Axios"
import APIURLS from "../../../features/ApiPath/ApiPath"
import SearchHeader from "./SearchHeader"
import { useGlobalSearchContext } from "../../../context/GlobalSearchContext"

const SearchResultNft = () => {
  const { globalSearchTerm } = useGlobalSearchContext()
  const [nftData, setNftData] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [filterValue, setFilterValue] = useState("")
  const [allCategory, setAllCategory] = useState([])
  const [categoryId, setCategoryId] = useState("all")
  const [nftLoader, setNftLoader] = useState(true)
  const getAllNFT = async (page, globalSearchTerm) => {
    setNftLoader(true)
    try {
      let res = await apiCallPost(
        APIURLS.GLOBAL_SEARCH,
        {
          search: globalSearchTerm,
          filter: "nft",
          page: currentPage,
          limit: 12,
        },
        {},
        "toastOnError"
      )
      if (res?.data) {
        if (page > 1 || (globalSearchTerm !== "" && page > 1)) {
          setNftData((nftData) => [...nftData, ...res?.data?.rows])
          setNftLoader(false)
        } else {
          setNftData(res?.data?.rows)
          setNftLoader(false)
        }
        setTotalCount(res?.data?.count)
        setNftLoader(false)
      }
    } catch (error) {
      setNftLoader(false)
      console.log(error)
    }
  }
  useEffect(() => {
    getAllCategory(setAllCategory)
  }, [])
  useEffect(() => {
    const finder = setTimeout(async () => {
      setCurrentPage(1)
      await getAllNFT("1", globalSearchTerm)
    }, [500])
    return () => {
      clearTimeout(finder)
    }
  }, [globalSearchTerm, filterValue, categoryId])

  const handleViewMore = (nextPage) => {
    if (globalSearchTerm && globalSearchTerm.length >= 1) {
      getAllNFT(nextPage, globalSearchTerm)
    } else {
      getAllNFT(nextPage, "")
    }
  }

  useEffect(() => {
    getAllCategory(setAllCategory)
  }, [])
  return (
    <>
      <section className={styles.search_result_sec}>
        <Container>
          <div className="d-flex flex-wrap align-items-center justify-content-between">
            <h2 className="text-white">Search Results</h2>
            <CommonSelectbox
              icon={<SortIcon />}
              title={"Select Category"}
              options={allCategory}
              className="me-3"
              setCategoryId={setCategoryId}
              type={"category"}
            />
          </div>
          <SearchHeader />

          <Container>
            <Row>
              {nftLoader ? (
                <CollectionSkelton />
              ) : (
                <>
                  {nftData?.length > 0 ? (
                    <>
                      {nftData?.map((item, index) => (
                        <Col
                          xs={12}
                          sm={6}
                          lg={4}
                          xl={3}
                          className="mt-5"
                          key={index}>
                          <CommonCard
                            img={item?.image}
                            fileType={item?.fileType}
                            title={item?.title}
                            nftId={item?.nftId}
                            to={item?.nftId}
                            subtitle={item?.collection.title}
                            price={item?.price}
                            fractions={item?.quantity}
                            btnTitle="BUY"
                            isFavourite={item?.isFavourite}
                            type={"nft"}
                            setNftData={setNftData}
                          />
                        </Col>
                      ))}
                      {nftData?.length !== totalCount && (
                        <div className="text-center mt-5 ">
                          <CommonBtn
                            title="VIEW MORE"
                            className="btn-bordered"
                            onClick={() => {
                              handleViewMore(currentPage + 1)
                              setCurrentPage((prevValue) => prevValue + 1)
                            }}
                          />
                        </div>
                      )}
                    </>
                  ) : (
                    <NoResult />
                  )}
                </>
              )}
            </Row>
          </Container>
        </Container>
      </section>
    </>
  )
}

export default SearchResultNft
