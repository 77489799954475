import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import CommonBtn from "../../../components/ui/CommonBtn/CommonBtn";
import styles from "./HeroSec.module.scss";
import Ncounter from "./Ncounter/Ncounter";
import Logo from "../../../assets/images/logo.png";
import { NavHashLink } from "react-router-hash-link";
import videoBanner from "../../../assets/hero-vdo.webm";

const HeroSec = ({ countAssetCategory, countCollection, countNFT }) => {
  const [squares7and8, setSquares7and8] = useState("");
  useEffect(() => {
    document.body.classList.toggle("HomePage");
    document.documentElement.addEventListener("mousemove", followCursor);
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.body.classList.toggle("HomePage");
      document.documentElement.removeEventListener("mousemove", followCursor);
    };
  }, []);
  const followCursor = (event) => {
    let posX = event.clientX - window.innerWidth / 2;
    let posY = event.clientY - window.innerWidth / 6;
    setSquares7and8(
      "perspective(500px) rotateY(" +
        posX * 0.002 +
        "deg) rotateX(" +
        posY * -0.002 +
        "deg)"
    );
  };
  return (
    <>
      <section className={`hero-static ${styles.hero}`}>
        <Container>
          <Row>
            <Col md={6} className="pt-md-5 mt-xxl-5">
              <div className={styles.hero_left}>
                <h1>
                  Discover <span>Buy, Sell & Fractionalize</span> Assets
                </h1>
                <p>
                  The placeholder text, beginning with the line Lorem ipsum
                  dolor sit amet, consectetur adipiscing placeholder
                </p>
                <div className={styles.hero_left_action}>
                  <CommonBtn
                    to="marketplace/collections"
                    role="link"
                    title="EXPLORE"
                    className="exploreBtn"
                  />
                  <NavHashLink
                    className="commonBtn btn-bordered"
                    to="/#how-it-works">
                    HOW IT WORK
                  </NavHashLink>
                </div>
                {countAssetCategory && countCollection && countNFT && (
                  <Ncounter
                    start={0}
                    title="Asset Categories"
                    countAssetCategory={countAssetCategory}
                    countCollection={countCollection}
                    countNFT={countNFT}
                  />
                )}
              </div>
            </Col>
            <Col md={6}>
              <div
                className={styles.hero_right}
                style={{ transform: squares7and8 }}>
                <div className={styles.hero_right_inner}>
                  <div className={styles.hero_right_inner_vdo}>
                    <video
                      width="100%"
                      height="100%"
                      autoPlay
                      playsInline
                      muted
                      loop>
                      <source src={videoBanner} type="video/mp4" />
                    </video>
                    <div className={styles.hero_right_inner_label}>
                      <img src={Logo} alt="" /> <h4>GOLD BULLION</h4>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default HeroSec;
