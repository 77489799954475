import React from 'react';
import styles from "./AdminSupport.module.scss";
import CommonBtn from '../../components/ui/CommonBtn/CommonBtn';
import { TimeFrameIcon } from '../../assets/svgIcons/SVGicon';
import { Col, Container, Row } from 'react-bootstrap';

const AdminSupport = () => {
    return (
        <>
            <section className={styles.admin_sec}>
                <Container>
                    <Row>
                        <Col>
                            <h2>
                                #93762 Ticket
                            </h2>
                            <div className={styles.admin_sec_block}>
                                <div className={styles.admin_sec_block_detail}>
                                    <h6>I have problem with transaction</h6>
                                    <CommonBtn className="btn-bordered-yellow" title="Open" />
                                </div>
                                <p>I tried to buy an item, the money transfer did not go through. Several times I re-created the deal-nothing come of it. I would like to know what the problem is and ask you to solve it</p>
                            </div>
                            <div className={styles.admin_sec_period}>
                                <TimeFrameIcon />
                                <h6>Waiting for admin support!</h6>
                                <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default AdminSupport;