import * as Yup from "yup";
import {
  IMAGE_TYPE,
  VIDEO_TYPE,
  maxFileSizeAllowed,
} from "../Constant/Constant";

const validmediaType = IMAGE_TYPE.concat(VIDEO_TYPE);

export const loginFormValidations = Yup.object().shape({
  email: Yup.string().required("Email/Username is required"),
  password: Yup.string().trim().required("Password is required."),
});
export const changeEmailValidation = Yup.object().shape({
  email: Yup.string()
    .matches(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Invalid email address"
    )
    .required("Email is required"),
});
export const SignUpValidationSchema = Yup.object({
  userName: Yup.string()
    .matches(/^[a-zA-Z0-9._]+(?<![_.])$/, "Special characters are not allowed")
    .matches(/^\S*$/, "Space are not allowed in username")
    .min(3, "Username is too short, should be 3 character minimum.")
    .max(20, "Username Must be 20 characters or less")
    .strict(true)
    .trim("Space are not allowed")
    .required("Username is required"),
  fullName: Yup.string()
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
    .min(3, "Fullname is too short, should be 3 character minimum.")
    .max(30, "Must be 30 characters or less")
    .strict(true)
    .trim("Space are not allowed")
    .required("Full name is required"),
  email: Yup.string()
    .matches(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Invalid email address"
    )
    .strict(true)
    .trim("Space are not allowed")

    .required("Email is required"),

  termsPermission: Yup.boolean()
    .required("Please Agree for Terms and conditions")
    .oneOf([true], "Please Agree for Terms and conditions"),
  password: Yup.string()
    .min(8, "")
    .strict(true)
    .trim("Space are not allowed")
    .matches(
      /^\S*$/,
      // ^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})
      "Space are not allowed"
    )
    .required(""),
  confirmPassword: Yup.string()
    .required("Confirm password is required")
    .strict(true)
    .trim("Space are not allowed")
    .matches(/^\S*$/, "Space are not allowed in Confirm password")
    .oneOf([Yup.ref("password")], "Both password need to be the same"),
});

export const ProfileSettingValidationSchema = Yup.object().shape({
  fullName: Yup.string()
    .min(3, "Fullname is too short, should be 3 character minimum.")
    .max(30, "Must be 30 characters or less")
    .strict(true)
    .trim("Space are not allowed")
    .required("Full name is required")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
  username: Yup.string()
    .matches(/^[a-zA-Z0-9._]+(?<![_.])/, "Special characters are not allowed")
    .matches(/^\S*$/, "Space are not allowed in username")
    .min(3, "Username is too short, should be 3 character minimum.")
    .max(20, "Username Must be 20 characters or less")
    .strict(true)
    .trim("Space are not allowed")
    .required("Username is required"),
  email: Yup.string()
    .email("Invalid email address")
    .strict(true)
    .trim("Space are not allowed")
    .required("Email is required"),
  bio: Yup.string()
    .min(10, "Minimum 10 characters are required")
    .max(200, "Maximum 200 characters are allowed"),
  phoneNo: Yup.string()
    .min(9, "Minimum 10 numbers are required")
    .max(15, "Maximum 15 numbers are allowed")
    .matches(
      /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g,
      "Invalid phone number"
    ),
  twitter: Yup.string().matches(
    /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    "Invalid Url"
  ),
  instagram: Yup.string().matches(
    /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    "Invalid Url"
  ),
});

export const resetPasswordValidations = Yup.object({
  password: Yup.string()
    .min(8, "")
    .strict(true)
    .trim("Space are not allowed")
    .matches(
      /^\S*$/,
      // ^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})
      "Space are not allowed"
    ),
  confirmPassword: Yup.string()
    .required("Confirm password is required.")
    .strict(true)
    .trim("Space are not allowed")
    .matches(/^\S*$/, "Space are not allowed in Confirm password")
    // .when("password",{
    // is: (val,f) => (val && val.length > 0 ? console.log(val,f) : false),
    // then: () => Yup.string()}
    .oneOf([Yup.ref("password")], "Both password need to be the same"),
});
export const forgetPassValidationSchema = Yup.object({
  email: Yup.string()
    .matches(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Invalid email address"
    )
    .strict(true)
    .trim("Space are not allowed")
    .required("Email is required"),
});

export const newsLetterValidation = Yup.object({
  email: Yup.string()
    .matches(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Invalid email address"
    )
    .strict(true)
    .trim("Space are not allowed")
    .required("Email is required"),
});
export const contactUsValidation = Yup.object({
  username: Yup.string()
    .min(2, "Too short!")
    .max(30, "Full Name must be smaller than 30 Characters!")
    .required("Name is required")
    .trim("white space is not allowed in Name")
    .strict(true)
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
  email: Yup.string()
    .email("Please Enter Valid email address")
    .strict(true)
    .trim("Space are not allowed")
    .required("Email is required"),
  message: Yup.string().trim().required("Required").min(1, "Required"),
});

export const fractionValidationSchema = Yup.object().shape({
  additionalFields: Yup.array().of(
    Yup.object().shape({
      value: Yup.string().required("Field is required"),
    })
  ),
  document: Yup.array().of(
    Yup.object().shape({
      url: Yup.string().required("Pdf document is required"),
    })
  ),

  fractionImgA: Yup.mixed()
    .required("Field is required")
    .test("filesize", "Max File size 5MB", function (value) {
      if (typeof value !== "string") {
        if (value.size <= maxFileSizeAllowed.IMAGE_LARGE) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    })
    .test("fileFormat", "Unsupported Formattt", function (value) {
      if (typeof value !== "string") {
        if (value && validmediaType.includes(value.type)) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    }),
  fractionImgB: Yup.mixed()
    .required("Field is required")
    .test("filesize", "Max File size 2MB", function (value) {
      if (typeof value !== "string") {
        if (value.size <= maxFileSizeAllowed.IMAGE_SMALL) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    })
    .test("fileFormat", "Unsupported Formattt", function (value) {
      if (typeof value !== "string") {
        if (value && validmediaType.includes(value.type)) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    }),
  fractionImgC: Yup.mixed()
    .required("Field is required")
    .test("filesize", "Max File size 20MB", function (value) {
      if (typeof value !== "string") {
        if (value.size <= maxFileSizeAllowed.IMAGE_SMALL) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    })
    .test("fileFormat", "Unsupported Formattt", function (value) {
      if (typeof value !== "string") {
        if (value && validmediaType.includes(value.type)) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    }),
  fractionImgD: Yup.mixed()
    .required("Field is required")
    .test("filesize", "Max File size 20MB", function (value) {
      if (typeof value !== "string") {
        if (value.size <= maxFileSizeAllowed.IMAGE_SMALL) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    })
    .test("fileFormat", "Unsupported Formattt", function (value) {
      if (typeof value !== "string") {
        if (value && validmediaType.includes(value.type)) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    }),
  fractionImgE: Yup.mixed()
    .required("Field is required")
    .test("filesize", "Max File size 20MB", function (value) {
      if (typeof value !== "string") {
        if (value.size <= maxFileSizeAllowed.IMAGE_SMALL) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    })
    .test("fileFormat", "Unsupported Formattt", function (value) {
      if (typeof value !== "string") {
        if (value && validmediaType.includes(value.type)) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    }),

  nameofAsset: Yup.string()
    .min(3, "NFT name is too short - should be 3 character minimum.")
    .max(20, "Must be 20 characters or less")
    .strict(true)
    .required("NFT name is required.")
    .matches(/^[aA-zZ0-9\s]+$/, "Only alphabets are allowed for this field"),
  // assetCategory: Yup.string().required("Field is required"),
  price: Yup.number()
    .nullable()
    .typeError("You must specify a number")
    .min(1, "Minimum Price 1 ")
    .max(50000000, "Max Price 50000000 ")
    .required("Price is required"),

  noOfFraction: Yup.number()
    .typeError("You must specify a number")
    .min(1, "Minimum 1 Token")
    .max(1000, "Maxium 1000 Token")
    .required("No of Fractions are required"),

  roi: Yup.number()
    .typeError("You must specify a number")
    .min(1, "Minimum 1")
    .max(1000, "Maxium 10000 Token")
    .required("ROI is required"),
  collection: Yup.string().required("Collection is required"),
  description: Yup.string()
    .min(20, "Minimum 20 character")
    .max(
      300,
      "Maximum character limit exceeded. Please enter only 300 characters."
    ),
});

export const createTicketValidation = Yup.object({
  title: Yup.string()
    .min(2, "Too short!")
    .max(30, "Title must be smaller than 30 Characters!")
    .required("Title is required")
    .trim("white space is not allowed in Title")
    .strict(true)
    .matches(
      /^[aA-zZ0-9\s]+$/,
      "Only alphanumerics are allowed for this field"
    ),
  description: Yup.string()
    .trim()
    .required("Description is Required")
    .min(3, "Description too short!")
    .max(1000, "Description too long!"),
});

export const SendTicketResponseValidation = Yup.object({
  message: Yup.string()
    .trim()
    .required("Please input your Reply!")
    .min(3, "Required")
    .max(1000, "Description too long!"),
});

export const dividendValidationSchema = Yup.object({
  amount: Yup.number()
    .typeError("You must specify a number")
    .min(1, "Minimum Price 1 XAR")
    .max(50000000, "Max Price 50000000 XAR")
    .required("Amount is required"),
});

// not in use

export const becomeVpvalidations = Yup.object().shape(
  {
    username: Yup.string()
      .trim("space is not allowed in username")
      .required("Username is required")
      .strict(true)
      .matches(/^\S*$/, "Space are not allowed in username"),
    name: Yup.string()
      .min(2, "Too short!")
      .max(50, "Too long!")
      .required("Name is required")
      .trim("space is not allowed in Name")
      .strict(true)
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
    mobile_no: Yup.string()
      .required("Phone number is required")
      .min(9, "Minimum 9 numbers are required")
      .max(15, "Maximum 15 numbers are allowed")
      .matches(
        /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g,
        "Invalid phone number"
      ),
    countryName: Yup.string().required("Country is required"),
    about_me: Yup.string()
      .required("Required")
      //  .strict(false)
      .strict(false)
      .max(500, "Maximum 500 characters are allowed")
      .trim(),
    // .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "), tester
    email: Yup.string().email().required("Email is required"),
    user_catagory: Yup.array()
      .required("Required")
      .min(1, "Atleast one type should be selected!")
      .max(4, "Maximum 4 type should be selected!"),

    twitterId: Yup.string()
      .matches(
        /(http(s)+:\/\/.)+(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
        "Invalid Url"
      )
      .when(["facebookId", "instagramId", "linkedinId"], {
        is: (facebookId, instagramId, linkedinId) =>
          !linkedinId && !instagramId && !facebookId,
        then: Yup.string().required("At least one social link is required"),
      }),

    instagramId: Yup.string()
      .matches(
        /(http(s)+:\/\/.)+(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
        "Invalid Url"
      )
      .when(["linkedinId", "facebookId", "twitterId"], {
        is: (linkedinId, facebookId, twitterId) =>
          !linkedinId && !facebookId && !twitterId,
        then: Yup.string().required("At least one social link is required"),
      }),

    linkedinId: Yup.string()
      .matches(
        /(http(s)+:\/\/.)+(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
        "Invalid Url"
      )
      .when(["facebookId", "instagramId", "twitterId"], {
        is: (facebookId, instagramId, twitterId) =>
          !facebookId && !instagramId && !twitterId,
        then: Yup.string().required("At least one social link is required"),
      }),

    facebookId: Yup.string()
      .matches(
        /(http(s)+:\/\/.)+(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
        "Invalid Url"
      )
      .when(["linkedinId", "instagramId", "twitterId"], {
        is: (linkedinId, instagramId, twitterId) =>
          !linkedinId && !instagramId && !twitterId,
        then: Yup.string().required("At least one social link is required"),
      }),
  },
  [
    ["twitterId", "facebookId"],
    ["twitterId", "linkedinId"],
    ["twitterId", "instagramId"],
    ["facebookId", "instagramId"],
    ["linkedinId", "facebookId"],
    ["linkedinId", "instagramId"],
  ]
);

export const createCollectionValidation = Yup.object({
  name: Yup.string()
    .trim("White space is not allowed")
    .strict(true)
    .max(50, "Must be 50 characters or less")
    .min(3, "The name needs to be at least 3 char")
    .required("Collection name is required"),
  description: Yup.string()
    .trim("White space is not allowed")
    .strict(true)
    .required("Collection description is required"),
  logo: Yup.string().required("Collection logo is required"),
});

export const createNftValidation = Yup.object({
  logo: Yup.mixed().required("Image is required"),
  name: Yup.string()
    .strict(false)
    .trim()
    .max(50, "Must be 50 characters or less")
    .required("Name is required"),
  description: Yup.string()
    .strict(false)
    .trim()
    .max(500, "Must be 500 characters or less")
    .required("Description is required"),
  price: Yup.number()
    .typeError("You must specify a number")
    .min(0, "Min value 0.")
    .moreThan(0)
    .positive()
    .max(100000000000000000000, "Max value 100000000000000000000.")
    // .matches(/^\d+(\.\d{1,2})?$/, "Only two values are allowed after decimal ")
    .required("Price is required"),
  location: Yup.object().shape({
    city: Yup.string().required("Location is required"),
    state: Yup.string().required("Location is required"),
    country: Yup.string().required("Location is required"),
    lat: Yup.number().required("Location is required"),
    long: Yup.number().required("Location is required"),
  }),
  royalty: Yup.number()
    .typeError("You must specify a number")
    .min(1, "Minimum value of royality is  greater than 1%")
    .max(20, "Max value of Royalty fee is 20%")
    .required("Royalty fee is required"),
  copies: Yup.number()
    .min(1, "Number of editions is Required")
    .max(10, "Max number of editions is 10")
    .typeError("You must specify a number")
    .test("Decimal-check", "No decimal allowed", function (value) {
      if (value) {
        let abc = JSON.stringify(value);
        if (abc?.indexOf(".") > -1) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    })
    .required("Editions fee is required"),
});

export const walletWithdrawValidation = Yup.object({
  amount: Yup.number()
    .typeError("You must specify a number")
    .required("Amount is required!"),
  address: Yup.string().trim().required("Address is required!"),
});

export const passwordUpdateValidations = Yup.object({
  oldPassword: Yup.string().required("Old password is required"),
  newPassword: Yup.string()
    .min(8, "Password is too short - should be 8 alphanumeric minimum.")
    .strict(false)
    .trim()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Password must contain 8 characters, one uppercase, one lowercase, one number and one special case character"
    )
    .required("New password is required")
    .max(30, "Password is too short - should be 30 alphanumeric character."),
  confirmPassword: Yup.string()
    .required("Confirm password is required")
    .when("newPassword", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("newPassword")],
        "New password and confirm new password must be same."
      ),
    }),
});

export const withdrawValidation = Yup.object({
  address: Yup.string()
    .required("Address is required")
    .max(51, "Invalid Address")
    .min(51, "Invalid Address"),
});
