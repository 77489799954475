import React, { useEffect, useState } from "react";
import styles from "./SignUpModal.module.scss";
import { Form } from "react-bootstrap";
import PasswordChecklist from "react-password-checklist";
import logo from "../../../assets/images/logo.png";
import CommonBtn from "../CommonBtn/CommonBtn";
import CommonModal from "../CommonModal/CommonModal";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { SignUpValidationSchema } from "../../../features/Validation/YupValidations";
import { SignUpFormFields } from "../../../features/FormFields/FormFields";
import { apiCallGet, apiCallPost } from "../../../features/Axios/Axios";
import APIURLS from "../../../features/ApiPath/ApiPath";
import PasswordInput from "../passwordinput/PasswordInput";

const SignUpModal = ({
  show,
  onHide,
  className,
  signInTrigger,
  setSignup,
  setUserEmail,
  setVerifyPass,
}) => {
  const [signupLoader, setSignupLoader] = useState(false);
  const [uniqUsername, setUniqUsername] = useState({
    UserMessage: "",
    UserError: false,
  });

  const formik = useFormik({
    initialValues: {
      fullName: "",
      userName: "",
      email: "",
      password: "",
      confirmPassword: "",
      termsPermission: true,
    },
    validationSchema: SignUpValidationSchema,
    onSubmit: async (values) => {
      setSignupLoader(true);
      try {
        const res = await apiCallPost(
          `${APIURLS.USER.SIGNUP}`,
          { ...values },
          {},
          "toastOn"
        );
        if (res) {
          setUserEmail(res?.data);
          setSignup(false);
          setVerifyPass(true);
          setSignupLoader(false);
        }
      } catch (error) {
        setSignupLoader(false);
      }
    },
  });

  useEffect(() => {
    const finder = setTimeout(async () => {
      if (formik.values.userName && formik.values.userName.length >= 4) {
        try {
          const res = await apiCallGet(
            `${APIURLS.USER.FIND_USERNAME}/${formik.values.userName}`
          );
          if (res) {
            setUniqUsername({ UserMessage: res?.message, UserError: false });
          }
        } catch (error) {
          setUniqUsername({
            UserMessage: error?.message || "",
            UserError: true,
          });
        }
      }
    }, [500]);
    return () => {
      clearTimeout(finder);
    };
  }, [formik.values.userName]);

  return (
    <>
      <CommonModal
        show={show}
        onHide={onHide}
        className={`${className} ${styles.signup_modal}`}
        subTitle="Sign Up"
        modal
        modalTitle={
          <>
            <div className="d-flex justify-content-between">
              <img src={logo} alt="" />
            </div>
          </>
        }
      >
        <Form onSubmit={formik.handleSubmit}>
          {SignUpFormFields?.map(
            ({ label, type, placeholder, name }, index) => (
              <Form.Group className="mb-3" key={index}>
                <Form.Label className="mt-3">{label}</Form.Label>
                {type !== "password" ? (
                  <Form.Control
                    type={type}
                    placeholder={placeholder}
                    id={name}
                    name={name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                  />
                ) : (
                  <PasswordInput
                    placeholder={placeholder}
                    id={name}
                    name={name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values[name]}
                  />
                )}
                {name === "password" && formik.touched[name] && (
                  <PasswordChecklist
                    className="PasswordCheckList"
                    rules={[
                      "maxLength",
                      "minLength",
                      "specialChar",
                      "number",
                      "capital",
                      "lowercase",
                    ]}
                    maxLength={15}
                    minLength={8}
                    value={formik.values.password}
                  />
                )}

                {!formik.errors.userName &&
                  name === "userName" &&
                  formik.values.userName && (
                    <div
                      className={`error_field ${
                        uniqUsername.UserError ? "" : "success"
                      }`}
                    >
                      <span>{uniqUsername.UserMessage}</span>
                    </div>
                  )}

                {formik.touched[name] && formik.errors[name] && (
                  <div className="error_field">
                    <span>{formik.errors[name]}</span>
                  </div>
                )}
              </Form.Group>
            )
          )}
          <Form.Group>
            <Form.Check
              type="checkbox"
              id="termsPermission"
              name="termsPermission"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              checked={
                formik.values.termsPermission === true ? "checked" : null
              }
              value={formik.values.termsPermission}
              label={
                <div className="confirm_sec">
                  <p className="p-0 ms-3">
                    I Agree <Link to="#">Terms & Conditions</Link> And
                    <Link to="#"> Privacy Policy</Link>
                  </p>
                </div>
              }
            />
            {formik.touched.termsPermission &&
              formik.errors.termsPermission && (
                <div className="error_field">
                  <span>{formik.errors.termsPermission}</span>
                </div>
              )}
          </Form.Group>
          <CommonBtn
            disabled={!formik.isValid}
            type="submit"
            className="mt-3 w-100"
            title="CONTINUE"
            loader={signupLoader}
          />
        </Form>
        <div className="signup_note">
          <p>
            Already A Member?{" "}
            <Link to="#" onClick={signInTrigger}>
              Sign In
            </Link>
          </p>
        </div>
      </CommonModal>
    </>
  );
};

export default SignUpModal;
