import APIURLS from "../ApiPath/ApiPath";
import { apiCallDelete, apiCallPost, apiCallPut } from "../Axios/Axios";

export const getNotifications = async (
  setLoading,
  ReadOrUnReadType,
  searchTerm,
  page = 1
) => {
  setLoading(true);

  try {
    const res = await apiCallPut(
      `${APIURLS.NOTIFICATION.GET_NOTIFICATION}10/${page}/
      ${ReadOrUnReadType}`,
      {
        search: searchTerm,
      }
    );
    setLoading(false);
    return res?.data;
  } catch (error) {
    console.log(error);
    setLoading(false);
  }
};
export const MarkReadNoftification = async (id) => {
  try {
    await apiCallPut(`${APIURLS.NOTIFICATION.MARK_READ}${id}`);
  } catch (error) {}
};
export const MarkAllReadNoftification = async () => {
  try {
    const res = await apiCallPut(APIURLS.NOTIFICATION.MARK_READ_ALL);
  } catch (error) {}
};
export const deleteNoftification = async (id) => {
  try {
    const res = await apiCallDelete(`${APIURLS.NOTIFICATION.DELETE}${id}`);
  } catch (error) {}
};
export const deleteAllNoftification = async (type) => {
  try {
    const res = await apiCallDelete(
      `${APIURLS.NOTIFICATION.DELETE_ALL}${type}`
    );
  } catch (error) {}
};
export const makeNftFavourite = async (action, id) => {
  try {
    const res = await apiCallPost(
      `${APIURLS.NFT.NFT_FAVOURITE}`,
      {
        nftId: id,
        isFavourite: action,
      },
      {}
    );
  } catch (error) {}
};
export const makeCollectionFavourite = async (action, id) => {
  try {
    const res = await apiCallPost(
      `${APIURLS.COLLECTION.COLLECTION_FAVOURITE}`,
      {
        collectionId: id,
        isFavourite: action,
      },
      {}
    );
  } catch (error) {
    console.log(error);
  }
};
