import React, { useDeferredValue, useEffect, useState } from "react";
import styles from "./Activity.module.scss";
import { Container, Row, Col, Pagination } from "react-bootstrap";
import CommonSelectbox from "../../components/ui/Dropdowns/CommonSelectbox";
import {
  BUYICON,
  CARTICON,
  DIVIDENDICON,
  GOTOPAGEICON,
  LISTICON,
  MINTICON,
  SendIcon,
  SortIcon,
} from "../../assets/svgIcons/SVGicon";
import CommonSearch from "../../components/ui/CommonSearch/CommonSearch";
import CommonTable from "../../components/ui/CommonTable/CommonTable";
import { Link } from "react-router-dom";

import PrevImg from "../profilesettings/PrevImg";
import { apiCallGet, apiCallPost } from "../../features/Axios/Axios";
import APIURLS from "../../features/ApiPath/ApiPath";
import {
  formatDate,
  isArrayEmpty,
  limitPerPage,
  openEtherScan,
  trimText,
} from "../../features/Utility/Utility";
import PaginationComponent from "../../components/common/pagination/PaginationComponent";
import useDebounce from "../../hooks/useDebounce";
import {
  FILTER_ACTIVITY,
  FILTER_PROFILE_FRACTION_SORT,
} from "../../features/Constant/Constant";
import moment from "moment";

const Activity = () => {
  const [exploreActivity, setExploreActivity] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [filterValue, setFilterValue] = useState(FILTER_ACTIVITY[0].value);
  const [sortValue, setSortValue] = useState();
  const debounce = useDebounce(searchValue);

  const getAllActivities = async () => {
    try {
      const { data } = await apiCallPost(
        `${APIURLS.NFT.GET_ALL_EXPLORE_ACTIVITY}/${currentPage}/${limitPerPage}`,
        { order: sortValue, filter: filterValue, search: debounce }
      );
      setExploreActivity(data);
      console.log("exploreActivity", data);
    } catch (error) {
      console.log("exploreActivity ==>", error);
    }
  };

  useEffect(() => {
    getAllActivities();
  }, [currentPage, debounce, sortValue, filterValue]);

  return (
    <>
      <section className={styles.activity_sec}>
        <Container>
          <div className={styles.activity_sec_head}>
            <h2 className="text-white mb-0">Activity</h2>
            <div className={styles.hl} />
          </div>
          <Row className="mb-4">
            <Col md={6}>
              <CommonSearch
                className={styles.activity_sec_search}
                placeholder="Search..."
                searchvalue={searchValue}
                setSearchvalue={setSearchValue}
                setCurrentPage={setCurrentPage}
              />
            </Col>
            <Col md={6}>
              <div className={`text-md-end ${styles.activity_sec_act}`}>
                <CommonSelectbox
                  icon={<SortIcon />}
                  title="ALL"
                  type="filter"
                  options={FILTER_ACTIVITY}
                  className="me-lg-5 me-2"
                  setFilterValue={setFilterValue}
                  setCurrentPage={setCurrentPage}
                />
                <CommonSelectbox
                  icon={<SortIcon />}
                  title="Newest"
                  type="filter"
                  options={FILTER_PROFILE_FRACTION_SORT}
                  setFilterValue={setSortValue}
                  className="ms-lg-5 ms-2"
                  setCurrentPage={setCurrentPage}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <CommonTable
                isDataEmpty={isArrayEmpty(exploreActivity?.count)}
                className={styles.activity_sec_table}
              >
                <thead>
                  <tr>
                    <th>NFTs</th>
                    <th>Txn Hash</th>
                    <th>Event</th>
                    <th>Price (XAR)</th>
                    <th>Fractions</th>
                    <th>From</th>
                    <th>To</th>
                    <th>Date</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {exploreActivity &&
                    exploreActivity?.rows?.map((item, index) => {
                      const price = +item?.price;

                      const transactionText = (
                        <p>
                          {item?.type} <br />
                          <small
                            style={{
                              color: "#d59111",
                              display: "block",
                              marginTop: -5,
                              fontSize: 14,
                            }}
                          >
                            (in-process)
                          </small>
                        </p>
                      );
                      return (
                        <tr key={index}>
                          <td>{item?.title}</td>
                          <td>
                            <a
                              style={{ cursor: "pointer" }}
                              title={item?.txnHash}
                              onClick={() => openEtherScan(item?.txnHash)}
                              target="_blank"
                            >
                              {trimText(item?.txnHash)}
                            </a>
                          </td>
                          <td>
                            {item.type === "Sale" && (
                              <>
                                <CARTICON />
                                {item.type}
                              </>
                            )}
                            {item.type === "Dividend" && (
                              <>
                                <DIVIDENDICON /> {item.type}
                              </>
                            )}
                            {item.type === "List" && (
                              <>
                                <LISTICON /> {item.type}
                              </>
                            )}
                            {item.type === "Mint" && (
                              <>
                                <MINTICON /> {item.type}
                              </>
                            )}
                            {item.type === "Buy" && (
                              <>
                                <BUYICON /> {item.type}
                              </>
                            )}
                            {item.type === "Transaction" && transactionText}
                          </td>
                          <td>{price.toFixed(4)}</td>
                          <td>{item?.quantity}</td>
                          <td>{trimText(item?.fromAddress)}</td>
                          <td>{trimText(item?.toAddress)}</td>
                          <td>{formatDate(item?.createdAt)}</td>
                          <td>
                            {item?.status && (
                              <Link
                                style={{ color: "#fff" }}
                                to={`/marketplace/nft/${item.nftId}`}
                              >
                                {item?.status}
                                <span
                                  style={{
                                    display: "inline-block",
                                    marginLeft: 5,
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <GOTOPAGEICON />
                                </span>
                              </Link>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </CommonTable>

              <PaginationComponent
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                requestPerPage={limitPerPage}
                totalCount={exploreActivity?.count}
              />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Activity;
