import React from "react";
import { Link } from "react-router-dom";
import styles from "./CategoryCard.module.scss";

const CategoryCard = ({ className, title, img, to, onClick }) => {
  return (
    <>
      <div onClick={onClick} className={`${styles.category_card} ${className}`}>
        <div className={styles.category_card_thumb}>
          <img src={img} alt="" />
        </div>
        <h5>{title}</h5>
      </div>
    </>
  );
};

export default CategoryCard;
