import React from "react";
import "react-perfect-scrollbar/dist/css/styles.css";
import Routers from "./Routers";

function App() {
  return (
    <div className="App">
      <Routers />
    </div>
  );
}
export default App;
