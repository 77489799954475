import React from "react";
import { Container } from "react-bootstrap";
import styles from "./TrendingNFTs.module.scss";
import CommonCard from "../../../components/ui/CommonCards/CommonCard/CommonCard";
import Slider from "react-slick";

const TrendingNFTs = ({ data }) => {
  const settings = {
    dots: false,
    infinite: data?.length > 4 ? true : false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    pauseOnHover: false,
    pauseOnFocus: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <section className={styles.trending_nft}>
        <Container>
          <div className={styles.trending_nft_head}>
            <h2>Top Trending NFTs</h2>
            <p>
              Don't bother typing “lorem ipsum” into Google translate. If you
              already tried, you may have gotten anything from "NATO" to
              "China", depending on how you capitalized the letters. The bizarre
              translation was fodder for conspiracy theories.
            </p>
          </div>
          {data && (
            <Slider {...settings}>
              {data?.map((item, index) => {
                console.log({ item });
                return (
                  <CommonCard
                    img={item.image}
                    title={item.title}
                    to={`marketplace/nft/${item.nftId}`}
                    subtitle={item?.collectionTitle}
                    price={item.price}
                    fractions={item.quantity}
                    key={item?.nftId}
                    nftId={item?.nftId}
                    btnTitle="BUY"
                    isFavourite={item?.isFavourite}
                  />
                );
              })}
            </Slider>
          )}
        </Container>
      </section>
    </>
  );
};
export default TrendingNFTs;
