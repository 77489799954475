import React, { useEffect, useState } from "react";
import styles from "./CustomerSupport.module.scss";
import { Col, Container, Row } from "react-bootstrap";
import CommonBtn from "../../components/ui/CommonBtn/CommonBtn";
import CommonSelectbox from "../../components/ui/Dropdowns/CommonSelectbox";
import { TicketIcon } from "../../assets/svgIcons/SVGicon";
import SimpleBtn from "../../components/ui/SimpleBtn/SimpleBtn";
import APIURLS from "../../features/ApiPath/ApiPath";
import { apiCallPost } from "../../features/Axios/Axios";
import { useNavigate } from "react-router-dom";
import CreateTicketModal from "../../components/ui/CreateTicketModal/CreateTicketModal";
import NoResult from "../../components/ui/NoResult/NoResult";

const CustomerSupport = () => {
  const [allTickets, setAllTickets] = useState([]);
  const [ticketStatus, setTicketStatus] = useState("");
  const navigate = useNavigate();

  const allTicketsData = async () => {
    try {
      const res = await apiCallPost(APIURLS.SUPPORT.ALL_TICKETS, {
        status: ticketStatus,
      });
      if (res) {
        setAllTickets(res?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    allTicketsData();
  }, [ticketStatus]);

  const [show, setShow] = useState(false);
  const OnHide = () => setShow(false);
  const handleShow = () => setShow(true);

  const optionsTwo = [
    { menu: "All Tickets", value: "",to: "#" },
    { menu: "Open Tickets", value: "open",to: "#" },
    { menu: "Resolved Tickets", value: "resolved",to: "#" },
    { menu: "Closed Tickets", value: "closed",to: "#" },
  ];
  const tikButtonClass = (status) => {
    if (status === "open") {
      return "btn-bordered-yellow";
    } else if (status === "resolved") {
      return "btn-bordered-green";
    } else {
      return "btn-bordered-red";
    }
  };
  return (
    <>
      <section className={styles.support}>
        <Container>
          <Row>
            <Col>
              <h2>Customer Support</h2>
            </Col>
            <Col>
              <div className={styles.support_right}>
                <CommonSelectbox
                  icon={<TicketIcon />}
                  title="All Tickets"
                  options={optionsTwo}
                  className="ms-lg-5 ms-2 mt-sm-0 mt-3 customer-all-ticket"
                  setTicketStatus={setTicketStatus}
                  type="tickets"
                />
                <SimpleBtn
                  className={styles.support_right_simple_btn}
                  title="Create Ticket"
                  onClick={handleShow}
                />
                <CreateTicketModal
                  show={show}
                  handleShow={handleShow}
                  onHide={OnHide}
                  allTicketsData={allTicketsData}
                />
              </div>
            </Col>
          </Row>
          <Row>
            {allTickets?.length > 0 ? (
              allTickets?.map((item) => (
                <Col sm={12} md={6} lg={6} key={item.supportId}>
                  <div
                    className={styles.support_sec}
                    onClick={() => {
                      navigate(`/customer-support/${item.supportId}`);
                    }}
                  >
                    <div className={styles.support_sec_content}>
                      <div>
                        <h6>{item.title}</h6>
                        <p>{item.ticketId}</p>
                      </div>
                      <CommonBtn
                        to={item.supportId}
                        className={tikButtonClass(item.status)}
                        title={item.status}
                      />
                    </div>
                  </div>
                </Col>
              ))
            ) : (
              <NoResult />
            )}
          </Row>
        </Container>
      </section>
    </>
  );
};

export default CustomerSupport;
