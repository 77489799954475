import React, { useState } from "react"
import { Button } from "react-bootstrap"
import {
  HeartBlankFilledIcon,
  HeartBlankIcon,
  OpenEyeIcon,
} from "../../../assets/svgIcons/SVGicon"
import CommonBtn from "../../../components/ui/CommonBtn/CommonBtn"
import styles from "./DetailPage.module.scss"
import { makeNftFavourite } from "../../../features/serives/services"
import { useDispatch, useSelector } from "react-redux"
import { setShowLogInLogInModal } from "../../../Redux/Reducers/user"

const DetailsSec = ({
  title,
  collection,
  category,
  description,
  viewCount,
  favouriteCount,
  isFavourite,
  price,
  nftId,
  nftDetails,
  listingData,
  onClick,
  lastTradePrice,
}) => {
  const dispatch = useDispatch()
  const { userLogin } = useSelector((state) => state.user)
  const userWallets = useSelector((state) => state.user?.userData?.userWallets)
  const [isfav, setisFav] = useState(isFavourite === 1 ? true : false)
  const [latestFavCount, setLatestFavCount] = useState(favouriteCount)

  const likehandle = () => {
    if (!userLogin) {
      dispatch(setShowLogInLogInModal(true))
      return
    }
    try {
      makeNftFavourite(!isfav, nftId)
      setLatestFavCount((prevCount) => (isfav ? prevCount - 1 : prevCount + 1))
      setisFav((prevState) => !prevState)
    } catch (error) {}
  }
  console.log(
    "happy",
    listingData?.rows,
    listingData?.rows?.length === 0,
    listingData?.rows?.some((item) => item.wallet === userWallets),
    userWallets
  )
  return (
    <>
      <div className={styles.detailpage_inner}>
        <div className={styles.detailpage_inner_head}>
          <h2>{title}</h2>
          <div className={styles.detailpage_inner_head_info}>
            <Button className={styles.detailpage_inner_head_info_feed}>
              <OpenEyeIcon />
              <p>{Number(viewCount)}</p>
            </Button>
            <Button
              className={styles.detailpage_inner_head_info_feed}
              onClick={likehandle}>
              {isfav ? <HeartBlankFilledIcon /> : <HeartBlankIcon />}
              <p>{latestFavCount}</p>
            </Button>
            {/* <div className={styles.dot_area}> */}
            {/* <CommonSelectbox className={styles.dot_drop} icon={<DotsIcon />} options={optionsOne} /> */}
            {/* 
              <Dropdown className={styles.dot_drop}>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  <DotsIcon />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="#/action-1">
                    View On Blockchain
                  </Dropdown.Item>
                  <Dropdown.Item href="#/action-2">Report</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown> */}
            {/* </div> */}
          </div>
        </div>
        <div className={styles.detailpage_inner_info}>
          <div>
            <p>Collection</p>
            <h3>{collection?.title}</h3>
          </div>
          <div className={styles.vl} />
          <div>
            <p>Category</p>
            <h3>{category?.categoryName}</h3>
          </div>
        </div>
        <div className={styles.detailpage_inner_data}>
          <p>{description}</p>
          <ul className={styles.detailpage_inner_data_price}>
            <li>
              <p className="mb-0">Last Traded Price</p>
              <h4>$ {lastTradePrice}</h4>
            </li>
            <li>
              <p className="mb-0">Initial Price</p>
              <h4>$ {price}</h4>
            </li>
          </ul>

          <CommonBtn
            title={"Buy"}
            className="btn-bordered w-100 my-4"
            onClick={onClick}
            disabled={
              listingData?.rows?.length === 0 ||
              listingData?.rows?.some((item) => item.wallet === userWallets)
            }
          />
        </div>
      </div>
    </>
  )
}

export default DetailsSec
