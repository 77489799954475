import React from "react";
import { useState } from "react";

const ProfilePrev = ({ file, className = "" }) => {
  const [preview, setPreview] = useState("");

  const acceptedFromat = ["image/jpeg", "image/jpg", "image/png", "image/webp"];

  if (typeof file !== "string") {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => {
      if (acceptedFromat.includes(file.type)) {
        setPreview(reader.result);
      }
    };
  }

  return (
    <>
      <img
        src={typeof file === "string" ? file : preview}
        className={`profileImg ${className}`}
      />
    </>
  );
};

export default ProfilePrev;
