import React from "react"
import { Col } from "react-bootstrap"
import styles from "../marketplace.module.scss"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"

const CollectionSkelton = () => {
  return (
    <>
      {[...Array(4)].map((_, index) => (
        <Col xs={12} sm={6} lg={4} xl={3} className="mt-5" key={index}>
          <SkeletonTheme baseColor="#202020" highlightColor="#444">
            <Skeleton
              count={1}
              height={275}
              style={{
                borderTopLeftRadius: "2.5rem",
                borderTopRightRadius: "2.5rem",
              }}
            />
            <div className={`${styles.common_card}`}>
              <a className={styles.common_card_thumb}>
                <Skeleton count={1} />
              </a>
              <div className={styles.common_card_head}>
                <h6>
                  <Skeleton count={1} />
                </h6>
                <p>
                  <Skeleton count={1} />
                </p>
              </div>
            </div>
          </SkeletonTheme>
        </Col>
      ))}
    </>
  )
}

export default CollectionSkelton
