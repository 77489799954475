import React, { createRef, useEffect, useState } from "react";
import Web3 from "web3";
import {
  Container,
  Navbar,
  Nav,
  NavDropdown,
  Dropdown,
  Badge,
} from "react-bootstrap";
import {
  Link,
  NavLink,
  useHref,
  useLocation,
  useNavigate,
} from "react-router-dom";
import styles from "./Header.module.scss";
import Logo from "../../../assets/images/logo.png";
import {
  BellIcon,
  CopyIcon,
  Disconnect,
  LoginIcon,
  MyItem,
  MyProfile,
  SignUpIcon,
  WalletIcon,
} from "../../../assets/svgIcons/SVGicon";
import NotificationBox from "./notificationBox/NotificationBox";
import CommonBtn from "../../ui/CommonBtn/CommonBtn";
import user from "../../../assets/images/xegara_icon_img.png";
import SignUpModal from "../../ui/SignUpModal/SignUpModal";
import SignInModal from "../../ui/SignInModal/SignInModal";
import ForgotPassword from "../../ui/ForgotPassword/ForgotPassword";
import CreateNewModal from "../../ui/CreatNewModal/CreatNewModal";
import VerificationModal from "../../ui/VerificationModal/VerificationModal";
import { useDispatch, useSelector } from "react-redux";
import {
  resetUser,
  setShowLogInLogInModal,
  setUserLogin,
} from "../../../Redux/Reducers/user";
import { resetWallet, setWalletBalance } from "../../../Redux/Reducers/wallet";
import {
  getProfile,
  handleCopy,
  imgKitFunction,
} from "../../../features/Utility/Utility";
import { toasts } from "../Toast/Toast";
import WalletModal from "../../ui/WalletModal/WalletModal";
import ethProviders from "../../../features/web3/EthProviders";
import socket from "../../../Socket/Socket";
import useCheckInKYC from "../../../hooks/useCheckInKYC";
import {
  GLOBAL_SEARCH_PATH,
  chainId,
} from "../../../features/Constant/Constant";
import {
  prefetchNotifications,
  setunReadNotificationsFromSocket,
} from "../../../Redux/Reducers/notifications";
import GlobalSearchComponent from "../../ui/CommonSearch/GlobalSearchComponent";
import { useGlobalSearchContext } from "../../../context/GlobalSearchContext";
import { store } from "../../../Redux/Store";
import ConfirmModal from "../../ui/ConfirmModal/ConfirmModal";
import { apiCallPost } from "../../../features/Axios/Axios";
import APIURLS from "../../../features/ApiPath/ApiPath";

const Header = () => {
  const { pathname } = useLocation();

  /* -----------------------------Hooks---------------------------------------*/
  const { globalSearchTerm } = useGlobalSearchContext();
  const dispatch = useDispatch();
  //  Dont remove this line of code its helping to load CheckIn KYC Module
  const chcekin = useCheckInKYC();
  //  Dont remove this line of code its helping to load CheckIn KYC Module
  const navigate = useNavigate();
  const param = useHref();
  const login = useSelector((state) => state.user.showLogInLogInModal);

  const setLogin = (actionPayload) => {
    dispatch(setShowLogInLogInModal(actionPayload));
  };
  // header triggers
  /* ----------------------------States----------------------------------------*/
  const [backdrop, setBackdrop] = useState(false);
  const toggleBtn = createRef();
  const [small, setSmall] = useState(false);
  const { state } = useLocation();
  /* ----------------------------Modals states--------------------------------------*/
  const [signup, setSignup] = useState(false);
  const [forgotShow, setForgotShow] = useState(false);
  const [newPass, setNewPass] = useState(false);
  const [verifyPass, setVerifyPass] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [resetPassOtp, setResetPassOtp] = useState(0);
  const [resetOtp, setResetOtp] = useState(false);
  const [walletModalShow, setWalletModalShow] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [confirmModalLoader, setConfirmModalLoader] = useState(false);
  const [notificationDrop, setNotificationDrop] = useState(false);
  /* ---------------------------UseSelector-------------------------------------*/
  const count = useSelector(
    (state) => state?.notification?.allNotifications?.count
  );
  const userWallet = useSelector((state) => state?.user?.userData?.userWallets);
  const userLogin = useSelector((state) => state?.user?.userLogin);
  const userInfo = useSelector((state) => state?.user?.userData);
  const walletAddress = useSelector((state) => state?.wallet?.walletAddress);
  const expireToken = useSelector((state) => state?.user?.tokenExpires);
  const balance = useSelector((state) => state?.wallet?.walletBalance);
  /* ---------------------------Functions-------------------------------------*/
  const handleSidebar = () => {
    if (document.body.clientWidth < 991) {
      toggleBtn.current.click();
    }
  };
  const disconnectHandler = async () => {
    dispatch(setUserLogin(false));
    dispatch(resetWallet());
    dispatch(resetUser());
    localStorage.clear();
    navigate("");
  };
  const handleDisconnect = async (accounts) => {
    if (accounts.length > 0) {
      if (accounts[0] === walletAddress) {
        return;
      }
      if (walletAddress !== "" && accounts[0] !== walletAddress) {
        return toasts.warn(`Please Connect With ${walletAddress} Address`);
      }
    }
    if (accounts.length === 0) {
      dispatch(resetWallet());
      toasts.warn("Please connect a wallet...");
    }
  };
  const userBalance = async () => {
    const balance = await ethProviders.getBalance(walletAddress);
    if (balance) {
      dispatch(setWalletBalance(balance));
    }
  };
  async function handleChainChanged(chainIds) {
    const web3 = new Web3(window.ethereum);
    console.log({ chainIds });
    if (chainIds !== chainId) {
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: web3?.utils?.toHex(chainId) }],
        });
      } catch (error) {}
    }
  }

  /* ---------------------------UseEffects-------------------------------------*/
  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", handleDisconnect);
      window.ethereum.on("chainChanged", handleChainChanged);
    }
    return () => {
      if (window.ethereum && window.ethereum.removeListener) {
        window.ethereum.removeListener("accountsChanged", handleDisconnect);
      }
    };
  }, []);
  useEffect(() => {
    expireToken && disconnectHandler();
  }, [expireToken]);
  useEffect(() => {
    if (userLogin) {
      getProfile();
      store.dispatch(prefetchNotifications());
      walletAddress && userBalance();
    }
  }, [userLogin, param, walletAddress]);
  useEffect(() => {
    if (userLogin && userInfo) {
      socket.emit("EMIT_JOIN", { _id: userInfo?.userId });
      socket.on("LISTEN_NOTIFY", (data) => {
        dispatch(setunReadNotificationsFromSocket(data));
      });
    }
  }, [userLogin]);
  const handleGlobalSearch = () => {
    if (
      globalSearchTerm.length > 0 &&
      pathname !== GLOBAL_SEARCH_PATH.COLLECTION &&
      pathname !== GLOBAL_SEARCH_PATH.NFT
    ) {
      navigate("/search/collections");
    }
  };
  useEffect(() => {
    handleGlobalSearch();
  }, [globalSearchTerm]);
  const updateWalletRequste = async () => {
    setConfirmModalLoader(true);
    try {
      const res = await apiCallPost(
        APIURLS.WALLET.UPDATE_WALLET,
        { address: userWallet },
        {},
        "toastOn"
      );
      if (res) {
        setConfirmModalLoader(false);
        setConfirmModal(false);
      }
    } catch (error) {
      setConfirmModalLoader(false);
      setConfirmModal(false);
      setWalletModalShow(true);
    }
  };
  return (
    <>
      <div
        onClick={handleSidebar}
        className={`${styles.headerBackdrop} d-lg-none ${
          backdrop ? styles.active : ""
        }`}
      />
      <header className={`${styles.header} ${small ? "tiny" : "static"}`}>
        <Navbar
          className={`py-0 ${styles.header_navbar} navbar_dp`}
          expand="lg">
          <Container>
            <div className={styles.header_left}>
              <Link className={styles.header_logo} to="/">
                <img src={Logo} alt="Logo" />
              </Link>
            </div>
            <div className={styles.header_right}>
              <GlobalSearchComponent className={styles.header_search} />
              <Navbar.Collapse
                id="basic-navbar-nav"
                className={styles.header_navbarCollapse}>
                <Nav className={` ${styles.header_nav}`}>
                  <NavDropdown
                    title="Marketplace"
                    className={styles.header_dropDown}
                    renderMenuOnMount={true}>
                    <NavLink
                      className={`dropdown-item ${styles.header_dropDownItem}`}
                      to="/marketplace/collections"
                      onClick={handleSidebar}>
                      Collections
                    </NavLink>
                    <NavLink
                      className={`dropdown-item ${styles.header_dropDownItem}`}
                      to="/marketplace/nft"
                      onClick={handleSidebar}>
                      NFTs
                    </NavLink>
                  </NavDropdown>
                  {userLogin &&
                    userInfo?.isKycDone &&
                    userInfo?.userKyc?.kycStatus === "APPROVED" && (
                      <NavLink
                        onClick={(e) =>
                          state?.status === "PENDING" && e.preventDefault()
                        }
                        className={`nav-link ${styles.header_navlink}`}
                        to={`/fractionalize `}>
                        Fractionalize
                      </NavLink>
                    )}
                  <NavLink
                    className={`nav-link ${styles.header_navlink}`}
                    to="/activity">
                    Activity
                  </NavLink>
                </Nav>
              </Navbar.Collapse>
              <div className={styles.header_action}>
                {userLogin && (
                  <Dropdown
                    className={styles.header_notification_dropDown}
                    show={notificationDrop}
                    onToggle={() => {
                      setNotificationDrop(!notificationDrop);
                    }}>
                    {(count || 0) > 0 && <Badge>{count}</Badge>}
                    <Dropdown.Toggle>
                      <BellIcon />
                    </Dropdown.Toggle>
                    {notificationDrop && (
                      <Dropdown.Menu>
                        <NotificationBox
                          onClick={() => setNotificationDrop(!notificationDrop)}
                        />
                      </Dropdown.Menu>
                    )}
                  </Dropdown>
                )}
                {userLogin ? (
                  <>
                    <Dropdown className={styles.header_userDropdown}>
                      <Dropdown.Toggle>
                        {imgKitFunction(
                          userInfo?.userMeta?.profileImage || user,
                          "w-36,h-36"
                        )}
                        <span>{userInfo?.username}</span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu renderOnMount={true}>
                        {walletAddress ? (
                          <div className={styles.header_userDropdownHead}>
                            <div
                              className={styles.header_userDropdownHeadThumb}>
                              <img src={user} alt={userInfo?.username} />
                            </div>
                            <div
                              className={styles.header_userDropdownHeadContent}>
                              <p>
                                {walletAddress?.slice(0, 8) +
                                  "....." +
                                  walletAddress?.slice(-5)}
                                <span
                                  className="ms-2"
                                  onClick={() => handleCopy(walletAddress)}>
                                  <CopyIcon />
                                </span>
                              </p>
                              <h4>{parseFloat(balance).toFixed(2)} ETH</h4>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className={styles.header_userDropdownAddFund}>
                          {walletAddress ? (
                            <CommonBtn
                              clas
                              title="ADD FUNDS"
                              className="btn-bordered"
                            />
                          ) : (
                            <CommonBtn
                              icon={<WalletIcon />}
                              className={`btn-bordered ${styles.header_wltBTn}`}
                              title="CONNECT WALLET"
                              onClick={() => setWalletModalShow(true)}
                            />
                          )}
                        </div>
                        <NavLink
                          className={`dropdown-item ${styles.header_action_hasIcon}`}
                          to={`/${userInfo?.username}/favourites`}>
                          <MyProfile /> My Profile
                        </NavLink>
                        <NavLink
                          className={`dropdown-item ${styles.header_action_hasIcon}`}
                          to="/profile/my-items">
                          <MyItem /> My Items
                        </NavLink>
                        <NavLink
                          className={`dropdown-item ${styles.header_action_hasIcon}`}
                          onClick={disconnectHandler}
                          to="/">
                          <Disconnect /> Disconnect
                        </NavLink>
                      </Dropdown.Menu>
                    </Dropdown>
                  </>
                ) : (
                  <>
                    <CommonBtn
                      icon={<LoginIcon />}
                      className={`btn-bordered ${styles.header_signIn}`}
                      title="Sign In"
                      onClick={() => setLogin(true)}
                    />
                    <CommonBtn
                      icon={<SignUpIcon />}
                      className={styles.header_signUp}
                      title="Sign Up"
                      onClick={() => setSignup(true)}
                    />
                  </>
                )}
              </div>
              <Navbar.Toggle
                className={styles.header_toggleBtn}
                aria-controls="basic-navbar-nav"
                onClick={() => setBackdrop(!backdrop)}
                ref={toggleBtn}>
                <span className="menu-line"></span>
                <span className="menu-line"></span>
                <span className="menu-line"></span>
              </Navbar.Toggle>
            </div>
          </Container>
        </Navbar>
      </header>
      {login && (
        <SignInModal
          show={login}
          onHide={() => setLogin(false)}
          setLogin={setLogin}
          signUpTrigger={() => {
            setSignup(true);
            setLogin(false);
          }}
          forPassClick={() => {
            setForgotShow(true);
            setLogin(false);
          }}
        />
      )}
      {signup && (
        <SignUpModal
          show={signup}
          onHide={() => setSignup(false)}
          setSignup={setSignup}
          setUserEmail={setUserEmail}
          userEmail={userEmail}
          setVerifyPass={setVerifyPass}
          signInTrigger={() => {
            setLogin(true);
            setSignup(false);
          }}
        />
      )}
      {forgotShow && (
        <ForgotPassword
          show={forgotShow}
          onHide={() => setForgotShow(false)}
          setUserEmail={setUserEmail}
          setVerifyPass={setVerifyPass}
          setResetOtp={setResetOtp}
          setForgotShow={setForgotShow}
        />
      )}
      {newPass && (
        <CreateNewModal
          show={newPass}
          onHide={() => setNewPass(false)}
          title="Create New Password"
          btnText="CHANGE PASSWORD"
          setLogin={setLogin}
          setNewPass={setNewPass}
          userEmail={userEmail}
          resetPassOtp={resetPassOtp}
        />
      )}
      {verifyPass && (
        <VerificationModal
          show={verifyPass}
          onHide={() => setVerifyPass(false)}
          setVerifyPass={setVerifyPass}
          title="Verification Code"
          btnText="VERIFY"
          userEmail={userEmail}
          triggerVerifyOtp={(otp) => {
            if (resetOtp) {
              setResetPassOtp(otp);
              setVerifyPass(false);
              setNewPass(true);
            } else {
              setVerifyPass(false);
              setLogin(true);
            }
          }}
        />
      )}
      {/* {walletModalShow && (
        <WalletModal setWalletModalShow={setWalletModalShow} />
      )} */}

      {walletModalShow && (
        <WalletModal
          setWalletModalShow={setWalletModalShow}
          setConfirmModal={setConfirmModal}
        />
      )}
      {confirmModal && (
        <ConfirmModal
          show={confirmModal}
          text={`Are you sure you want to change your wallet (${userWallet})`}
          setWalletModalShow={setWalletModalShow}
          setConfirmModal={setConfirmModal}
          confirmModalLoader={confirmModalLoader}
          onClick={() => updateWalletRequste()}
        />
      )}
    </>
  );
};
export default Header;
