import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Dropdown } from "react-bootstrap";
import { useFormik } from "formik";
import styles from "./Fractionalize.module.scss";
import { FileUploader } from "react-drag-drop-files";
import upload from "../../assets/images/upload.png";
import BG from "../about/Banner/Banner";
import CommonBtn from "../../components/ui/CommonBtn/CommonBtn";
import PrevImg from "../profilesettings/PrevImg";
import { useSelector } from "react-redux";
import FractionalizeRequest from "./FractionalizeRequest";
import UploadDocuments from "./UploadDocuments";
import { ActionRemove, UploadDocPlusIcon } from "../../assets/svgIcons/SVGicon";
import { getImageUrl, handleUpload } from "../../features/Utility/Utility";
import {
  apiCallGet,
  apiCallPost,
  apiCallPut,
} from "../../features/Axios/Axios";
import APIURLS from "../../features/ApiPath/ApiPath";
import { useLocation, useNavigate } from "react-router-dom";
import { fractionValidationSchema } from "../../features/Validation/YupValidations";
import { FileUploaderType, IMAGE_TYPE } from "../../features/Constant/Constant";

const Fractionalize = () => {
  /*--------------------------------------- React Hooks----------------------------------------------*/
  const navigate = useNavigate();
  const { state } = useLocation();

  /*--------------------------------------- redux states----------------------------------------------*/
  const userLogin = useSelector((state) => state?.user?.userLogin);
  const useInfo = useSelector((state) => state?.user);
  /*--------------------------------------- UseStates----------------------------------------------*/
  const [loader, setLoader] = useState(false);
  const [fractionalizeModel, setFractionalizeModel] = useState(true);
  const [showDocModal, setShowDocModal] = useState(false);
  const [fileList, setFilelist] = useState([]);
  const [allCategory, setAllCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const { additional, collection, document } = selectedCategory;
  const [fractionDetal, setFractionDetal] = useState();

  /*--------------------------------------- Functions----------------------------------------------*/

  const getFractionDetails = async () => {
    if (state?.id) {
      try {
        const res = await apiCallGet(
          `${APIURLS.NFT.FRACTIONALIZE_GET_BYID}/${state?.id}`
        );
        res.data.additionalField = res?.data?.additionalField?.map((_item) => {
          _item.id = _item.catId;
          return _item;
        });
        selectCategoryHandler(res.data.categoryId);
        setFractionDetal(res?.data);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    getFractionDetails();
  }, []);
  const formik = useFormik({
    initialValues: {
      nameofAsset: fractionDetal?.title || "",
      assetCategory: selectedCategory.name || "",
      collection: fractionDetal?.collections || "",
      price: fractionDetal?.price || "",
      noOfFraction: fractionDetal?.quantity || "",
      roi: fractionDetal?.dividend || "",
      description: fractionDetal?.description || "",
      fractionImgA: fractionDetal?.image || "",
      fractionImgB: fractionDetal?.gallery[0].image || "",
      fractionImgC: fractionDetal?.gallery[1].image || "",
      fractionImgD: fractionDetal?.gallery[2].image || "",
      fractionImgE: fractionDetal?.gallery[3].image || "",
      document: fractionDetal?.document || document || "",
      additionalFields: fractionDetal?.additionalField || additional || "",
    },
    validationSchema: fractionValidationSchema,
    enableReinitialize: true,
    onSubmit: async (payload) => {
      setLoader(true);
      try {
        if (fileList !== 0) {
          const imageUpload = await handleUpload(fileList);
          const data = {
            collectionId: fractionDetal?.collectionId || collection[0]?.id,
            categoryId: fractionDetal?.categoryId || selectedCategory?.id,
            title: payload.nameofAsset,
            description: payload.description,
            price: payload.price,
            userId: useInfo?.userData?.userId,
            quantity: payload.noOfFraction,
            image:
              getImageUrl(imageUpload, "fractionImgA") || fractionDetal?.image,
            dividend: payload.roi,
            gallery: [
              {
                image:
                  fractionDetal?.gallery[0].image ||
                  getImageUrl(imageUpload, "fractionImgB"),
              },
              {
                image:
                  fractionDetal?.gallery[1].image ||
                  getImageUrl(imageUpload, "fractionImgC"),
              },
              {
                image:
                  fractionDetal?.gallery[2].image ||
                  getImageUrl(imageUpload, "fractionImgD"),
              },
              {
                image:
                  fractionDetal?.gallery[3].image ||
                  getImageUrl(imageUpload, "fractionImgE"),
              },
            ],
            document: payload.document,
            additionalField: payload.additionalFields,
          };
          fractionalizeRequestHandler(data);
        }
      } catch (error) {
        setLoader(false);
      }
    },
  });

  const fractionalizeRequestHandler = async (data) => {
    setLoader(true);
    let res;
    try {
      if (state?.id) {
        res = await apiCallPut(
          `/${APIURLS?.NFT?.FRACTIONALIZE_GET_BYID}/${state?.id}`,
          data,
          {},
          "toastOn"
        );
      } else {
        res = await apiCallPost(
          `${APIURLS?.NFT?.FRACTIONALIZE_CREATE}`,
          data,
          {},
          "toastOn"
        );
      }

      if (res) {
        setLoader(false);
        navigate(`/${useInfo?.userData?.username}/fractionalize`);
      }
    } catch (error) {
      setLoader(false);
    }
  };

  const getAllCategory = async () => {
    setLoader(true);
    try {
      const res = await apiCallGet(`${APIURLS?.CATEGORY?.CATEGORY}`, {});
      if (res) {
        setAllCategory(res?.data?.rows);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
    }
  };

  const selectCategoryHandler = async (id) => {
    try {
      const res = await apiCallGet(
        `${APIURLS?.USER?.ASSET_CATEGORY}/${id}`,
        {}
      );

      setSelectedCategory(res?.data);
      setFractionalizeModel(false);
    } catch (error) {}
  };

  const handleFractionFileChange = (url, key) => {
    const existKey = fileList?.some((item) => item.key === key);
    if (existKey) {
      let res = fileList.filter((item) => item.key !== key);
      setFilelist([...res, { url, key }]);
    } else {
      setFilelist([...fileList, { url, key }]);
    }
    formik.setFieldValue(key, url);
  };
  const allDocumentsAreUploaded = () => {
    return (
      formik?.values?.document &&
      formik.values.document.every((item) => item?.url)
    );
  };
  /*--------------------------------------- useEffects----------------------------------------------*/
  useEffect(() => {
    userLogin && getAllCategory();
  }, [userLogin]);

  /*--------------------------------------- HTML----------------------------------------------*/

  const removeImage = (key) => {
    formik.setFieldValue(key, "");
  };
  console.log("formik ==>", formik);

  const cancelRequestHandler = async () => {
    try {
      await apiCallPut(
        `/${APIURLS?.NFT?.FRACTIONALIZE_GET_BYID}/${state?.id}`,
        {},
        {}
      );
      navigate(`/${useInfo?.userData?.username}/fractionalize`);
    } catch (error) {}
  };

  return (
    <>
      <section className={styles.fraction_sec}>
        <Container>
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col sm={12}>
                <div className={styles.fraction_sec_head}>
                  <h2>Fractionalize Request</h2>
                  <p>Lorem ipsum, or lipsum as it is sometimes known</p>
                  <div className={styles.hl} />
                </div>
              </Col>
              <Row>
                <Col sm={12} md={8} lg={8}>
                  <Row>
                    <Col
                      sm={12}
                      md={6}
                      lg={6}
                      className={styles.fraction_sec_left}
                    >
                      <Form.Group className="mb-3">
                        <Form.Label>NFT Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter NFT name"
                          id="nameofAsset"
                          name="nameofAsset"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.nameofAsset}
                          readOnly={
                            state && state?.status === "PENDING" ? true : false
                          }
                        />
                        {formik.errors.nameofAsset &&
                        formik.touched.nameofAsset ? (
                          <div className="error_field">
                            <span>{formik.errors.nameofAsset}</span>
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col
                      sm={12}
                      md={6}
                      lg={6}
                      className={styles.fraction_sec_left}
                    >
                      <Form.Group className="mb-3">
                        <Form.Label>Collection</Form.Label>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="success"
                            className={styles.dropdown_menu}
                            id="collection"
                            name="collection"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          >
                            {formik.values.collection || "Select Value"}
                          </Dropdown.Toggle>
                          {collection && (
                            <Dropdown.Menu renderOnMount={true}>
                              {collection?.map((item) => {
                                return (
                                  <Dropdown.Item
                                    href="#"
                                    key={item?.id}
                                    onClick={() =>
                                      formik.setFieldValue(
                                        "collection",
                                        item?.title
                                      )
                                    }
                                    className="dropdown-item"
                                  >
                                    {item?.title}
                                  </Dropdown.Item>
                                );
                              })}
                            </Dropdown.Menu>
                          )}
                        </Dropdown>
                      </Form.Group>
                      {formik.touched?.collection &&
                      formik.errors?.collection ? (
                        <div className="error_field">
                          <span>{formik.errors.collection}</span>
                        </div>
                      ) : null}
                    </Col>
                    <Col
                      sm={12}
                      md={6}
                      lg={6}
                      className={styles.fraction_sec_left}
                    >
                      <Form.Group className="mb-3">
                        <Form.Label>Price</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Enter price"
                          id="price"
                          name="price"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.price}
                          minLength={1}
                          maxLength={8}
                          onKeyDown={(evt) =>
                            (evt.key === "e" ||
                              evt.key === "-" ||
                              evt.key === "+") &&
                            evt.preventDefault()
                          }
                          readOnly={
                            state && state?.status === "PENDING" ? true : false
                          }
                        />
                      </Form.Group>
                      {formik.touched.price && formik.errors.price ? (
                        <div className="error_field">
                          <span>{formik.errors.price}</span>
                        </div>
                      ) : null}
                    </Col>
                    <Col
                      sm={12}
                      md={6}
                      lg={6}
                      className={styles.fraction_sec_right}
                    >
                      <Form.Group className="mb-3">
                        <Form.Label>No. of Fractions</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Enter No. of Fractions"
                          id="noOfFraction"
                          name="noOfFraction"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.noOfFraction}
                          onKeyDown={(evt) =>
                            (evt.key === "e" ||
                              evt.key === "-" ||
                              evt.key === "+") &&
                            evt.preventDefault()
                          }
                          minLength="1"
                          maxLength="10"
                          readOnly={
                            state && state?.status === "PENDING" ? true : false
                          }
                        />
                      </Form.Group>
                      {formik.touched.noOfFraction &&
                      formik.errors.noOfFraction ? (
                        <div className="error_field">
                          <span>{formik.errors.noOfFraction}</span>
                        </div>
                      ) : null}
                    </Col>
                    <Col
                      sm={12}
                      md={6}
                      lg={6}
                      className={styles.fraction_sec_right}
                    >
                      <Form.Group className="mb-3">
                        <Form.Label>ROI p.a</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Enter Percentage"
                          id="roi"
                          name="roi"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.roi}
                          onKeyDown={(evt) =>
                            (evt.key === "e" ||
                              evt.key === "-" ||
                              evt.key === "+") &&
                            evt.preventDefault()
                          }
                          readOnly={
                            state && state?.status === "PENDING" ? true : false
                          }
                        />
                      </Form.Group>
                      {formik.touched.roi && formik.errors.roi ? (
                        <div className="error_field">
                          <span>{formik.errors.roi}</span>
                        </div>
                      ) : null}
                    </Col>
                    <Col
                      sm={12}
                      md={6}
                      lg={6}
                      className={styles.fraction_sec_right}
                    >
                      <Form.Group className="mb-3">
                        <Form.Label>Authenticity</Form.Label>
                        <div className={styles.fraction_sec_right_uploadDoc}>
                          <p
                            style={{
                              color: allDocumentsAreUploaded() ? "#fff" : "",
                            }}
                          >
                            {allDocumentsAreUploaded()
                              ? "All Documents Are Uploaded"
                              : "Add your ownership document"}
                          </p>
                          {state && state?.status === "PENDING" ? null : (
                            <button
                              onClick={() => setShowDocModal(true)}
                              type="button"
                            >
                              <UploadDocPlusIcon />
                            </button>
                          )}
                        </div>
                      </Form.Group>
                      {formik.touched.document && formik.errors.document ? (
                        <div className="error_field">
                          <span>
                            {formik.errors.document.some((i) => i.url)
                              ? "Pdf document is required"
                              : ""}
                          </span>
                        </div>
                      ) : null}
                    </Col>
                    <Col sm={12}>
                      <Form.Group
                        className={`mb-3  ${styles.fraction_sec_left_hasTextarea}`}
                      >
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={2}
                          placeholder="Write your words here..."
                          id="description"
                          name="description"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.description}
                          maxLength={300}
                          readOnly={
                            state && state?.status === "PENDING" ? true : false
                          }
                        />
                      </Form.Group>
                      {formik.touched.description &&
                      formik.errors.description ? (
                        <div className="error_field">
                          <span>{formik.errors.description}</span>
                        </div>
                      ) : null}
                    </Col>
                  </Row>
                </Col>
                <Col sm={12} md={4} lg={4}>
                  <Col sm={12} className={styles.fraction_sec_right}>
                    <Form.Label>Picture/Video</Form.Label>
                    <div className={styles.upload_handle}>
                      <FileUploader
                        types={FileUploaderType}
                        onTypeError={(err) => alert(JSON.stringify(err))}
                        handleChange={(e) =>
                          handleFractionFileChange(e, "fractionImgA")
                        }
                        name="fractionfileA"
                        classes={`drop_area drop_zone ${
                          formik.values.fractionImgA && styles.update
                        }`}
                        children={
                          <>
                            {!formik.values.fractionImgA ? (
                              <div className={styles.upload_handle_img}>
                                <img src={upload} alt="upload_img" />
                                <p>
                                  Drag & Drop here or <br />
                                  <span className="mt-2">Browse Files</span>
                                </p>
                                <h4>
                                  Choose any:{" "}
                                  <span>
                                    JPG, PNG, TIFF, Bitmap, GIF, EPS, RAW, MP4,
                                    MPEG-2, MOV, WMV
                                  </span>
                                </h4>
                                <p>Images: Size should not be more than 5 MB</p>
                              </div>
                            ) : (
                              <div className={styles.upload_handle_img}>
                                <img src={upload} alt="upload_img" />
                              </div>
                            )}
                          </>
                        }
                      />
                      {formik.values.fractionImgA && (
                        <>
                          <PrevImg file={formik.values.fractionImgA || ""} />
                          {state && state?.status === "PENDING" ? null : (
                            <button
                              className={styles.upload_handle_child_removeBtn}
                              type="button"
                              onClick={() => removeImage("fractionImgA")}
                            >
                              <ActionRemove />
                            </button>
                          )}
                        </>
                      )}
                    </div>
                    {formik.touched.fractionImgA &&
                    formik.errors.fractionImgA ? (
                      <div className="error_field">
                        <span>{formik.errors.fractionImgA}</span>
                      </div>
                    ) : null}

                    <Form.Group className="mb-3">
                      <Row className="mt-4">
                        <Col xs={6} sm={6} lg={3} className={`smallThumbs`}>
                          <div
                            className={styles.upload_handle_child}
                            style={{ backgroundImage: `url(${BG})` }}
                          >
                            <FileUploader
                              types={FileUploaderType}
                              handleChange={(e) =>
                                handleFractionFileChange(e, "fractionImgB")
                              }
                              name="fractionfileB"
                              classes={`drop_area drop_zone ${
                                formik.values.fractionImgB && styles.update
                              }`}
                              children={
                                <>
                                  {!formik.values.fractionImgB ? (
                                    <div
                                      className={
                                        styles.upload_handle_child_icon
                                      }
                                    >
                                      <img src={upload} alt="upload_img" />
                                    </div>
                                  ) : (
                                    <div
                                      className={
                                        styles.upload_handle_child_icon
                                      }
                                    >
                                      <img src={upload} alt="upload_img" />
                                    </div>
                                  )}
                                </>
                              }
                            />
                            {formik.values.fractionImgB && (
                              <>
                                <PrevImg
                                  file={formik.values.fractionImgB || ""}
                                />
                                {state && state?.status === "PENDING" ? null : (
                                  <button
                                    className={
                                      styles.upload_handle_child_removeBtn
                                    }
                                    type="button"
                                    onClick={() => removeImage("fractionImgB")}
                                  >
                                    <ActionRemove />
                                  </button>
                                )}
                              </>
                            )}
                          </div>
                          {formik.touched.fractionImgB &&
                          formik.errors.fractionImgB ? (
                            <div className="error_field">
                              <span>{formik.errors.fractionImgB}</span>
                            </div>
                          ) : null}
                        </Col>

                        <Col xs={6} sm={6} lg={3} className={`smallThumbs`}>
                          <div
                            className={styles.upload_handle_child}
                            style={{ backgroundImage: `url(${BG})` }}
                          >
                            <FileUploader
                              types={FileUploaderType}
                              handleChange={(e) =>
                                handleFractionFileChange(e, "fractionImgC")
                              }
                              name="fractionfileC"
                              classes={`drop_area drop_zone ${
                                formik.values.fractionImgC && styles.update
                              }`}
                              children={
                                <>
                                  {!formik.values.fractionImgC ? (
                                    <div
                                      className={
                                        styles.upload_handle_child_icon
                                      }
                                    >
                                      <img src={upload} alt="upload_img" />
                                    </div>
                                  ) : (
                                    <div
                                      className={
                                        styles.upload_handle_child_icon
                                      }
                                    >
                                      <img src={upload} alt="upload_img" />
                                    </div>
                                  )}
                                </>
                              }
                            />
                            {formik.values.fractionImgC && (
                              <>
                                {state && state?.status === "PENDING" ? null : (
                                  <button
                                    className={
                                      styles.upload_handle_child_removeBtn
                                    }
                                    type="button"
                                    onClick={() => removeImage("fractionImgC")}
                                  >
                                    <ActionRemove />
                                  </button>
                                )}
                                <PrevImg
                                  file={formik.values.fractionImgC || ""}
                                />
                              </>
                            )}
                          </div>
                          {formik.touched.fractionImgC &&
                          formik.errors.fractionImgC ? (
                            <div className="error_field">
                              <span>{formik.errors.fractionImgC}</span>
                            </div>
                          ) : null}
                        </Col>

                        <Col xs={6} sm={6} lg={3} className={`smallThumbs`}>
                          <div
                            className={styles.upload_handle_child}
                            style={{ backgroundImage: `url(${BG})` }}
                          >
                            <FileUploader
                              types={FileUploaderType}
                              handleChange={(e) =>
                                handleFractionFileChange(e, "fractionImgD")
                              }
                              name="fractionfileD"
                              classes={`drop_area drop_zone ${
                                formik.values.fractionImgD && styles.update
                              }`}
                              children={
                                <>
                                  {!formik.values.fractionImgD ? (
                                    <div
                                      className={
                                        styles.upload_handle_child_icon
                                      }
                                    >
                                      <img src={upload} alt="upload_img" />
                                    </div>
                                  ) : (
                                    <div
                                      className={
                                        styles.upload_handle_child_icon
                                      }
                                    >
                                      <img src={upload} alt="upload_img" />
                                    </div>
                                  )}
                                </>
                              }
                            />
                            {formik.values.fractionImgD &&
                              formik.values.fractionImgD && (
                                <>
                                  <PrevImg
                                    file={formik.values.fractionImgD || ""}
                                  />
                                  {state &&
                                  state?.status === "PENDING" ? null : (
                                    <button
                                      className={
                                        styles.upload_handle_child_removeBtn
                                      }
                                      type="button"
                                      onClick={() =>
                                        removeImage("fractionImgD")
                                      }
                                    >
                                      <ActionRemove />
                                    </button>
                                  )}
                                </>
                              )}
                          </div>
                          {formik.touched.fractionImgD &&
                          formik.errors.fractionImgD ? (
                            <div className="error_field">
                              <span>{formik.errors.fractionImgD}</span>
                            </div>
                          ) : null}
                        </Col>

                        <Col xs={6} sm={6} lg={3} className={`smallThumbs`}>
                          <div
                            className={styles.upload_handle_child}
                            style={{ backgroundImage: `url(${BG})` }}
                          >
                            <FileUploader
                              types={FileUploaderType}
                              handleChange={(e) =>
                                handleFractionFileChange(e, "fractionImgE")
                              }
                              name="fractionfileE"
                              classes={`drop_area drop_zone ${
                                formik.values.fractionImgE && styles.update
                              }`}
                              children={
                                <>
                                  {!formik.values.fractionImgE ? (
                                    <div
                                      className={
                                        styles.upload_handle_child_icon
                                      }
                                    >
                                      <img src={upload} alt="upload_img" />
                                    </div>
                                  ) : (
                                    <div
                                      className={
                                        styles.upload_handle_child_icon
                                      }
                                    >
                                      <img src={upload} alt="upload_img" />
                                    </div>
                                  )}
                                </>
                              }
                            />
                            {formik.values.fractionImgE && (
                              <>
                                <PrevImg
                                  file={formik.values.fractionImgE || ""}
                                />
                                {state && state?.status === "PENDING" ? null : (
                                  <button
                                    className={
                                      styles.upload_handle_child_removeBtn
                                    }
                                    type="button"
                                    onClick={() => removeImage("fractionImgE")}
                                  >
                                    <ActionRemove />
                                  </button>
                                )}
                              </>
                            )}
                          </div>
                          {formik.touched.fractionImgE &&
                          formik.errors.fractionImgE ? (
                            <div className="error_field">
                              <span>{formik.errors.fractionImgE}</span>
                            </div>
                          ) : null}
                        </Col>
                      </Row>
                    </Form.Group>
                  </Col>
                </Col>
              </Row>
              <Row>
                {formik?.values?.additionalFields &&
                  formik?.values?.additionalFields && (
                    <Col xs={12} md={12}>
                      <h3>Properties</h3>
                    </Col>
                  )}
                {formik?.values?.additionalFields &&
                  formik?.values?.additionalFields?.map(
                    ({ name, value }, index) => {
                      return (
                        <Col xs={12} md={4} key={index}>
                          <Form.Group className="mb-3">
                            <Form.Label>{name || "Name"}</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder={name}
                              name={`additionalFields[${index}].value`}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={value}
                              readOnly={
                                state && state?.status === "PENDING"
                                  ? true
                                  : false
                              }
                            />
                          </Form.Group>
                          {formik?.errors?.additionalFields?.length > 0 ? (
                            <div className="error_field">
                              {formik?.errors?.additionalFields[index]
                                ?.value && (
                                <span>
                                  {
                                    formik?.errors?.additionalFields[index]
                                      ?.value
                                  }
                                </span>
                              )}
                            </div>
                          ) : null}
                        </Col>
                      );
                    }
                  )}
              </Row>
            </Row>
            <Row>
              <Col
                sm={12}
                md={12}
                lg={12}
                className={`${styles.fraction_sec_left} ${styles.fraction_sec_left_button}`}
              >
                {state && state?.status === "PENDING" ? (
                  <CommonBtn
                    title="Cancel"
                    type="button"
                    className="btn-bordered my-5"
                    onClick={cancelRequestHandler}
                  />
                ) : (
                  <CommonBtn
                    title="SUBMIT"
                    type="submit"
                    className="btn-bordered my-5"
                    loader={loader}
                  />
                )}
              </Col>
            </Row>
          </Form>
        </Container>
      </section>
      {fractionalizeModel && !state?.id && (
        <FractionalizeRequest
          show={() => fractionalizeModel(true)}
          onHide={() => setFractionalizeModel(false)}
          loader={loader}
          allCategory={allCategory}
          selectCategoryHandler={selectCategoryHandler}
        />
      )}

      {showDocModal && (
        <UploadDocuments
          show={showDocModal}
          formik={formik}
          onHide={() => setShowDocModal(false)}
          document={document}
        />
      )}
    </>
  );
};

export default Fractionalize;
