const APIURLS = {
  USER: {
    HOME: "/users/v1/dashboard/all",
    SIGN_IN: "users/v1/auth/email-login",
    SIGNUP: "users/v1/auth/signup",
    OTPVERIFY: "users/v1/auth/verify-otp",
    RESEND_OTP: "users/v1/auth/resend-otp",
    ADD_EMAIL: "users/v1/users/add-email",
    FORGET_PASS: "users/v1/auth/forget-password",
    RESET_PASS: "users/v1/auth/reset-password",
    FIND_USERNAME: "users/v1/users/profile",
    CHECK_USERNAME: "users/v1/users/check",
    PROFILE_PRIVATE: "users/v1/users/user_profile",
    PROFILE_PUBLIC: "users/v1/users/user_public_profile",
    USER_PROFILE: "users/v1/users/profile",
    UPDATE_PROFILE: "users/v1/users/update-profile",
    ASSET_CATEGORY: "users/v1/category",
    NEWSLETTER: "/users/v1/newsletter/subscribe",
    CONTACT_US: "/users/v1/contactUs",
  },
  DIVIDENT: {
    ADD: "/users/v1/fractionalize/add/dividend",
    CLAM: "/users/v1/fractionalize/claim/dividend",
    SHOW: "/users/v1/fractionalize/show/dividend",
  },
  PROFILE: { MY_ITEM: "/users/v1/nft/user-item/" },
  WALLET: {
    UPDATE_WALLET: "users/v1/users/update-wallet",
    ADD_WALLET_ADDRESS: "users/v1/users/add-wallet-address",
  },
  NOTIFICATION: {
    GET_NOTIFICATION: "/notification/v1/get_notification/",
    DELETE: "/notification/v1/delete_notification/",
    DELETE_ALL: "/notification/v1/delete_all_notification/",
    MARK_READ_ALL: "/notification/v1/update_notification",
    MARK_READ: "/notification/v1/update_one_notification/",
  },
  NFT: {
    GET_ALL_NFT: "/users/v1/nft/all",
    GET_NFT_DETAILS: "/users/v1/nft/detail",
    GET_NFT_ALL_LISTING: "/users/v1/nft/all-listing",
    GET_NFT_ALL_FAVOURITE: "/users/v1/nft/all-favourite",
    DELIST_NFT: "/users/v1/nft/delist",
    UPDATE_DELIST_NFT: "/users/v1/nft/update-delist-nft",
    NFT_FAVOURITE: "/users/v1/nft/like-dislike",
    ADDCOLLECTIONS: "nft/api/v1/collections/add",
    FRACTIONALIZE_CREATE: "users/v1/fractionalize/create",
    FRACTIONALIZE_LIST: "users/v1/fractionalize/list",
    FRACTIONALIZE_GET_BYID: "users/v1/fractionalize",
    LIST_NFT: "users/v1/nft/listNft",
    NFT_LISTING: "users/v1/nft/listing",
    GET_NFT_DOCS: "/users/v1/nft/pdf",
    GET_ALL_ACTIVITY: "/users/v1/nft/activity",
    LOCK_NFT: "users/v1/nft/lock-qauntity",
    GET_ALL_EXPLORE_ACTIVITY: "/users/v1/activity/all",
    GET_HISTORY: "/users/v1/nft/history",
    GET_TOP_OWNERS: "/users/v1/nft/top-owners",

    NFT_MINTED: "/users/v1/nft/mint-nft",
  },
  SEARCH: {
    SEARCH_LIST: "search/api/v1",
  },
  CHAT: {
    CHAT_HISTORY: "chat/api/v1/self_chat_history",
  },
  MEDIA: {
    ADD_IMAGE: "media/v1/media/user_upload",
    ADD_IMAGE_FRACTION: "media/v1/media/upload",
    ADD_PDF: "media/v1/media/pdf_upload",
    ADD_VIDEO: "media/v1/media/video_upload",
  },
  COLLECTION: {
    GET_ALL_COLLECTION: "/users/v1/collections/all",
    GET_COLLECTION_DETAILS: "/users/v1/collections/detail",
    COLLECTION_FAVOURITE: "/users/v1/collections/like-dislike",
  },
  CATEGORY: {
    CATEGORY: "/users/v1/category/all",
  },
  SUPPORT: {
    ALL_TICKETS: "/users/v1/support/tickets",
    CREATE_TICKET: "/users/v1/support/createTicket",
    TICKET_DETAIL: "users/v1/support/openTicket",
    SEND_RESPONSE: "/users/v1/support/send-msg",
  },
  BUY_NFT: "/users/v1/nft/buy",
  COIN_PRICE: "/blockchain/api/v1/price-conversion",
  GLOBAL_SEARCH: "/users/v1/search/global",
  KYC: "/users/v1/user-kyc/kyc",
  BURN_REQ: "/users/v1/nft/burn-nft",
}

export default APIURLS
