import React from "react";
import "react-loading-skeleton/dist/skeleton.css";
import { createRoot } from "react-dom/client";
import { Toaster } from "react-hot-toast";
import { Provider } from "react-redux";
import { store } from "./Redux/Store";
import persistStore from "redux-persist/es/persistStore";
import { PersistGate } from "redux-persist/integration/react";
import "slick-carousel/slick/slick.css";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App";
import "./index.scss";
import { Web3ReactProvider } from "@web3-react/core";
import ErrorBoundary from "./components/common/ErrorBoundary/ErrorBoundary";
import { Web3Provider } from "@ethersproject/providers";
import { prefetchNotifications } from "./Redux/Reducers/notifications";

const getLibrary = (provider) => {
  const library = new Web3Provider(provider);
  library.pollingInterval = 8000; // frequency provider is polling
  return library;
};

const persistor = persistStore(store);
const root = createRoot(document.getElementById("root"));

root.render(
  <Web3ReactProvider getLibrary={getLibrary}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ErrorBoundary>
          <Toaster position="top-right" />
          <App />
        </ErrorBoundary>
      </PersistGate>
    </Provider>
  </Web3ReactProvider>
);
