import React, { useEffect, useState } from "react"
import styles from "../marketplace.module.scss"
import { Col, Container, Row } from "react-bootstrap"
import CommonCard from "../../../components/ui/CommonCards/CommonCard/CommonCard"
import MarketplaceHead from "../MarketplaceHead/MarketplaceHead"
import CommonBtn from "../../../components/ui/CommonBtn/CommonBtn"
import NoResult from "../../../components/ui/NoResult/NoResult"
import APIURLS from "../../../features/ApiPath/ApiPath"
import { apiCallPost } from "../../../features/Axios/Axios"
import { getAllCategory } from "../../../features/Utility/Utility"
import CollectionSkelton from "./CollectionSkelton"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

const Nft = () => {
  const navigate = useNavigate()
  const [nftData, setNftData] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [searchvalue, setSearchvalue] = useState("")
  const [filterValue, setFilterValue] = useState("")
  const [allCategory, setAllCategory] = useState([])
  const userWallet = useSelector((state) => state?.user?.userData?.userWallets)
  const [categoryId, setCategoryId] = useState("all")
  const [nftLoader, setNftLoader] = useState(true)
  const getAllNFT = async (page, searchvalue) => {
    setNftLoader(true)
    try {
      const { data } = await apiCallPost(
        `${APIURLS.NFT.GET_ALL_NFT}/${page}/12`,
        { search: searchvalue, filter: filterValue, category: categoryId },
        {},
        "toastOnError"
      )
      if (data) {
        const { rows } = data
        if (page > 1 || (searchvalue !== "" && page > 1)) {
          setNftData((nftData) => [...nftData, ...rows])
          setNftLoader(false)
        } else {
          setNftData(rows)
          setNftLoader(false)
        }
        setTotalCount(data?.count)
        setNftLoader(false)
      }
    } catch (error) {
      setNftLoader(false)
      console.log(error)
    }
  }
  useEffect(() => {
    getAllCategory(setAllCategory)
  }, [])
  useEffect(() => {
    const finder = setTimeout(async () => {
      setCurrentPage(1)
      await getAllNFT("1", searchvalue)
    }, [500])
    return () => {
      clearTimeout(finder)
    }
  }, [searchvalue, filterValue, categoryId])

  const handleViewMore = (nextPage) => {
    if (searchvalue && searchvalue.length >= 1) {
      getAllNFT(nextPage, searchvalue)
    } else {
      getAllNFT(nextPage, "")
    }
  }

  return (
    <>
      <section className={styles.collection_sec}>
        <MarketplaceHead
          item="Collections"
          item1="NFTs"
          setSearchvalue={setSearchvalue}
          searchvalue={searchvalue}
          setFilterValue={setFilterValue}
          allCategory={allCategory}
          setCategoryId={setCategoryId}
        />
        <Container>
          <Row>
            {nftLoader ? (
              <CollectionSkelton />
            ) : (
              <>
                {nftData?.length > 0 ? (
                  <>
                    {nftData?.map((item, index) => (
                      <Col
                        xs={12}
                        sm={6}
                        lg={4}
                        xl={3}
                        className="mt-5"
                        key={index}>
                        <CommonCard
                          img={item?.image}
                          fileType={item?.fileType}
                          title={item?.title}
                          to={item?.nftId}
                          nftId={item?.nftId}
                          subtitle={item?.collection.title}
                          price={item?.price}
                          fractions={`${item?.quantityLeft} / ${item?.quantity}`}
                          btnTitle={
                            item?.walletAddress === userWallet ? "VIEW" : "BUY"
                          }
                          onClickBtn={() => navigate(item?.nftId)}
                          isFavourite={item?.isFavourite}
                          type={"nft"}
                          setNftData={setNftData}
                        />
                      </Col>
                    ))}
                    {nftData?.length !== totalCount && (
                      <div className="text-center mt-5 ">
                        <CommonBtn
                          title="VIEW MORE"
                          className="btn-bordered"
                          onClick={() => {
                            handleViewMore(currentPage + 1)
                            setCurrentPage((prevValue) => prevValue + 1)
                          }}
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <NoResult />
                )}
              </>
            )}
          </Row>
        </Container>
      </section>
    </>
  )
}

export default Nft
