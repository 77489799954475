import { combineReducers } from "redux";
import user from "./Reducers/user";
import wallet from "./Reducers/wallet";
import notification from "./Reducers/notifications";
const createRootReducer = () =>
  combineReducers({
    user,
    wallet,
    notification,
  });

export default createRootReducer;
