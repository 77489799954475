import React from "react";
import styles from "./MarketplaceHead.module.scss";
import { Col, Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import CommonSearch from "../../../components/ui/CommonSearch/CommonSearch";
import CommonSelectbox from "../../../components/ui/Dropdowns/CommonSelectbox";
import { SelectCategory, SortIcon } from "../../../assets/svgIcons/SVGicon";

const MarketplaceHead = ({
  searchvalue,
  setSearchvalue,
  setFilterValue,
  allCategory,
  setCategoryId,
  defaultValue,
  title,
}) => {
  const optionsTwo = [
    { menu: "Newset", value: "new" },
    { menu: "Oldest", value: "old" },
  ];
  return (
    <>
      <section className={styles.market_head}>
        <Container>
          <Row>
            <Col>
              <ul>
                <li>
                  <NavLink to="/marketplace/collections" className="h2">
                    Collections
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/marketplace/nft" className="h2">
                    NFTs
                  </NavLink>
                </li>
              </ul>
              <div className="hl" />
              {/* <CommonDropdown /> */}
              {/* <SearchBar /> */}
              <Row>
                <Col lg={6}>
                  <div className="d-flex justify-content-between align-items-center">
                    <CommonSearch
                      className={styles.market_head_search}
                      placeholder="Search..."
                      searchvalue={searchvalue}
                      setSearchvalue={setSearchvalue}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  {/* <div className="text-end">
                    <CommonSelectbox icon={<SortIcon />} title="Select Category" options={optionsOne} className="me-3" />
                    <CommonSelectbox icon={<SortIcon />} title="Newest" options={optionsTwo} />
                  </div> */}
                  <div className={`text-md-end ${styles.activity_sec_act}`}>
                    <CommonSelectbox
                      icon={<SelectCategory />}
                      title={title || "All"}
                      options={allCategory}
                      className="me-3 select-category-btn"
                      setCategoryId={setCategoryId}
                      type={"category"}
                      defaultValue={defaultValue}
                    />
                    <CommonSelectbox
                      icon={<SortIcon />}
                      title="Newest"
                      options={optionsTwo}
                      setFilterValue={setFilterValue}
                      type={"filter"}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default MarketplaceHead;
