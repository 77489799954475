import React, { useState } from "react";
import "../CommonModal/CommonModal.scss";
import CommonModal from "../CommonModal/CommonModal";
import { Form } from "react-bootstrap";
import PasswordChecklist from "react-password-checklist";
import CommonBtn from "../CommonBtn/CommonBtn";
import PasswordInput from "../passwordinput/PasswordInput";
import { useFormik } from "formik";
import { newPassWordFields } from "../../../features/FormFields/FormFields";
import { resetPasswordValidations } from "../../../features/Validation/YupValidations";
import { apiCallPost } from "../../../features/Axios/Axios";
import APIURLS from "../../../features/ApiPath/ApiPath";

const CreatNewModal = ({
  show,
  onHide,
  title,
  btnText,
  setLogin,
  setNewPass,
  userEmail,
  resetPassOtp,
}) => {
  const [signupLoader, setSignupLoader] = useState(false);

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: resetPasswordValidations,
    onSubmit: async (values) => {
      setSignupLoader(true);
      try {
        const data = {
          email: userEmail,
          otp: +resetPassOtp,
          ...values,
        };
        const res = await apiCallPost(
          `${APIURLS.USER.RESET_PASS}`,
          data,
          {},
          "toastOn"
        );
        if (res) {
          setLogin(true);
          setNewPass(false);
        }
        setLogin(true);
        setNewPass(false);
      } catch (error) {
        setSignupLoader(false);
      }
    },
  });

  return (
    <>
      <CommonModal
        show={show}
        onHide={onHide}
        className="creat_modal"
        modalTitle={
          <>
            <h3 className="creat_headind">{title}</h3>
          </>
        }
        backdrop="static"
        modal
      >
        <Form onSubmit={formik.handleSubmit}>
          {newPassWordFields.map(({ label, name }, index) => (
            <Form.Group className="mb-3" key={index}>
              <Form.Label>{label}</Form.Label>
              <PasswordInput
                placeholder="********"
                id={name}
                name={name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values[name]}
              />
              {name === "password" &&
                (formik.touched.password || formik.values.password) && (
                  <PasswordChecklist
                    className="PasswordCheckList"
                    rules={[
                      "maxLength",
                      "minLength",
                      "specialChar",
                      "number",
                      "capital",
                      "lowercase",
                    ]}
                    maxLength={15}
                    minLength={8}
                    value={formik.values.password}
                  //messages={{minLength:"Password has atleast 8 Characters",maxLength:"password must contain a maximum of 15 characters."}}
                  />
                )}
              {formik.errors[name] && (
                <div className="error_field">
                  <span>{formik.errors[name]}</span>
                </div>
              )}
            </Form.Group>
          ))}
          <CommonBtn
            className="mt-3 w-100"
            title={btnText}

            disabled={!formik.isValid}
            type="submit"
            loader={signupLoader}
          />
        </Form>
      </CommonModal>
    </>
  );
};

export default CreatNewModal;
