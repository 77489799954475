import React, { useState } from "react"
import { Link } from "react-router-dom"
import styles from "./UserCard.module.scss"
import Insta from "../../../../assets/images/icons/insta.png"
import Twitter from "../../../../assets/images/icons/twitter.png"
import User from "../../../../assets/images/profile-img.png"
import { CopyIcon } from "../../../../assets/svgIcons/SVGicon"
import CommonBtn from "../../../../components/ui/CommonBtn/CommonBtn"
import { useSelector } from "react-redux"
import moment from "moment"
import { toasts } from "../../../../components/common/Toast/Toast"
import WalletModal from "../../../../components/ui/WalletModal/WalletModal"
import ConfirmModal from "../../../../components/ui/ConfirmModal/ConfirmModal"
import { apiCallPost } from "../../../../features/Axios/Axios"
import APIURLS from "../../../../features/ApiPath/ApiPath"
import { getProfile } from "../../../../features/Utility/Utility"

const UserCard = () => {
  const [walletModalShow, setWalletModalShow] = useState(false)
  const [confirmModal, setConfirmModal] = useState(false)
  const [confirmModalLoader, setConfirmModalLoader] = useState(false)
  const userInfo = useSelector((state) => state?.user?.userData)
  const userWallet = useSelector((state) => state?.user?.userData?.userWallets)
  const walletAddress = useSelector((state) => state?.wallet?.walletAddress)
  const auth = useSelector((state) => state?.user?.userLogin)

  const handleCopy = () => {
    navigator.clipboard.writeText(walletAddress)
    toasts.success("Address Copied!")
  }
  const updateWalletRequste = async () => {
    setConfirmModalLoader(true)
    try {
      const res = await apiCallPost(
        APIURLS.WALLET.UPDATE_WALLET,
        { address: userWallet },
        {},
        "toastOn"
      )
      if (res) {
        setConfirmModalLoader(false)
        setConfirmModal(false)
        setWalletModalShow(false)
        getProfile()
      }
    } catch (error) {
      setConfirmModalLoader(false)
      setConfirmModal(false)
      setWalletModalShow(false)
    }
  }
  return (
    <>
      <div className={styles.usercard}>
        <img
          src={userInfo?.userMeta?.profileImage || User}
          alt=""
          className={styles.usercard_thumb}
        />
        <h6 className="mb-3">{userInfo?.username}</h6>
        <div className={styles.usercard_wlt_address}>
          {userWallet && (
            <>
              <span>
                {userWallet?.slice(0, 8) + "....." + userWallet?.slice(-5)}{" "}
              </span>
              <button type="button" onClick={handleCopy}>
                <CopyIcon />
              </button>
            </>
          )}
        </div>
        <p>{userInfo?.userMeta?.bio}</p>
        <ul>
          {userInfo?.userMeta?.instagram && (
            <li>
              <Link to={userInfo?.userMeta?.instagram} target="_blank">
                <img src={Insta} alt="" />
              </Link>
            </li>
          )}
          {userInfo?.userMeta?.twitter && (
            <li>
              <Link to={userInfo?.userMeta?.twitter} target="_blank">
                <img src={Twitter} alt="" />
              </Link>
            </li>
          )}
        </ul>
        <hr />
        {!auth && (
          <>
            <p className={styles.usercard_footer}>
              Member Since {moment(userInfo?.createdAt).format("MMM  DD, yyyy")}
            </p>
          </>
        )}
        {auth && (
          <CommonBtn
            className={styles.walletButton}
            title="WALLET"
            loader={confirmModalLoader}
            onClick={async () => {
              await getProfile(setConfirmModalLoader)
              setWalletModalShow(true)
            }}
          />
        )}
      </div>
      {walletModalShow && (
        <WalletModal
          setWalletModalShow={setWalletModalShow}
          setConfirmModal={setConfirmModal}
        />
      )}
      {confirmModal && (
        <ConfirmModal
          show={confirmModal}
          text={`Are you sure you want to change your wallet (${userWallet})`}
          setWalletModalShow={setWalletModalShow}
          setConfirmModal={setConfirmModal}
          confirmModalLoader={confirmModalLoader}
          onClick={() => updateWalletRequste()}
        />
      )}
    </>
  )
}

export default UserCard
