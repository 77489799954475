import React from "react";
import { Form } from "react-bootstrap";
import { SearchIcon } from "../../../assets/svgIcons/SVGicon";
import styles from "./CommonSearch.module.scss";

const CommonSearch = ({
  placeholder,
  className,
  setSearchvalue,
  searchvalue,
  setCurrentPage,
}) => {
  
  return (
    <>
      <div className={`${styles.common_search} ${className}`}>
        <div style={{ position: "relative" }}>
          <Form.Control
            type="email"
            placeholder={placeholder}
            required
            value={searchvalue}
            onChange={(e) => {
              if (e?.target?.value?.length <= 1) {
                setCurrentPage && setCurrentPage(1);
              }
              setSearchvalue && setSearchvalue(e.target.value);
            }}
          />
          <button type="submit">
            <SearchIcon />
          </button>
        </div>
      </div>
    </>
  );
};

export default CommonSearch;
