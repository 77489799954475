  import React from "react"
  import { Spinner } from "react-bootstrap"

  const Spinners = () => {
    return (
      <>
        <Spinner animation="grow" size="sm" style={{ position: "absolute" }} />
        <Spinner animation="grow" style={{ width: "24px", height: "24px" }} />
      </>
    )
  }

  export default Spinners
