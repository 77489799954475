import React, { useEffect, useState } from "react"
import CommonCard from "../../../components/ui/CommonCards/CommonCard/CommonCard"
import styles from "./Myitems.module.scss"
import { Col, Row } from "react-bootstrap"
import CommonBtn from "../../../components/ui/CommonBtn/CommonBtn"
import SearchAndFilter from "../commonComponents/SearchAndFilter/SearchAndFilter"
import ListNFTModal from "../../../components/ui/ListNFTModal/ListNFTModal"
import { apiCallPut } from "../../../features/Axios/Axios"
import APIURLS from "../../../features/ApiPath/ApiPath"
import { isArrayEmpty } from "../../../features/Utility/Utility"
import useDebounce from "../../../hooks/useDebounce"
import {
  CREATER_FILTER_OPTION,
  FILTER_PROFILE_FRACTION_SORT,
} from "../../../features/Constant/Constant"
import NoResult from "../../../components/ui/NoResult/NoResult"

const Myitems = () => {
  const [listNftModal, setListNftModal] = useState(false)
  const [myItemData, setMyItemData] = useState([])
  const [myItemDataNft, setMyItemDataNft] = useState({})
  const [searchTerm, setSearchTerm] = useState("")
  const [sortOrder, setSortOrder] = useState("")
  const [createFilter, setCreateFilter] = useState(
    CREATER_FILTER_OPTION[0].value
  )
  const [currPage, setCurrPage] = useState(1)
  const debounceTerm = useDebounce(searchTerm)
  const getMyItems = async () => {
    try {
      const { data } = await apiCallPut(
        `${APIURLS.PROFILE.MY_ITEM}${currPage}/6`,
        {
          page: currPage,
          search: debounceTerm,
          order: sortOrder,
          type: createFilter,
        },
        {}
      )
      if (currPage > 1) {
        setMyItemData((prev) => {
          return { ...prev, rows: [...prev.rows, ...data.rows] }
        })
      } else {
        setMyItemData(data)
      }
    } catch (error) {}
  }
  useEffect(() => {
    getMyItems()
  }, [currPage, createFilter, sortOrder, debounceTerm])
  return (
    <div className={styles.myitems}>
      <SearchAndFilter
        searchvalue={searchTerm}
        setSearchvalue={setSearchTerm}
        setCurrentPage={setCurrPage}
        setFilterValue={setSortOrder}
        setCollectionValue={setCreateFilter}
        type={"filter"}
        collection={true}
        newest={true}
        search={true}
        setNewestFilterValue={setSortOrder}
        newestOptions={FILTER_PROFILE_FRACTION_SORT}
        collectionFilterTitle={CREATER_FILTER_OPTION[0].menu}
        collectionFilterOption={CREATER_FILTER_OPTION}
        options={FILTER_PROFILE_FRACTION_SORT}
      />
      <Row>
        {isArrayEmpty(myItemData?.rows) && <NoResult />}
        {myItemData?.rows?.map((item, index) => (
          <Col sm={6} md={4} key={index} className="mt-5">
            <CommonCard
              img={item?.image}
              title={item.title}
              nftId={item?.nftId}
              to={`/marketplace/nft/${item?.nftId}`}
              subtitle={item.collection}
              price={item.price}
              fractions={`${item?.quantityLeft} / ${item?.quantity}`}
              fileType={item?.fileType}
              isFavourite={item?.isFavourite}
              isListed={item?.isListed}
              isMint={item?.isMint}
              btnTitle="LIST"
              createFilter={createFilter === "collected"}
              onClickBtn={() => {
                setMyItemDataNft(item)
                setListNftModal(true)
              }}
              myItems
            />
          </Col>
        ))}
      </Row>
      {myItemData?.rows?.length < myItemData?.count && (
        <div>
          <div className="text-center mt-5 pt-5 ">
            <CommonBtn
              title="VIEW MORE"
              onClick={() => setCurrPage((prev) => prev + 1)}
              className="btn-bordered"
            />
          </div>
        </div>
      )}
      {createFilter === "created" && listNftModal && (
        <ListNFTModal
          show={listNftModal}
          onHide={() => setListNftModal(false)}
          myItemDataNft={myItemDataNft}
          createFilter={createFilter === "created"}
          setListNftModal={setListNftModal}
        />
      )}
    </div>
  )
}
export default Myitems
