import React from "react"
import { Table } from "react-bootstrap"
import PerfectScrollbar from "react-perfect-scrollbar"
import "./CommonTable.scss"
import NoResult from "../NoResult/NoResult"

const CommonTable = ({
  className,
  children,
  tableHead,
  isDataEmpty,
  isLoading,
}) => {
  const table = isDataEmpty ? (
    <NoResult />
  ) : (
    <div className={`${className} common_table`}>
      {tableHead && <>{children}</>}
      <PerfectScrollbar>
        <Table>{children}</Table>
      </PerfectScrollbar>
    </div>
  )

  return table
}

export default CommonTable
