import React from "react";
import styles from "./DetailPage.module.scss";
import CommonTable from "../../../components/ui/CommonTable/CommonTable";
import CommonBtn from "../../../components/ui/CommonBtn/CommonBtn";
import { Col, Row } from "react-bootstrap";
import { isArrayEmpty, trimText } from "../../../features/Utility/Utility";

const TopOwnersTable = ({ topOwnder }) => {
  return (
    <>
      <div className={`${styles.history_head} pb-5`}>
        <Row className="align-items-center ">
          <Col className="text-center text-sm-start">
            <h4>Top Owners</h4>
          </Col>
        </Row>
        <hr />
        <CommonTable
          isDataEmpty={isArrayEmpty(topOwnder.length !== 0)}
          className="table-box"
        >
          <thead>
            <tr>
              <th>Rank</th>
              <th>Owner Name</th>
              <th>Address</th>
              <th>% Owned</th>
              <th width="12rem">No. of Fractions</th>
            </tr>
          </thead>
          <tbody>
            {topOwnder &&
              topOwnder?.map((item) => {
                return (
                  <tr>
                    <td>#1</td>
                    <td>{item?.username}</td>
                    <td>{trimText(item?.walletAddress)}</td>
                    <td>{item?.perShare}</td>
                    <td>{item?.quantity}</td>
                  </tr>
                );
              })}
          </tbody>
        </CommonTable>
      </div>
    </>
  );
};

export default TopOwnersTable;
