import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./NotificationBox.module.scss";
import user from "../../../../assets/images/xegara_icon_img.png";
import { useDispatch, useSelector } from "react-redux";
import {
  isArrayEmpty,
  timeFromNow,
} from "../../../../features/Utility/Utility";
import { MarkReadNoftification } from "../../../../features/serives/services";
import {
  deleteNotification,
  getAllNotifications,
} from "../../../../Redux/Reducers/notifications";
import {
  NOFTIFICATION_TYPE,
  VIDEO_EXT_TYPE,
} from "../../../../features/Constant/Constant";

const unReadPageUrl = "/notifications/unread";
const NotificationBox = ({ onClick }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { allNotifications } = useSelector((state) => state?.notification);

  const handleReadNotification = (notificationId) => {
    MarkReadNoftification(notificationId);
    dispatch(
      deleteNotification({
        id: notificationId,
        type: NOFTIFICATION_TYPE.UNREAD,
      })
    );
    setTimeout(() => {
      dispatch(
        getAllNotifications({
          ReadOrUnReadType: NOFTIFICATION_TYPE.ALL,
          page: 1,
        })
      );
    }, 500);
  };

  return (
    <>
      <div className={styles.NotificationHead}>
        <h4>Notifications</h4>
        <button
          onClick={() => {
            onClick();
            navigate(unReadPageUrl);
          }}
        >
          SEE MORE
        </button>
      </div>

      {isArrayEmpty(allNotifications?.rows) && (
        <div className={styles.NotificationHead}>
          <div
            style={{
              flex: 1,
              alignSelf: "center",
              textAlign: "center",
              margin: "30px 0px",
            }}
          >
            <h4>No Notifications</h4>
          </div>
        </div>
      )}
      {allNotifications?.rows?.slice(0, 5)?.map((item, index) => (
        <div key={index}>
          <span
            className={` ${
              item.notifyRead === 0 ? "dropdown-item unread" : ""
            }  ${styles.NotificationItem}`}
            key={index}
          >
            <div
              onClick={() => window.location.replace(item.profileRedirectLink)}
              className={styles.NotificationItemThumb}
              style={{ cursor: "pointer" }}
            >
              {item &&
              VIDEO_EXT_TYPE.includes(item?.profile?.split(".").pop()) ? (
                <video autoplay playsinline style={{ pointerEvents: "none" }}>
                  <source src={item?.profile} type="video/mp4"></source>
                </video>
              ) : (
                <img src={item?.profile || user} alt="img" />
              )}
            </div>

            <div className={styles.NotificationItemContent}>
              <span
                onClick={() => handleReadNotification(item.notificationId)}
                dangerouslySetInnerHTML={{ __html: item.message }}
              ></span>
              <span>{timeFromNow(item?.updatedAt)}</span>
            </div>
          </span>
        </div>
      ))}
    </>
  );
};

export default NotificationBox;
