import React from "react"
import CommonModal from "../CommonModal/CommonModal"
import "../WalletModal/WalletModal.scss"
import CommonBtn from "../CommonBtn/CommonBtn"
const ConfirmModal = ({
  show,
  setConfirmModal,
  onClick,
  setWalletModalShow,
  text,
  confirmModalLoader,
}) => {
  return (
    <CommonModal
      show={show}
      onHide={() => setConfirmModal(false)}
      className="Confirm_wallet_modal"
      modalTitle="Wallet Change Request">
      <p className="confirmText">{text}</p>
      <div className="confirmBtnWrap">
        <CommonBtn
          title="Cancel"
          onClick={() => {
            setConfirmModal(false)
            setWalletModalShow(true)
          }}
          style={{ marginRight: "10px" }}
        />
        <CommonBtn
          loader={confirmModalLoader}
          className={`btn-bordered`}
          title="Confirm"
          onClick={onClick}
        />
      </div>
    </CommonModal>
  )
}

export default ConfirmModal
