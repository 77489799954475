import React from "react";
import CommonModal from "../../components/ui/CommonModal/CommonModal";
import { Col, Dropdown, Form } from "react-bootstrap";
import styles from "./Fractionalize.module.scss";
import CommonBtn from "../../components/ui/CommonBtn/CommonBtn";
import { useNavigate } from "react-router-dom";

const FractionalizeRequest = ({
  show,
  onHide,
  loader,
  allCategory,
  selectCategoryHandler,
  stateId,
}) => {
  const navigate = useNavigate();

  return (
    <CommonModal
      show={show}
      modalTitle={`Fractionalize Request`}
      onHide={onHide}
      className="fractionModalStyle"
      backdrop="static"
    >
      <Col sm={12} className={styles.fractionRequest}>
        <Form.Group className="mb-3">
          <Form.Label>Choose Category</Form.Label>
          <Dropdown>
            <Dropdown.Toggle
              variant="success"
              id="assetCategory"
              name="assetCategory"
              className={styles.dropdown_menu}
              type="button"
            >
              Choose Category
            </Dropdown.Toggle>
            {allCategory[0] && (
              <Dropdown.Menu className={styles.dropdownList}>
                {allCategory?.map((item, index) => {
                  return (
                    <Dropdown.Item
                      key={index}
                      eventKey={index?.id}
                      onClick={() => selectCategoryHandler(item?.id)}
                      className="dropdown-item"
                    >
                      {item?.name}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            )}
          </Dropdown>
        </Form.Group>
      </Col>
      <Col sm={12}>
        <CommonBtn
          loader={loader}
          title="CANCEL"
          className="btn_cancel"
          onClick={() => navigate(-1)}
        />
      </Col>
    </CommonModal>
  );
};

export default FractionalizeRequest;
