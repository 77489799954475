import Web3 from "web3";
import { toasts } from "../../components/common/Toast/Toast";
import {
  contractAddress,
  dividendFactoryAddress,
  EIP712Domain,
  NFTListing,
  domain,
  chainId,
} from "../Constant/Constant";

/*--------------------------------------------- ABI's -------------------------------------------------*/
const nftABI = require("./Abi/Abi.json");
const dividendAbi = require("./Abi/DividendAbi.json");
const dividendFactoryAbi = require("./Abi/DividendFactory.json");

/*------------------------------------------- Web3 provider --------------------------------------------*/
const { ethereum } = window;
const web3 = new Web3(ethereum);

/*----------------------------------------- Contract Instance ------------------------------------------*/
const nftContract = new web3.eth.Contract(nftABI, contractAddress);
const dividendFactory = new web3.eth.Contract(
  dividendFactoryAbi,
  dividendFactoryAddress
);

/*-------------------------------------------- Class Start ---------------------------------------------*/
class EthProvider {
  constructor() {
    this.isMeta = Boolean(ethereum && ethereum.isMetaMask);
  }
  /// set user network ///
  async setNetwork() {
    const network = await ethereum.request({ method: "eth_chainId" });
    if (network === chainId) {
      return network;
    } else {
      try {
        const network = await ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: web3.utils.toHex(chainId) }],
        });
        debugger;
        return network;
      } catch (error) {
        toasts.error(error?.message);
      }
    }
  }
  /// connect metamask account with default ///
  async isMetamaskInstalled(setConnectErrors) {
    if (this.isMeta) {
      const network = await this.setNetwork();
      if (network === chainId) {
        try {
          const account = await ethereum.request({
            method: "eth_requestAccounts",
            // params: [
            //   {
            //     from: web3.utils.isAddress(
            //       "0x5a1F92Ebb05072d0a1D8A610b241c6f4Bb848F1c"
            //     ),
            //   },
            // ],
          });
          return account[0];
        } catch (err) {
          setConnectErrors("Open & Unlock metamask wallet and try again");
          toasts.error(err?.message);
          return false;
        }
      }
    } else {
      toasts.error(
        "Please install and initialize Metamask wallet extension first"
      );
      setTimeout(() => {
        window.open("https://metamask.io", "_blank");
      }, 2000);
      return false;
    }
  }
  /// get user balances ///
  async getBalance(account) {
    const weiBalance = await web3.eth.getBalance(account);
    const etherBalance = web3.utils.fromWei(weiBalance, "ether");
    return etherBalance;
  }
  /// get PlatformFee ///
  async verifyPlatformFee() {
    try {
      const platformFee = await nftContract.methods.platformFee().call();
      return platformFee / 100;
    } catch (error) {
      throw error;
    }
  }
  /// Compare user account ///
  async userWalletCompare(account, wallet) {
    if (account === wallet) {
      return true;
    }
    if (wallet !== account) {
      return false;
    }
  }

  /// List and Mint nft Function ///
  createToken = async (
    { tokenId, fractionsQuantity, uri = "", owner, signature },
    setActive
  ) => {
    setActive(true);
    debugger;
    try {
      const nftContract = new web3.eth.Contract(nftABI, contractAddress);
      const estimatedGas1 = await nftContract.methods
        .createToken({ tokenId, fractionsQuantity, uri, owner, signature })
        .estimateGas({ from: owner });
      let finalGasPrice = web3.utils.fromWei(
        JSON.stringify(estimatedGas1),
        "ether"
      );
      let finalTotalPrice = JSON.parse(finalGasPrice);
      const userBalance = await this.getBalance(owner);
      if (finalTotalPrice < +userBalance) {
        let sendArgs = {
          from: owner,
          gas: estimatedGas1,
        };
        return new Promise((resolve, reject) => {
          nftContract.methods
            .createToken({
              tokenId,
              fractionsQuantity,
              uri,
              owner,
              signature,
            })
            .send(sendArgs, function (error, transactionHash) {
              if (transactionHash) {
                setActive(false);
                resolve(transactionHash);
              }
              if (error) {
                toasts.error(error?.message);
                setActive(false);
                reject(error);
              }
            });
        });
      } else {
        throw { message: "Insufficient balance !" };
      }
    } catch (error) {
      console.log(error, "Error creating");
      toasts.error(error?.message);
      throw error;
    } finally {
      setActive(false);
    }
  };
  /// Sign for list NFT Function ///
  listNftSign = async (
    { pricePerShare, tokenId, counter, nftAmount, owner },
    setSignNftLoader
  ) => {
    setSignNftLoader(true);
    debugger;
    try {
      const account = await this.isMetamaskInstalled();
      debugger;
      const correctAccountOrNot = await this.userWalletCompare(
        account.toLowerCase(),
        owner.toLowerCase()
      );
      if (correctAccountOrNot) {
        let weiPricePerShare = web3.utils.toWei(
          JSON.stringify(+pricePerShare),
          "shannon"
        );
        const message = {
          pricePerShare: +weiPricePerShare,
          tokenId: +tokenId,
          counter,
          nftAmount,
          owner,
        };
        const primaryType = "NFTListing";
        const types = {
          EIP712Domain,
          NFTListing,
        };
        const msgParams = JSON.stringify({
          domain,
          message,
          primaryType,
          types,
        });
        const params = [owner, msgParams];
        const method = "eth_signTypedData_v4";
        let signedMessage = await new Promise((resolve, reject) => {
          web3?.currentProvider?.sendAsync(
            { method, params, from: owner },
            async function (err, result) {
              if (err) {
                setSignNftLoader(false);
                toasts.error(err.message);
                return reject(err);
              }
              if (result.error) return console.error(result);
              if (result.result) resolve(result.result);
            }
          );
        });
        setSignNftLoader(false);
        return {
          pricePerShare,
          tokenId: +tokenId,
          owner,
          signature: signedMessage,
        };
      } else {
        setSignNftLoader(false);
        await this.isMetamaskInstalled();
        toasts.error(`Please connect this wallet address ${owner}`);
        return;
      }
    } catch (error) {
      setSignNftLoader(false);
      throw error;
    }
  };
  /// Verify the signature function ///
  verifyNFTListing = async (voucher) => {
    try {
      const verifyNFTListing = await nftContract.methods
        .verifyNFTListing(voucher)
        .call();
      return verifyNFTListing;
    } catch (error) {
      throw error;
    }
  };
  /// Verify the signature function ///
  verifyBuyDetails = async (voucher) => {
    try {
      const verifyBuyDetails = await nftContract.methods
        .verifyBuyDetails(voucher)
        .call();
      return verifyBuyDetails;
    } catch (error) {
      throw error;
    }
  };
  /// Primary Marketplace Buy NFT Function ///
  buyToken = async (
    { NFTListing, buyDetails, buyer, amountToBuy, userBalance },
    setActive
  ) => {
    setActive(true);
    await this.isMetamaskInstalled();
    try {
      let finalPrice = web3.utils.fromWei(
        NFTListing?.pricePerShare.toString(),
        "shannon"
      );
      let valueWei = finalPrice * buyDetails?.conversionRate * amountToBuy;
      const gasFees = await nftContract.methods
        .buyToken(NFTListing, buyDetails)
        .estimateGas({ from: buyer, value: +valueWei });
      let finalGas = web3.utils.fromWei(JSON.stringify(gasFees), "ether");
      let finalNftAmount = web3.utils.fromWei(valueWei.toString(), "ether");
      let finalTotalPrice = JSON.parse(finalNftAmount) + JSON.parse(finalGas);
      if (finalTotalPrice < +userBalance) {
        let sendArgs = {
          from: buyer,
          value: +valueWei,
          gas: gasFees,
        };
        try {
          const res = await new Promise((resolve, reject) => {
            nftContract.methods
              .buyToken(NFTListing, buyDetails)
              .send(sendArgs, function (error, transactionHash) {
                if (transactionHash) {
                  setActive(false);
                  resolve({
                    txnAmount: finalNftAmount,
                    txnFees: finalGas,
                    txnHash: transactionHash,
                  });
                }
                if (error) {
                  setActive(false);
                  reject(error);
                }
              });
          });
          return res;
        } catch (error) {
          setActive(false);
          throw error;
        }
      } else {
        toasts.error("Insufficient balance !");
      }
    } catch (error) {
      // toasts.error(error?.message)
      setActive(false);
      throw error;
    }
  };

  /*-------------------------------------------- Divident Contract ---------------------------------------------*/
  /// dividendFactory ///
  async getNftDivident(tokenId) {
    try {
      const nftDiviDentConstractAddress = await dividendFactory.methods
        .viewDividenAddress(tokenId)
        .call();
      return nftDiviDentConstractAddress;
    } catch (error) {
      throw error;
    }
  }
  // createDividend
  // async createNftDividend() {
  //   try {
  //     const gasFees = await nftContract.methods
  //     .buyToken(NFTListing, buyDetails)
  //     .estimateGas({ from: buyer, value: +valueWei });
  //     const nftDividend = await dividendFactory.methods.createDividend()
  //   } catch (error) {

  //   }
  // }
}

const ethProviders = new EthProvider();
export default ethProviders;
