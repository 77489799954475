import React from 'react'
import boximg from "../../../assets/images/boxsecimg.png"
import Ipsumboximg from "../../../assets/images/ipsumboximg.png"
import loremimg from "../../../assets/images/loremsecimg.png"
import { Col, Container, Row } from 'react-bootstrap';
import styles from "./BoxesSec.module.scss";
function BoxesSec() {
    return (
        <div className={styles.box_sec}>
            <Container>
                <Row className='align-items-center'>
                    <Col lg={7}>
                        <div className={styles.box_sec_content}>
                            <h2>What Is Lorem Ipsum</h2>
                            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English </p>
                            <p>Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English</p>
                        </div>
                    </Col>
                    <Col lg={5}>
                        <div className={`ms-lg-auto ${styles.boxsec_pic}`} >
                            <img src={boximg} alt="" />
                        </div>
                    </Col>
                </Row>
                <Row className='align-items-center'>
                <Col lg={7} className="order-lg-last">
                        <div className={`ms-lg-auto ${styles.box_sec_content}`}>
                            <h2>What Is Lorem Ipsum</h2>
                            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English </p>
                            <p>Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English</p>
                        </div>
                    </Col>
                    <Col lg={5}>
                        <div className={styles.boxsec_pic} >
                            <img src={Ipsumboximg} alt="" />
                        </div>
                    </Col>
                    
                </Row>
                <Row className='align-items-center'>
                    <Col lg={7}>
                        <div className={styles.box_sec_content}>
                            <h2>What Is Lorem Ipsum</h2>
                            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English </p>
                            <p>Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English</p>
                        </div>
                    </Col>
                    <Col lg={5}>
                        <div className={`ms-lg-auto ${styles.boxsec_pic}`}>
                            <img src={loremimg} alt="" />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
export default BoxesSec
