import axios from "axios"
import { toasts } from "../../components/common/Toast/Toast"
import { API_BASE_URL } from "../Constant/Constant"
import { setTokenExpires } from "../../Redux/Reducers/user"
import { store } from "../../Redux/Store"

axios.defaults.baseURL = API_BASE_URL

function formatUrl(url, params) {
  params =
    params && Object.keys(params).length > 0
      ? `?${new URLSearchParams(params).toString()}`
      : ""
  return `${url}${params}`
}

function handleError(error) {
  error?.data?.message && toasts.error(error.data.message)
  error?.response?.data?.message && toasts.error(error.response.data.message)
}

function handleSuccess(res) {
  if (res.status === 200 || res.status === 201) {
    res.message && toasts.success(res.message)
    res?.data?.message && toasts.success(res.data.message)
  }
  if (res.status === 403) {
    res.message && toasts.warning(res.message)
  }
}

axios.interceptors.request.use(
  (config) => {
    config.headers["Authorization"] = `Bearer ${localStorage.getItem(
      "authorizationToken"
    )}`

    return config
  },
  (error) => {
    return error
  }
)

// Add a response interceptor
axios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error?.response?.status === 401) {
      store.dispatch(setTokenExpires(true))
    }
    throw error
  }
)
export const apiCallPost = (url, data, params = {}, toastOn) =>
  new Promise((resolve, reject) => {
    axios
      .post(formatUrl(url, params), data)
      .then((res) => {
        if (toastOn === "toastOn") {
          handleSuccess(res)
        }
        resolve(res.data)
      })
      .catch((error) => {
        if (toastOn === "toastOn" || toastOn === "toastOnError") {
          handleError(error)
        }
        reject(error)
      })
  })

export const apiCallGet = (url, params = {}, toastOn) => {
  return new Promise((resolve, reject) => {
    axios
      .get(formatUrl(url, params))
      .then((res) => {
        if (toastOn === "toastOn") {
          handleSuccess(res)
        }

        resolve(res?.data)
      })
      .catch((error) => {
        if (toastOn === "toastOn") {
          handleError(error)
        }
        reject(error?.response?.data)
      })
  })
}
export const apiCallPut = (url, data, params = {}, toastOn) =>
  new Promise((resolve, reject) => {
    axios
      .put(formatUrl(url, params), data)
      .then((res) => {
        if (toastOn === "toastOn") {
          handleSuccess(res)
        }
        resolve(res.data)
      })
      .catch((error) => {
        if (toastOn === "toastOn" || toastOn === "toastOnError") {
          handleError(error)
        }
        reject(error?.response?.data)
      })
  })

export const apiCallPatch = (url, data, params = {}, toastOn) =>
  new Promise((resolve) => {
    axios
      .patch(formatUrl(url, params), data)
      .then((res) => {
        if (toastOn === "toastOn") {
          handleSuccess(res)
        }
        resolve(res.data)
      })
      .catch((error) => {
        if (toastOn === "toastOn") {
          handleError(error)
        }
        resolve(null)
      })
  })

export const apiCallDelete = (url, params = {}, toastOn) => {
  return new Promise((resolve) => {
    axios
      .delete(formatUrl(url, params))
      .then((res) => {
        if (toastOn === "toastOn") {
          handleSuccess(res)
        }
        resolve(res.data)
      })
      .catch((error) => {
        if (toastOn === "toastOn") {
          handleError(error)
        }
        resolve(null)
      })
  })
}
