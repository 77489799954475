import React from "react";
import CommonModal from "../../components/ui/CommonModal/CommonModal";
import { Form, Col, Row } from "react-bootstrap";
import CommonBtn from "../../components/ui/CommonBtn/CommonBtn";
import { checkPDFType, handleUpload } from "../../features/Utility/Utility";
import { UploadIcon } from "../../assets/svgIcons/SVGicon";
import styles from "./Fractionalize.module.scss";
import { useState } from "react";

const UploadDocuments = ({ show, onHide, formik, document }) => {
  const [loader, setLoader] = useState(false);
  const { setFieldValue, setFieldTouched } = formik;
  const [docList, setDocList] = useState([]);
  const pdfFileHandler = async (file, key) => {
    setFieldTouched(key, true);
    const typeSupported = checkPDFType(file);
    if (typeSupported) {
      setFieldValue(key, file);
      setDocList([...docList, { url: file, key }]);
    }
  };

  const uplodeDocs = async (key) => {
    setLoader(true);
    try {
      const imageUrl = await handleUpload(docList);
      imageUrl.map((item) => setFieldValue(item.key, item.url, item.type));
      onHide(true);
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };
  const cancelSlectedFiles = () => {
    setFieldValue("document", [...document]);
  };

  return (
    <CommonModal
      show={show}
      modalTitle={`Authenticity`}
      onHide={onHide}
      className="fractionModalStyle"
    >
      <div className={styles.fractionRequest}>
        <h3>Documents Required</h3>
        <form>
          <Col sm={12}>
            <Form.Group className="mb-3">
              <>
                {formik?.values?.document.map(({ document }, index) => {
                  return (
                    <div key={index} className={styles.docUpload}>
                      <div className={styles.docUpload_input}>
                        <p
                          style={{
                            color:
                              formik?.values?.document[index]?.url?.name ===
                              undefined
                                ? ""
                                : "#fff",
                          }}
                        >
                          {formik?.values?.document[index]?.url
                            ? formik?.values?.document[index]?.url?.name ||
                              "Uploaded"
                            : document}
                        </p>
                        <Form.Control
                          type="file"
                          name={`document[${index}].url`}
                          onChange={(event) =>
                            pdfFileHandler(
                              event?.target?.files[0],
                              `document[${index}].url`
                            )
                          }
                          // onBlur={handleBlur}
                        />
                        <UploadIcon />
                      </div>
                      {formik.touched?.document?.length > 0 &&
                        formik.errors.document?.length > 0 && (
                          <div className="error_field">
                            {formik?.touched?.document[index]?.url && (
                              <span>
                                {formik?.errors?.document[index]?.url}
                              </span>
                            )}
                          </div>
                        )}

                      {/* {formik?.touched?.document &&
                        formik?.errors?.document?.length > 0 && (
                          <div className="error_field">
                            <span>{formik?.errors?.document[index]?.url}</span>
                          </div>
                        )} */}
                    </div>
                  );
                })}
              </>
              {/* // )} */}
            </Form.Group>
          </Col>
          <Row>
            <Col sm={6}>
              <CommonBtn
                title="CANCEL"
                className="btn_cancel"
                onClick={() => {
                  onHide(true);
                  cancelSlectedFiles();
                }}
                type="button"
              />
            </Col>
            <Col sm={6}>
              <CommonBtn
                title="UPDATE"
                className="btn_cancel"
                disabled={formik?.values?.document.length !== docList.length}
                onClick={() => uplodeDocs()}
                type="button"
                loader={loader}
              />
            </Col>
          </Row>
        </form>
      </div>
    </CommonModal>
  );
};

export default UploadDocuments;
