import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import BannerSec from "../../../../pages/profilePages/commonComponents/bannerSec/BannerSec"
import styles from "./ProfileLayout.module.scss"
import UserCard from "../../../../pages/profilePages/commonComponents/userCard/UserCard"
import { Outlet, useNavigate, useParams } from "react-router-dom"
import ProfileNav from "../../../../pages/profilePages/commonComponents/Navbar/ProfileNav"
import { useDispatch, useSelector } from "react-redux"
import { apiCallGet } from "../../../../features/Axios/Axios"
import APIURLS from "../../../../features/ApiPath/ApiPath"
import CompleteKYCModal from "../../../ui/CompleteKYCModal/CompleteKYCModal"
import { setCompleteKYCModal } from "../../../../Redux/Reducers/user"
const ProfileLayout = () => {
  const navigate = useNavigate()
  const params = useParams()
  const dispatch = useDispatch()
  const auth = useSelector((state) => state?.user?.userLogin)
  const fineUserName = async () => {
    try {
      if (auth) {
        const res = await apiCallGet(
          `${APIURLS.USER.PROFILE_PRIVATE}/${params.username}`,
          {}
        )
        const { data } = res
        console.log({ data })
        if (
          !data?.userKyc ||
          data?.userKyc?.kycStatus === "DECLINED" ||
          data?.userKyc?.kycStatus === "NOT APPLIED"
        ) {
          dispatch(setCompleteKYCModal(true))
        }
        if (res?.message === "Account not found") {
          navigate("/error")
        }
      } else {
        const res = await apiCallGet(
          `${APIURLS.USER.PROFILE_PUBLIC}/${params.userName}`,
          {}
        )
        if (res?.message === "Account not found") {
          navigate("/error")
        }
      }
    } catch (error) {
      console.error("profileinfo", error)
    }
  }
  useEffect(() => {
    fineUserName()
  }, [])
  return (
    <>
      <section className={styles.profile_layout}>
        <BannerSec />
        <Container>
          <Row>
            <Col lg={3}>
              <div className={styles.profile_layout_left}>
                <UserCard />
              </div>
            </Col>
            <Col lg={9}>
              <div className={styles.profile_layout_right}>
                <ProfileNav />
                <CompleteKYCModal />
                <Outlet />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default ProfileLayout
