import React from "react";
import "./SimpleBtn.scss";

const SimpleBtn = ({ icon, title, onClick, className, ...props }) => {
  return (
    <>
      <button className={`simpleBtn ${className}`} onClick={onClick} {...props}>
        {icon && (
          <>
            <span className="me-3">{icon}</span>
          </>
        )}
        {title && (
          <>
            <span>{title}</span>
          </>
        )}
      </button>
    </>
  );
};

export default SimpleBtn;
