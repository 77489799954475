import React, { useState } from "react"
import { Col, Row } from "react-bootstrap"
import { Link } from "react-router-dom"
import {
  EditListIcon,
  HeartIcon,
  HeartIconFilled,
} from "../../../../assets/svgIcons/SVGicon"
import styles from "./CommonCard.module.scss"
import APIURLS from "../../../../features/ApiPath/ApiPath"
import { apiCallPost } from "../../../../features/Axios/Axios"
import { useDispatch, useSelector } from "react-redux"
import { VIDEO_EXT_TYPE } from "../../../../features/Constant/Constant"
import { setShowLogInLogInModal } from "../../../../Redux/Reducers/user"
const CommonCard = ({
  className,
  title,
  img,
  to,
  subtitle,
  price,
  fractions,
  galleryData,
  btnTitle,
  onClickBtn,
  isFavourite,
  setAllCollectionData,
  type,
  setNftData,
  nftId,
  collectionId,
  myItems,
  forFavouriteOnly,
  LeftFractions,
  getAllFavourite,
  isListed,
  isMint,
  createFilter,
}) => {
  const [like, Setlike] = useState(isFavourite)
  const dispatch = useDispatch()
  const { userLogin } = useSelector((state) => state.user)
  const makeCollectionFavourite = async (action, id) => {
    try {
      const res = await apiCallPost(
        `${APIURLS.COLLECTION.COLLECTION_FAVOURITE}`,
        {
          collectionId: id,
          isFavourite: action,
        },
        {}
      )
      if (res) {
        if (forFavouriteOnly) {
          await getAllFavourite()
          return
        }
        setAllCollectionData((prevState) =>
          prevState.map((item) => {
            if (item.id === id) {
              item.isFavourite = item.isFavourite === 0 ? 1 : 0
            }
            return item
          })
        )
      }
    } catch (error) {
      console.log(error)
    }
  }
  const makeNftFavourite = async (action, id) => {
    try {
      const res = await apiCallPost(
        `${APIURLS.NFT.NFT_FAVOURITE}`,
        {
          nftId: id,
          isFavourite: action,
        },
        {}
      )
      if (res) {
        if (forFavouriteOnly) {
          await getAllFavourite()
          return
        }
        setNftData((prevState) =>
          prevState.map((item) => {
            if (item.nftId === id) {
              item.isFavourite = item.isFavourite === 0 ? 1 : 0
            }
            return item
          })
        )
        getAllFavourite()
      }
    } catch (error) {}
  }
  const favouriteHandler = () => {
    if (!userLogin) {
      dispatch(setShowLogInLogInModal(true))
      return
    }
    if (type === "collection")
      makeCollectionFavourite(Number(!isFavourite), collectionId || to)
    else {
      makeNftFavourite(Number(!isFavourite), nftId)
    }
    if (!forFavouriteOnly) {
      Setlike(Number(!isFavourite))
    }
  }
  return (
    <>
      <div className={`${styles.common_card} ${className}`}>
        <button className={styles.common_card_like} onClick={favouriteHandler}>
          {!like ? (
            <>
              <HeartIcon />
            </>
          ) : (
            <>
              <HeartIconFilled />
            </>
          )}
        </button>
        {/* {myItems && (
          <button className={styles.common_card_EditList}>
            <EditListIcon />
          </button>
        )} */}
        {VIDEO_EXT_TYPE.includes(img?.split(".").pop()) ? (
          <Link to={to} className={styles.common_card_thumb}>
            <video autoplay playsinline style={{ pointerEvents: "none" }}>
              <source src={img} type="video/mp4"></source>
            </video>{" "}
          </Link>
        ) : (
          <Link to={to} className={styles.common_card_thumb}>
            <img src={img} alt="Icon" />
          </Link>
        )}
        <div className={styles.common_card_head}>
          <Link to={to}>
            <h6>{title}</h6>
          </Link>
          <p>{subtitle}</p>
        </div>
        {price && (
          <>
            <div className={styles.common_card_price}>
              <hr />
              <Row>
                <Col>
                  <div className={styles.common_card_price_left}>
                    <span>Price</span>
                    <h6>${price}</h6>
                  </div>
                </Col>
                <Col>
                  <div className={styles.common_card_price_right}>
                    <span>Fractions</span>
                    <h6>{fractions}</h6>
                  </div>
                </Col>
              </Row>
            </div>
            <Link
              onClick={isMint && isListed ? "" : createFilter ? "" : onClickBtn}
              // to={to}
              disabled={createFilter || (isMint && isListed)}
              className={styles.common_card_action}>
              <span>
                {isMint && isListed
                  ? "Listed"
                  : createFilter
                  ? "Relist"
                  : btnTitle}
              </span>
            </Link>
          </>
        )}
        {galleryData && galleryData.length > 0 && (
          <div className={styles.common_card_gallery}>
            <Row>
              {galleryData.map((item, index) => (
                <Col key={index}>
                  <Link to={to} className={styles.common_card_gallery_img}>
                    {item &&
                    VIDEO_EXT_TYPE.includes(
                      item.image?.split(".").pop() ||
                        item.thumb?.split(".").pop()
                    ) ? (
                      <video
                        autoplay
                        playsinline
                        style={{ pointerEvents: "none" }}>
                        <source src={item.image} type="video/mp4"></source>
                      </video>
                    ) : (
                      <img src={item.image || item.thumb} alt="Icon" />
                    )}
                  </Link>
                </Col>
              ))}
            </Row>
          </div>
        )}
      </div>
    </>
  )
}
export default CommonCard
