import React from "react";
import Accepted from "./Accepted/Accepted";
import Rejected from "./Rejected/Rejected";
import YetTobeSubmitted from "./YetTobeSubmitted/YetTobeSubmitted";
import { useSelector } from "react-redux";
import AlreadySubmitted from "./AlreadySubmitted/AlreadySubmitted";
import { KYC_STATUS } from "../../../features/Constant/Constant";

const KYC = () => {
  const userDetails = useSelector((state) => state?.user);
  const { userData } = userDetails;

  const renderKycPage = () => {
    if (
      userData.userKyc &&
      userData.userKyc.kycStatus === KYC_STATUS.processing
    ) {
      return (
        <AlreadySubmitted
          actionDate={userData?.userKyc?.updatedAt}
          requestedDate={userData?.userKyc?.createdAt}
        />
      );
    }

    if (
      userData.userKyc &&
      (userData.userKyc.kycStatus === KYC_STATUS.approved ||
        userData.userKyc.kycStatus === KYC_STATUS.done)
    ) {
      return (
        <Accepted
          actionDate={userData?.userKyc?.updatedAt}
          requestedDate={userData?.userKyc?.createdAt}
        />
      );
    }

    if (
      userData.userKyc &&
      userData.userKyc.kycStatus === KYC_STATUS.declined
    ) {
      return (
        <Rejected
          reasons={userData?.userKyc?.remarks}
          actionDate={userData?.userKyc?.updatedAt}
          requestedDate={userData?.userKyc?.createdAt}
        />
      );
    }

    return (
      <YetTobeSubmitted
        actionDate={userData?.userKyc?.updatedAt}
        requestedDate={userData?.userKyc?.createdAt}
      />
    );
  };

  return <>{renderKycPage()}</>;
};

export default KYC;
