import React from "react"
import MainLayout from "./components/common/layouts/mainLayout/MainLayout"
import Activity from "./pages/activity/Activity"
import Faq from "./pages/faq/Faq"
import Fractionalize from "./pages/fractionalize/Fractionalize"
import ContactUs from "./pages/contactus/ContactUs"
import SearchResults from "./pages/search/SearchResults/SearchResults"
import Home from "./pages/home/Home"
import ProfileSettings from "./pages/profilesettings/ProfileSettings"
import ErrorPage from "./pages/error/ErrorPage"
import ProfileLayout from "./components/common/layouts/profileLayout/ProfileLayout"
import Favourites from "./pages/profilePages/Favourites/Favourites"
import Myitems from "./pages/profilePages/Myitems/Myitems"
import CollectionDetails from "./pages/marketplace/CollectionCategory/CollectionCategory"
import NFTDetailPage from "./pages/marketplace/DetailPage/NFTDetailPage"
import MyListings from "./pages/profilePages/myListings/MyListings"
import ProfileHistory from "./pages/profilePages/History/ProfileHistory"
import KYC from "./pages/profilePages/KYC/KYC"
import Dividend from "./pages/profilePages/Dividend/Dividend"
import ProfileFractionalize from "./pages/profilePages/Fractionalize/ProfileFractionalize"
import Collections from "./pages/marketplace/Collections/Collections"
import Marketplace from "./pages/marketplace/marketplace"
import Nft from "./pages/marketplace/Collections/Nft"
import HowItWorks from "./pages/HowItWorks/HowItWorks"
import SearchResultNft from "./pages/search/SearchResults/SearchResultNft"
import AboutPage from "./pages/about/AboutPage"
import Search from "./pages/search/Search"
import Unread from "./pages/notifications/unread/Unread"
import NotificationsPage from "./pages/notifications/Notifications"
import Read from "./pages/notifications/read/Read"
import Fileup from "./pages/Fileup/Fileup"
import { Navigate, RouterProvider, createBrowserRouter } from "react-router-dom"
import { useSelector } from "react-redux"
import CustomerSupport from "./pages/CustomerSupport/CustomerSupport"
import AdminSupport from "./pages/AdminSupport/AdminSupport"
import Ticket from "./pages/Ticket/Ticket"
import useCheckInKYC from "./hooks/useCheckInKYC"
import GlobalSearchContextProvider from "./context/GlobalSearchContext"
import PdfReader from "./pages/marketplace/DetailPage/PdfReader"

const Routers = () => {
  const auth = useSelector((state) => state?.user?.userLogin)
  const userInfo = useSelector((state) => state?.user?.userData)

  const routersPaths = createBrowserRouter([
    {
      path: "/",
      element: <MainLayout />,
      children: [
        {
          index: true,
          element: <Home />,
        },
        {
          path: "/faq",
          element: <Faq />,
        },
        {
          path: "/customer-support",
          element: <CustomerSupport />,
        },
        {
          path: "customer-support/:supportId",
          element: <Ticket />,
        },
        {
          path: "/admin-support",
          element: <AdminSupport />,
        },
        {
          path: "/ticket",
          element: <Ticket />,
        },
        {
          path: "/profile-settings",
          element: auth ? <ProfileSettings /> : <Navigate to="/" />,
        },
        {
          path: "/fractionalize",
          element:
            auth &&
            userInfo?.isKycDone &&
            userInfo?.userKyc?.kycStatus === "APPROVED" ? (
              <Fractionalize />
            ) : (
              <Navigate to="/" />
            ),
        },
        {
          path: "/contact-us",
          element: <ContactUs />,
        },
        {
          path: "/how-it-works",
          element: <HowItWorks />,
        },
        {
          path: "activity",
          element: <Activity />,
        },
        {
          path: "/about",
          element: <AboutPage />,
        },
        {
          element: <Marketplace />,
          path: "/marketplace",
          children: [
            {
              index: true,
              path: "collections",
              element: <Collections />,
            },
            {
              path: "nft",
              element: <Nft />,
            },
            {
              path: "collections/:collectionId",
              element: <CollectionDetails />,
            },
            {
              path: "nft/:nftId",
              element: <NFTDetailPage />,
            },
            {
              path: "nft/:nftId/:docId",
              element: <PdfReader />,
            },
          ],
        },
        {
          element: <Search />,
          path: "/search",
          children: [
            {
              index: true,
              path: "collections",
              element: <SearchResults />,
            },
            {
              path: "nft",
              element: <SearchResultNft />,
            },
            {
              path: "collections/:collectionId",
              element: <CollectionDetails />,
            },
            {
              path: "nft/:nftId",
              element: <NFTDetailPage />,
            },
          ],
        },
        {
          element: <ProfileLayout />,
          path: ":username",
          children: [
            {
              index: true,
              path: "favourites",
              element: <Favourites />,
            },
            {
              path: "my-items",
              element: <Myitems />,
            },
            {
              path: "my-listings",
              element: <MyListings />,
            },
            {
              path: "history",
              element: <ProfileHistory />,
            },
            {
              path: "kyc",
              element: <KYC />,
            },
            {
              path: "roi",
              element: <Dividend />,
            },
            {
              path: "fractionalize",
              element: <ProfileFractionalize />,
            },
          ],
        },
        {
          element: <NotificationsPage />,
          path: "/notifications",
          children: [
            {
              index: true,
              path: "unread",
              element: <Unread />,
            },
            {
              path: "read",
              element: <Read />,
            },
          ],
        },
        {
          path: "/fileup",
          element: <Fileup />,
        },
        {
          path: "/error",
          element: <ErrorPage />,
        },
      ],
    },
  ])

  return (
    <GlobalSearchContextProvider>
      <RouterProvider
        fallbackElement={
          <>
            <h1>Loading...</h1>
          </>
        }
        router={routersPaths}></RouterProvider>
    </GlobalSearchContextProvider>
  )
}

export default Routers
