import React from 'react';
import styles from './HowItWorksCard.module.scss';

const HowItWorksCard = ({ className, title, content, icon }) => {
    return (
        <>
            <div className={`${styles.howitworks_card} ${className}`}>
                <div className={styles.howitworks_card_thumb}>
                    {icon}
                </div>
                <h5>{title}</h5>
                <p>{content}</p>
            </div>
        </>
    )
}

export default HowItWorksCard