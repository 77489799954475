import React from "react"
// import styles from '../home/About/AboutPage.module.scss'
import Banner from "./Banner/Banner"
import Introduction from "./introduction/Introduction"
import Partners from "./partners/Partners"
import About from "../home/About/About"

const AboutPage = () => {
  return (
    <>
      <Banner />
      <Partners />
      <Introduction />
      <About />
    </>
  )
}

export default AboutPage
