import React, { useState } from "react";
import "../CommonModal/CommonModal.scss";
import CommonModal from "../CommonModal/CommonModal";
import { Form } from "react-bootstrap";
import CommonBtn from "../CommonBtn/CommonBtn";
import { apiCallPost } from "../../../features/Axios/Axios";
import APIURLS from "../../../features/ApiPath/ApiPath";
import { useFormik } from "formik";
import { forgetPassValidationSchema } from "../../../features/Validation/YupValidations";

const ForgotPassword = ({
  show,
  onHide,
  setUserEmail,
  setVerifyPass,
  setForgotShow,
  setResetOtp,
}) => {
  const [signupLoader, setSignupLoader] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: forgetPassValidationSchema,
    onSubmit: async (values) => {
      setSignupLoader(true);
      try {
        const res = await apiCallPost(
          `${APIURLS.USER.FORGET_PASS}`,
          values,
          {},
          "toastOn"
        );
        if (res) {
          setUserEmail(values?.email);
          setResetOtp(true);
          setVerifyPass(true);
          setForgotShow(false);
        }
      } catch (error) {
        setSignupLoader(false);
      }
    },
  });

  return (
    <>
      <CommonModal
        show={show}
        onHide={onHide}
        className="forgotpass_modal"
        modalTitle="Forgot Password"
      >
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group className="mb-5">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="text"
              placeholder="abc@gmail.com"
              id={"email"}
              name={"email"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
            {formik.errors.email && (
              <div className="error_field" style={{ marginTop: "-3.9rem" }}>
                <span>{formik.errors.email}</span>
              </div>
            )}
          </Form.Group>
          <CommonBtn
            className="mt-16 w-100"
            title="CONTINUE"
            disabled={!formik.isValid}
            type="submit"
            loader={signupLoader}
          />
        </Form>
      </CommonModal>
    </>
  );
};

export default ForgotPassword;
