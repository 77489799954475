import React from "react";
import "./AlertModal.scss";
import "../CommonModal/CommonModal.scss";
import CommonModal from "../CommonModal/CommonModal";
import CommonBtn from "../CommonBtn/CommonBtn";

const AlertModal = ({ show, onHide, img, title, btnText, btnAction }) => {
  return (
    <>
      <CommonModal
        show={show}
        onHide={onHide}
        className="alert_modal"
        subTitle={
          <>
            <img src={img} alt="" />
          </>
        }
      >
        <p className="mt-3 text-center">{title}</p>

        <CommonBtn className="mt-3 w-100" title={btnText} onClick={btnAction} />
      </CommonModal>
    </>
  );
};

export default AlertModal;
