import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { NOFTIFICATION_TYPE } from "../../features/Constant/Constant";
import { apiCallPut } from "../../features/Axios/Axios";
import APIURLS from "../../features/ApiPath/ApiPath";
import socket from "../../Socket/Socket";
// export const joinAndListenNotifications = createAsyncThunk(
//   "notifications/joinAndListen",
//   async (params) => {
//     const { _id } = params; // Access the userLogin and userInfo from the state
//     socket.emit("EMIT_JOIN", { _id });
//     socket.on("LISTEN_NOTIFY", (data) => {});
//   }
// );
export const getAllNotifications = createAsyncThunk(
  "notifications/getAll",
  async (params, { rejectWithValue }) => {
    const { page, ReadOrUnReadType, searchTerm } = params;
    try {
      const { data } = await apiCallPut(
        `${APIURLS.NOTIFICATION.GET_NOTIFICATION}10/${page}/${ReadOrUnReadType}`,
        {
          search: searchTerm,
        }
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
// export const MarkReadNoftification = createAsyncThunk(
//   "notifications/markRead",
//   async ({ id }, rejectWithValue) => {
//     try {
//       return await apiCallPut(`${APIURLS.NOTIFICATION.MARK_READ}${id}`);
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );
// export const MarkAllReadNoftification = createAsyncThunk(
//   "notifications/markAllRead",
//   async ({ id }, rejectWithValue) => {
//     try {
//       return await apiCallPut(APIURLS.NOTIFICATION.MARK_READ_ALL);
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

const NotificationSlice = createSlice({
  name: "noftifications",
  initialState: {
    readNotifications: [],
    unReadNotifications: [],
    allNotifications: [],
    count: 0,
  },
  extraReducers: (builer) => {
    builer.addCase(getAllNotifications.fulfilled, (state, action) => {
      const { ReadOrUnReadType } = action.meta.arg;
      if (ReadOrUnReadType === NOFTIFICATION_TYPE.READ) {
        state.readNotifications = action.payload;
      } else if (ReadOrUnReadType === NOFTIFICATION_TYPE.UNREAD) {
        state.unReadNotifications = action.payload;
      } else {
        state.allNotifications = action.payload;
      }
    });
  },
  reducers: {
    setReadNotifications: (state, action) => {
      state.readNotifications = action.payload;
    },
    setunReadNotifications: (state, action) => {
      state.unReadNotifications = action.payload;
    },
    setunReadNotificationsFromSocket: (state, action) => {
      const newNotificationDataFromSocket = {
        ...action.payload.data,
        notifyRead: 0,
      };

      const allNotificationsData = [
        newNotificationDataFromSocket,
        ...(state.allNotifications?.rows || []),
      ];

      const unReadData = [
        newNotificationDataFromSocket,
        ...(state.unReadNotifications?.rows || []),
      ];

      return {
        ...state,
        allNotifications: {
          ...state.allNotifications,
          rows: allNotificationsData,
          count: (state.allNotifications?.count || 0) + 1,
          totalcount: (state.allNotifications?.totalcount || 0) + 1,
        },
        unReadNotifications: {
          ...state.unReadNotifications,
          rows: unReadData,
          count: (state.unReadNotifications?.count || 0) + 1,
          totalcount: (state.unReadNotifications?.totalcount || 0) + 1,
        },
      };
    },

    deleteAllUnReadMessageOrReadMessages: (state, action) => {
      if (action.payload === NOFTIFICATION_TYPE.READ) {
        state.readNotifications = [];
      } else {
        state.unReadNotifications = [];
      }
    },
    ReadUnreadMessages: (state, action) => {
      const { id, count, totalcount } = action.payload;

      const updatedUnreadRows = state.unReadNotifications.rows.filter(
        ({ notificationId }) => notificationId !== id
      );

      const updatedAllNotificationsRows = (
        state.allNotifications.rows || []
      ).map((item) => {
        if (item.notificationId === id) {
          return { ...item, notifyRead: 1 };
        }
        return { ...item };
      });

      return {
        ...state,
        unReadNotifications: {
          ...state.unReadNotifications,
          rows: updatedUnreadRows,
          count: count - 1,
          totalcount: totalcount - 1,
        },
        allNotifications: {
          ...state.allNotifications,
          count: count - 1,
          rows: updatedAllNotificationsRows,
        },
      };
    },

    markAllReadMessage: (state, action) => {
      state.unReadNotifications = [];
    },

    deleteNotification: (state, action) => {
      const { id, type } = action.payload;
      if (type === NOFTIFICATION_TYPE.READ) {
        state.readNotifications = {
          count: state?.readNotifications?.count - 1,
          totalcount: state?.readNotifications?.totalcount - 1,
          rows: state.readNotifications?.rows?.filter(
            ({ notificationId }) => notificationId !== id
          ),
        };

        let alltotalcount = state?.allNotifications?.totalcount;
        state.allNotifications.totalcount = alltotalcount - 1;
      } else {
        state.unReadNotifications = {
          count: state?.unReadNotifications?.count - 1,
          totalcount: state?.unReadNotifications?.totalcount - 1,
          rows: state.unReadNotifications?.rows?.filter(
            ({ notificationId }) => notificationId !== id
          ),
        };
      }
        // state.allNotifications.rows = state.allNotifications?.rows?.filter(
        //   ({ notificationId }) => notificationId !== id
        // );
    },
  },
});
export const {
  setReadNotifications,
  markAllReadMessage,
  setunReadNotificationsFromSocket,
  deleteAllUnReadMessageOrReadMessages,
  setunReadNotifications,
  ReadUnreadMessages,
  deleteNotification,
} = NotificationSlice.actions;

export const prefetchNotifications = () => async (dispatch) => {
  try {
    const params = {
      ReadOrUnReadType: NOFTIFICATION_TYPE.ALL,
      page: 1,
    };

    dispatch(getAllNotifications(params));
  } catch (error) {
    console.error("Error:", error);
  }
};
export default NotificationSlice.reducer;
