import React, { useEffect, useState } from "react";
import styles from "../Notifications.module.scss";
import Thumb from "../../../assets/images/3d-1.png";
import CommonTable from "../../../components/ui/CommonTable/CommonTable";
import NotificationFilter from "../NotificationFilter/NotificationFilter";
import { deleteNoftification } from "../../../features/serives/services";
import { isArrayEmpty, timeFromNow } from "../../../features/Utility/Utility";
import {
  NOFTIFICATION_TYPE,
  VIDEO_EXT_TYPE,
} from "../../../features/Constant/Constant";
import useDebounce from "../../../hooks/useDebounce";
import PaginationComponent from "../../../components/common/pagination/PaginationComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteNotification,
  getAllNotifications,
} from "../../../Redux/Reducers/notifications";

const limit = 10;
const Read = () => {
  const dispatch = useDispatch();
  const { readNotifications } = useSelector((state) => state?.notification);
  const [searchTerm, setSearchTerm] = useState("");
  const debounceTerm = useDebounce(searchTerm);
  const [currPage, setCurrPage] = useState(1);

  useEffect(() => {
    dispatch(
      getAllNotifications({
        ReadOrUnReadType: NOFTIFICATION_TYPE.READ,
        searchTerm: debounceTerm,
        page: currPage,
      })
    );
  }, [debounceTerm, currPage]);

  return (
    <>
      <div className={styles.notifications_read}>
        <NotificationFilter
          setCurrPage={setCurrPage}
          searchvalue={searchTerm}
          search={true}
          setSearch={setSearchTerm}
          deletebtn={true}
          NotificationType={NOFTIFICATION_TYPE.READ}
        />

        <CommonTable
          isDataEmpty={isArrayEmpty(readNotifications?.rows)}
          className={`mt-5 ${styles.notifications_table}`}
        >
          <thead>
            <tr>
              <th>Details</th>
              <th>Time</th>
              <th width="62px">Actions</th>
            </tr>
          </thead>
          <tbody>
            {readNotifications?.rows?.map((item, index) => {
              return (
                <tr key={index}>
                  <td
                    onClick={() =>
                      window.location.replace(item.profileRedirectLink)
                    }
                  >
                    {item &&
                    VIDEO_EXT_TYPE.includes(item?.profile?.split(".").pop()) ? (
                      <video
                        autoplay
                        playsinline
                        style={{ pointerEvents: "none" }}
                      >
                        <source src={item?.profile} type="video/mp4"></source>
                      </video>
                    ) : (
                      <img
                        className={styles.notifications_table_thumb}
                        src={item?.profile || Thumb}
                        alt="img"
                      />
                    )}

                    <span
                      dangerouslySetInnerHTML={{ __html: item?.message }}
                    ></span>
                  </td>
                  <td>{timeFromNow(item?.updatedAt)}</td>
                  <td>
                    <button
                      onClick={() => {
                        deleteNoftification(item?.notificationId);
                        dispatch(
                          deleteNotification({
                            id: item?.notificationId,
                            type: NOFTIFICATION_TYPE.READ,
                          })
                        );
                      }}
                      className={styles.notifications_table_act_delete}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </CommonTable>
      </div>
      <PaginationComponent
        currentPage={currPage}
        requestPerPage={limit}
        setCurrentPage={setCurrPage}
        totalCount={readNotifications?.count}
      />
    </>
  );
};
export default Read;
