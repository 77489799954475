import React, { useEffect, useRef, useState } from "react";
import styles from "./ListNFTModal.module.scss";
import CommonModal from "../CommonModal/CommonModal";
import CommonBtn from "../CommonBtn/CommonBtn";
import { Button, Form } from "react-bootstrap";
import { MinusIcon, PlusIcon } from "../../../assets/svgIcons/SVGicon";
import ethProviders from "../../../features/web3/EthProviders";
import { useSelector } from "react-redux";
import { listNftApi } from "../../../features/Utility/Utility";
import { toasts } from "../../common/Toast/Toast";

const ListNFTModal = ({
  className,
  show,
  onHide,
  myItemDataNft,
  createFilter,
  setListNftModal,
}) => {
  const { title, collection, image, price, quantityLeft, quantity } =
    myItemDataNft;
  const incrementIntervalRef = useRef(null);
  const [nftCopy, setNftCopy] = useState(1);
  const [platformFee, setPlatformFee] = useState("");

  const walletAddress = useSelector((state) => state?.wallet?.walletAddress);
  const [signNftLoader, setSignNftLoader] = useState(false);

  const listNftApiFun = async ({ nftId, listId, signature }) => {
    const res = await listNftApi({ nftId, listId, signature });
    if (res) {
      setListNftModal(false);
    }
  };
  // list Signature with price for the NFT Buy //
  const listNftSignature = async ({
    pricePerShare,
    counter,
    nftAmount,
    tokenId,
  }) => {
    try {
      const { signature } = await ethProviders.listNftSign(
        {
          pricePerShare,
          tokenId,
          counter,
          nftAmount,
          owner: walletAddress,
        },
        setSignNftLoader
      );
      return signature;
    } catch (error) {
      console.error(error);
    }
  };
  // Mint NFT on contract with gas feee //
  const createNftToken = async (signMint) => {
    try {
      const createToken = await ethProviders.createToken(
        {
          tokenId: signMint?.tokenId,
          quantity: signMint?.quantity,
          uri: signMint?.uri,
          owner: signMint?.owner,
          signature: signMint?.signature,
        },
        setSignNftLoader
      );
      return createToken;
    } catch (error) {
      console.error(error);
    }
  };
  // First Signature for the NFT details //
  const signNftMint = async (nftData) => {
    const { tokenId, price, nftId, counter, isListed, isMint, quantity } =
      nftData;
    const listId = counter;
    const pricePerShare = price;
    const nftAmount = quantity;
    debugger;
    try {
      if (walletAddress) {
        if (isMint) {
          if (!isListed) {
            const signature = await listNftSignature({
              pricePerShare,
              counter,
              nftAmount,
              tokenId,
            });
            if (signature) {
              await listNftApiFun({ nftId, listId, signature });
            }
          }
        } else {
          const mintNftToaken = await createNftToken(nftData);
          if (mintNftToaken) {
            const signature = await listNftSignature({
              pricePerShare,
              counter,
              nftAmount,
              tokenId,
            });
            if (signature) {
              await listNftApiFun({ nftId, listId, signature });
            }
          }
        }
      } else {
        setListNftModal(true);
        toasts.warn("Please connect with wallet first.");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const startIncrement = (increment) => {
    incrementIntervalRef.current = setInterval(() => {
      setNftCopy((prevCount) => {
        const nextValue = eval(prevCount + increment + 1);
        if (nextValue <= 0 || nextValue === quantityLeft) {
          stopIncrement();
          return prevCount;
        }
        return nextValue;
      });
    }, 150);
  };
  const stopIncrement = () => {
    clearInterval(incrementIntervalRef.current);
  };

  const platformFees = async () => {
    const res = await ethProviders.verifyPlatformFee();
    if (res) {
      setPlatformFee(res);
    }
  };
  useEffect(() => {
    platformFees();
  }, []);

  return (
    <>
      <CommonModal
        show={show}
        onHide={onHide}
        className={`${className} ${styles.listnft_modal}`}
        modalTitle="List NFT"
        subTitle={
          <>
            <div className="d-flex align-items-center justify-content-start gap-4 py-4 px-5 w-100">
              <img src={image} alt="Nft Image" style={{ objectFit: "cover" }} />
              <div className="d-inline-block text-start">
                <h4>{title}</h4>
                <p>{collection}</p>
              </div>
            </div>
          </>
        }>
        <div className={styles.inner_sec}>
          <div className={styles.inner_sec_input}>
            <Form.Group className="mb-3">
              <Form.Label>New Price Per Fraction</Form.Label>
              <Form.Control
                type="text"
                placeholder={`$ ${createFilter ? price : "0.00"}`}
                readOnly={createFilter}
                value={`$ ${createFilter ? price : "0.00"}`}
              />
            </Form.Group>
          </div>
          {!createFilter && (
            <>
              <div className={styles.inner_sec_text}>
                <p>No. of Fractions</p>
                <p>
                  <span
                    onClick={() => setNftCopy(quantityLeft)}
                    style={{ cursor: "pointer" }}>
                    Max
                  </span>
                </p>
              </div>
              <div className={styles.counter}>
                <Button
                  className={styles.counter_rate}
                  onClick={() => setNftCopy(nftCopy - 1)}
                  onMouseDown={() => startIncrement("-")}
                  onMouseUp={() => stopIncrement()}
                  disabled={nftCopy <= 1}>
                  <MinusIcon />
                </Button>
                <Form.Control
                  type="number"
                  placeholder={quantity}
                  value={
                    nftCopy > quantityLeft
                      ? quantityLeft
                      : nftCopy < 1
                      ? 1
                      : nftCopy
                  }
                  onChange={(e) => setNftCopy(+e.target.value)}
                />
                <Button
                  className={styles.counter_rate}
                  onClick={() => setNftCopy(nftCopy + 1)}
                  onMouseDown={() => startIncrement("+")}
                  onMouseUp={() => stopIncrement()}
                  disabled={nftCopy >= quantityLeft}>
                  <PlusIcon />
                </Button>
              </div>
              <div className={styles.inner_sec_text}>
                <p>
                  <span></span>
                </p>
                <p>
                  <span>Available:</span> {quantityLeft}
                </p>
              </div>
            </>
          )}
          <div className={styles.amount_sec}>
            <p className={styles.textLeft}>
              $ {price}
              <br />
              <span>Current Price</span>
            </p>
            <p className={styles.textRight}>
              {platformFee}%
              <br />
              <span>Platform Fees</span>
            </p>
          </div>
        </div>
        <CommonBtn
          className="my-5 w-100 btn-bordered"
          title="Continue"
          onClick={() => signNftMint(myItemDataNft)}
        />
      </CommonModal>
    </>
  );
};

export default ListNFTModal;
