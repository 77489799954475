import React, { useEffect } from "react"
import CommonModal from "../../../components/ui/CommonModal/CommonModal"
import { Col, Form, Row } from "react-bootstrap"
import img from "../../../assets/images/Apartment1.png"
import styles from "./ProfileFractionalize.module.scss"
import CommonBtn from "../../../components/ui/CommonBtn/CommonBtn"
import { useFormik } from "formik"
import { dividendValidationSchema } from "../../../features/Validation/YupValidations"
import { apiCallPost } from "../../../features/Axios/Axios"
import APIURLS from "../../../features/ApiPath/ApiPath"
import ethProviders from "../../../features/web3/EthProviders"

const DividendModal = ({ show, onHide, fractionData }) => {
  const { tokenId, nftId } = fractionData
  const getNftDividentAddress = async () => {
    try {
      return await ethProviders.getNftDivident(tokenId)
    } catch (error) {}
  }

  const formik = useFormik({
    initialValues: {
      amount: "",
    },
    validationSchema: dividendValidationSchema,
    onSubmit: async (values) => {
      const nftContractAddress = await getNftDividentAddress()
      if(nftContractAddress){
        
      }
      debugger
      try {
        await apiCallPost(
          APIURLS.DIVIDENT.ADD,
          { nftId, dividend: values.amount },
          {},
          "toastOn"
        )
        onHide()
        formik.resetForm()
      } catch (error) {}
    },
  })

  return (
    <CommonModal
      modalTitle="Add Dividend"
      show={show}
      onHide={onHide}
      className={styles.dividendModal}>
      <form onSubmit={formik.handleSubmit}>
        <Col className={styles.dividendModal_nftInfo}>
          <Row>
            <img src={img} alt="errors" />
            <div className={styles.dividendModal_nftInfo_detail}>
              <h4>{fractionData?.title}</h4>
              <p>{fractionData?.category}</p>
            </div>
          </Row>
        </Col>
        <Col className={styles.dividendModal_inputStyle}>
          <Form.Group className="mb-3">
            <Form.Label>Amount</Form.Label>
            <div style={{ position: "relative" }}>
              <Form.Control
                type="number"
                placeholder="Enter your dividend"
                id="amount"
                name="amount"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.amount}
              />
              <span className={styles.dividendModal_inputStyle_xar}>XAR</span>
            </div>
          </Form.Group>
          {formik.errors.amount && formik.touched.amount && (
            <div className="error_field">
              <span>{formik.errors.amount}</span>
            </div>
          )}
        </Col>
        <Col className={styles.dividendModal_nftDetails}>
          <ul>
            <li>
              <span>Balance</span> : {`XAR 10`}
            </li>
            <li>
              <span>NFT Listing date</span> : {`12/01/2023`}
            </li>
            <li>
              <span>Dividend Last Added</span> : {`12/12/2023`}
            </li>
          </ul>
          <h6>Please Note:</h6>
          <p>
            The dividend amount has to be locked up upfront for a the minimum
            period of 1 year
          </p>
        </Col>
        <Col xs={12}>
          <CommonBtn
            title="Proceed"
            type="submit"
            loader={formik.isSubmitting}
            disabled={formik.isSubmitting}
            className="fullWidth"
          />
        </Col>
      </form>
    </CommonModal>
  )
}

export default DividendModal
