import React, { useEffect, useState } from "react"
import About from "./About/About"
import BrowseByCategory from "./BrowseByCategory/BrowseByCategory"
import HeroSec from "./HeroSec/HeroSec"
import HowItWorks from "./HowItWorks/HowItWorks"
import TrendingNFTs from "./TrendingNFTs/TrendingNFTs"
import { apiCallGet } from "../../features/Axios/Axios"
import APIURLS from "../../features/ApiPath/ApiPath"

const Home = () => {
  const [homeData, setHomeData] = useState([])

  const getHomeData = async () => {
    try {
      const res = await apiCallGet(APIURLS.USER.HOME)
      setHomeData(res?.data)
    } catch (error) {}
  }

  useEffect(() => {
    const body = document.querySelector("body")
    body.classList.add("home-controller")
    getHomeData()
    return () => {
      body.classList.remove("home-controller")
    }
  }, [])

  
  return (
    <>
      <HeroSec
        countAssetCategory={homeData?.categoryCount?.toString()}
        countCollection={homeData?.collectionCount?.toString()}
        countNFT={homeData?.nftCount?.toString()}
      />
      <About />
      <HowItWorks />
      <BrowseByCategory data={homeData?.categoryData?.rows} />
      <TrendingNFTs data={homeData?.nftData?.rows} />
    </>
  )
}

export default Home
