import React, { useState, useEffect, useRef } from "react";
import styles from "./DetailPage.module.scss";
import { Col, Container, Row } from "react-bootstrap";
import TopOwnersTable from "./TopOwnersTable";
import ActivityTable from "./ActivityTable";
import ListingTable from "./ListingTable";
import DetailPageContent from "./DetailPageContent";
import TokenDetails from "./TokenDetails";
import SliderSec from "./SliderSec";
import DetailsSec from "./DetailsSec";
import { useNavigate, useParams } from "react-router-dom";
import {
  contractAddress,
  DECRYPTION_KEY,
} from "../../../features/Constant/Constant";
import PropertyDetail from "./PropertyDetail";
import { UpArrowWhite, DownArrowWhite } from "../../../assets/svgIcons/SVGicon";
import { formatDate, getNftDetails } from "../../../features/Utility/Utility";
import * as CryptoJS from "crypto-js";
import { apiCallGet } from "../../../features/Axios/Axios";
import APIURLS from "../../../features/ApiPath/ApiPath";
import ListNFTModal from "../../../components/ui/ListNFTModal/ListNFTModal";

const NFTDetailPage = () => {
  const scrollRef = useRef();
  const limit = 10;
  const page = 1;
  const [nftDetails, setNftDetails] = useState({});
  const [listingData, setListingData] = useState([]);
  const [showProperties, setShowProperties] = useState(true);
  const [encryptedDocuments, setEncryptedDocuments] = useState([]);
  const params = useParams();
  const [showlist, setShowlist] = useState(false);
  const navigation = useNavigate();
  const [topOwnder, setTopOwnders] = useState([]);

  const getNftListing = async () => {
    try {
      const res = await apiCallGet(
        `${APIURLS.NFT.NFT_LISTING}/${params?.nftId}/${page}/${limit}`,
        {}
      );
      setListingData(res?.data);
    } catch (error) {}
  };
  const getAllNftDetials = async () => {
    try {
      const res = await getNftDetails(params?.nftId);
      if (res) {
        setNftDetails(res);
      }
    } catch (error) {
      navigation("/marketplace/collections");
      console.log("errm", error);
    }
  };
  const { gallery, fileType, image } = nftDetails;
  const calleverydoc = async () => {
    try {
      const res = await apiCallGet(
        `${APIURLS.NFT.GET_NFT_DOCS}/${params?.nftId}`
      );
      const bytes = CryptoJS.AES.decrypt(res?.data, DECRYPTION_KEY);
      const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
      setEncryptedDocuments(JSON.parse(decryptedData));
    } catch (error) {
      console.log(error);
    }
  };

  const getTopOwnders = async () => {
    try {
      const { data } = await apiCallGet(
        `${APIURLS.NFT.GET_TOP_OWNERS}/${params?.nftId}`
      );
      setTopOwnders(data);
    } catch (error) {}
  };

  useEffect(() => {
    getAllNftDetials();
    getNftListing();
    getTopOwnders();
  }, []);
  useEffect(() => {
    calleverydoc();
  }, []);


  return (
    <>
      <section className={styles.detailpage}>
        <Container>
          <Row>
            <Col lg={6} className="mb-4 mb-lg-0">
              <SliderSec
                image={[image]}
                fileType={fileType}
                gallery={gallery}
              />
            </Col>
            <Col lg={6}>
              <DetailsSec
                collection={nftDetails?.collection}
                category={nftDetails?.category}
                description={nftDetails?.description}
                favouriteCount={nftDetails?.favouriteCount}
                nftId={nftDetails?.nftId}
                price={nftDetails?.price}
                quantity={nftDetails?.quantity}
                title={nftDetails?.title}
                viewCount={nftDetails?.viewCount}
                key={nftDetails?.favouriteCount}
                isFavourite={nftDetails?.isFavourite}
                nftDetails={nftDetails}
                listingData={listingData}
                lastTradePrice={nftDetails?.lastTradePrice}
                onClick={() =>
                  scrollRef.current.scrollIntoView({
                    block: "center",
                    behavior: "smooth",
                  })
                }
              />
              <TokenDetails
                contractId={contractAddress}
                tokeId={nftDetails?.tokenId}
              />
              <DetailPageContent
                totalFractions={nftDetails?.quantity}
                dateOfMinting={formatDate(nftDetails?.mintDate)}
                dividend={nftDetails?.dividend}
                quantity={nftDetails?.quantity}
                fractionAvailbale={nftDetails?.quantity}
                encryptedDocuments={encryptedDocuments}
                paramId={params && params?.nftId}
                youOwn={nftDetails?.youOwn}
                availableFraction={nftDetails?.availableFraction}
              />
              {nftDetails?.additionalField?.length > 0 && (
                <button
                  onClick={() => setShowProperties((prevState) => !prevState)}
                  className="btn-properties"
                >
                  Properties
                  {showProperties ? <DownArrowWhite /> : <UpArrowWhite />}
                </button>
              )}
            </Col>
          </Row>
          {nftDetails?.additionalField?.length > 0 && (
            <PropertyDetail
              additionalField={nftDetails?.additionalField}
              showProperties={showProperties}
            />
          )}
          <div ref={scrollRef}>
            <ListingTable
              listingData={listingData}
              nftDetails={nftDetails}
              getNftListing={getNftListing}
            />
          </div>
          <ActivityTable paramId={params?.nftId} />
          <TopOwnersTable topOwnder={topOwnder} />
        </Container>
      </section>
      {showlist && (
        <ListNFTModal show={showlist} onHide={() => setShowlist(false)} />
      )}
    </>
  );
};
export default NFTDetailPage;
